<app-spinner-overlay></app-spinner-overlay>
<div mat-dialog-content class="dialog-center-cont" style="display: flex; flex-direction: column; margin-top: 10px;">
    
    <app-titolo-subtitolo [titolo]="'Obiettivo e Target'"
        [subTitolo]="'Seleziona almeno un target qualitativo o quantitativo.'">
    </app-titolo-subtitolo>

    <div appFormContainer [larghezza]="2" style="align-items: center;">

        <div appFormElement [larghezza]="1" [riempi]="true">
            <div class="titolo-sezione-dialog titolo-personalizzato">
                {{data.titoloObiettivo}}
            </div>
            <div class="descrizione-sezione-dialog">
                {{data.desObiettivo}}
            </div>

        </div>
    </div>
    <ng-container *ngIf="objQualitativiQuantitativi">
        <mat-tab-group #tabGroupTarget>
            <!-- AZIONE BUONE PRATICHE -->
            <mat-tab label="Target Qualitativi">
                <app-selezione-obiettivi-target-piano #targetQualitativi
                    [objQuestionario]="objQualitativiQuantitativi?.targetQualitativi" chiaveElemento="elementi"
                    [idContest]="this.data.listaId.idPianoSostenibilita" [stato]="this.data.stato"
                    [idObiettivo]="this.data.listaId.idObiettivo" [getTargetQualitativiQuantitativi]="carica"
                    [oggettoElementiSelezionatiTarget]="getOggettoElementiSelezionatiTarget"
                    chiaveElementoSelezione="targetQualitativi">
                </app-selezione-obiettivi-target-piano>
            </mat-tab>

            <!-- KPI -->
            <mat-tab label="Target Quantitativi">
                <app-selezione-obiettivi-target-piano #targetQuantitativi
                    [objQuestionario]="objQualitativiQuantitativi?.targetQuantitativi" chiaveElemento="elementi"
                    [idContest]="this.data.listaId.idPianoSostenibilita" [getTargetQualitativiQuantitativi]="carica"
                    [oggettoElementiSelezionatiTarget]="getOggettoElementiSelezionatiTarget" [stato]="this.data.stato"
                    [idObiettivo]="this.data.listaId.idObiettivo" chiaveElementoSelezione="targetQuantitativi">
                </app-selezione-obiettivi-target-piano>

            </mat-tab>
        </mat-tab-group>
    </ng-container>
</div>

<footer mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-flat-button color="accent" matDialogClose class="accent-button">Chiudi</button>
    </div>

    <div *ngIf="!this.data?.isPermessoSoloRead">
        <button mat-flat-button color="primary" (click)="salvaInHTML()" class="primary-button">Salva</button>
    </div>

    <div>
        <button mat-flat-button color="primary" (click)="assegnazioni()" class="primary-button">
            <mat-icon>person_edit</mat-icon> Assegnazioni
        </button>
    </div>
</footer>