<app-spinner-overlay></app-spinner-overlay>


<app-titolo-subtitolo titolo="Impatto"
    [subTitolo]="data.contesto ? 'Definisci le caratteristiche dell\'impatto selezionando una o più opzioni tra quelle disponibili per ciascun campo. Modifica la descrizione seguendo la linea guida che varierà sulla base delle caratteristiche dell\'impatto.' : ''"></app-titolo-subtitolo>

<div mat-dialog-content class="dialog-center-cont">

    <form [formGroup]="formImpatti">
        <div appFormContainer [larghezza]="3">

            <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
                <mat-label>Titolo</mat-label>
                <input matInput placeholder="Titolo" formControlName="titolo" />
                <mat-error appFormError></mat-error>
            </mat-form-field>

            <mat-form-field class="input-form" appFormElement [larghezza]="1.5" [riempi]="true"
                *ngIf="this.data.contesto">
                <mat-label>Impatto Potenziale/Effettivo</mat-label>
                <mat-select formControlName="impattoPotenzialeEffettivo" (selectionChange)="onSelectionChange()"
                    placeholder="Impatto Potenziale/Effettivo" [(value)]="selectedImpattoPotEff" multiple>
                    <mat-option *ngFor="let impt of arrayImpattoPotEff"
                        [value]="impt.codice">{{impt.descrizione}}</mat-option>
                </mat-select>
                <mat-error appFormError></mat-error>
            </mat-form-field>

            <mat-form-field class="input-form" appFormElement [larghezza]="1.5" [riempi]="true"
                *ngIf="this.data.contesto">
                <mat-label>Impatto Positivo/Negativo</mat-label>
                <mat-select formControlName="impattoPositivoNegativo" (selectionChange)="onSelectionChange()"
                    placeholder="Impatto Positivo/Negativo" [(value)]="selectedImpattoPosNeg" multiple>
                    <mat-option *ngFor="let impt of arrayImpattoPosNeg"
                        [value]="impt.codice">{{impt.descrizione}}</mat-option>
                </mat-select>
                <mat-error appFormError></mat-error>
            </mat-form-field>

            <div *ngIf="this.data.contesto" class="segnalazione" [ngClass]="{'show': isIconaGuida}">
                <div style="display: flex; align-items: center;">
                    <mat-icon>error</mat-icon>
                </div>
                <div class="descrizione">
                    {{guidaDescrizione}}
                </div>
            </div>

            <!-- <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
                <mat-label>Descrizione</mat-label>
                <input matInput placeholder="Descrizione" formControlName="descrizione" />
                <mat-error appFormError></mat-error>
            </mat-form-field> -->

            <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
                <mat-label>Descrizione</mat-label>
                <textarea matInput placeholder="Descrizione" formControlName="descrizione" rows="4"></textarea>
                <mat-error appFormError></mat-error>
            </mat-form-field>

            <mat-form-field appFormElement [larghezza]="3" [riempi]="true" *ngIf="!this.data.contesto">
                <mat-label>Note compilazione</mat-label>
                <textarea matInput placeholder="Note" formControlName="note" rows="4"></textarea>
                <mat-error appFormError></mat-error>
            </mat-form-field>

            <ng-container *ngIf="data.isFatturato === true">

                <div class="segnalazioneFatt">
                    <div style="display: flex; align-items: center;">
                        <mat-icon>error</mat-icon>
                    </div>
                    <div class="descrizione">
                        Impostare un range di fatturato e settore per consigliare alle aziende corrispondenti di
                        utilizzare
                        questo impatto in configurazione Survey.
                    </div>
                </div>

                <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
                    <mat-label>Fatturato DA</mat-label>
                    <input matInput placeholder="Fatturato DA" formControlName="fatturatoDa" type="number"
                        appInputNumber inputmode="numeric" min="0" max="999999999999" (keydown)="onKeyDown($event)" />
                    <mat-error appFormError></mat-error>
                </mat-form-field>

                <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
                    <mat-label>Fatturato A</mat-label>
                    <input matInput placeholder="Fatturato A" formControlName="fatturatoA" type="number" appInputNumber
                        inputmode="numeric" min="0" max="999999999999" (keydown)="onKeyDown($event)" />
                    <mat-error appFormError></mat-error>
                </mat-form-field>

                <mat-form-field class="input-form" appFormElement [larghezza]="1" [riempi]="true">
                    <mat-label>Settori</mat-label>
                    <mat-select formControlName="settori" placeholder="Settori" [(value)]="selectedSettori" multiple
                        [compareWith]="compareSettori">
                        <mat-option *ngFor="let set of arraySettori" [value]="set">{{set.titolo}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
                    <mat-label>TAG</mat-label>
                    <input matInput placeholder="TAG" formControlName="tag" />
                    <mat-error appFormError></mat-error>
                </mat-form-field>
            </ng-container>
        </div>
    </form>

</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Salva
        </button>
    </div>
</div>