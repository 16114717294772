import {  Component, EventEmitter, Input,  Output, ViewChild } from '@angular/core';
import { SpinnerOverlayComponent } from '../spinner-overlay/spinner-overlay.component';
import { Domande, InformativaAziendale, Sezioni } from 'src/app/services/informativa-aziendale/informativa-aziendale.service';
import { UnitaDiMisura, UnitaDiMisuraService } from 'src/app/services/unita-di-misura/unita-di-misura.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { Filters } from '../lista-tabellare/classes/lista-tabellare-data-source';

@Component({
  selector: 'app-compila-informativa-aziendale',
  templateUrl: './compila-informativa-aziendale.component.html',
  styleUrls: ['./compila-informativa-aziendale.component.scss']
})
export class CompilaInformativaAziendaleComponent {

  @Output() completato: EventEmitter<any> = new EventEmitter();

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  private _infoAz!: InformativaAziendale;
  private _inConf!: boolean;
  public arrayUm: { [idKpi: string]: UnitaDiMisura[] } = {};
  private _temporaryToken: string | undefined = undefined;
  private _isCompEsterna: boolean = false;

  // Viene messo a true quando il questionario è stato elaborato ed inizializzato
  public inizializzato = false;

  @Input()
  set temporaryToken(temporaryToken: string | undefined) {
    this._temporaryToken = temporaryToken;
  }

  @Input()
  set isCompEsterna(isCompEsterna: boolean) {
    this._isCompEsterna = isCompEsterna;
  }

  get isCompEsterna() {
    return this._isCompEsterna;
  }

  @Input()
  set infoAz(infoAz: InformativaAziendale) {
    this._infoAz = infoAz;
  }

  @Input()
  set inConf(inConf: boolean) {
    this._inConf = inConf;
  }

  get inConf() {
    return this._inConf;
  }

  get infoAz() {
    return this._infoAz!;
  }

  get sezioni(): Sezioni[] {
    return this.infoAz?.sezioni || [];
  }

  constructor(
    private readonly unitaDiMisuraService: UnitaDiMisuraService,
    private readonly utilityService: UtilityService
  ) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // https://localhost:4200/compilazione-informativa-aziendale?temporaryToken=7183d436-6477-48ea-b84e-69a5c40b863d
      this._parametriDaUrl();
      console.log('this.infoAz : ', this.infoAz);
    }, 0);
  }

  onRadioChange(event: any, idxSez: number, idxDom: number) {
    this.sezioni[idxSez].domande[idxDom].risposte = [event.value];
  }

  cambioCheck(event: any, idxAmb: number, idxTem: number, chiave: string) {

    let risposte = this.sezioni[idxAmb].domande[idxTem].risposte;

    if (event.checked) {

      if (!this.sezioni[idxAmb].domande[idxTem].risposte) {
        this.sezioni[idxAmb].domande[idxTem].risposte = [];
      }

      this.sezioni[idxAmb].domande[idxTem].risposte?.push(chiave);

    } else {

      risposte = risposte?.filter((str) => {
        return str !== chiave;
      });
    }
  }


  checkSotto(risposte: string[] | undefined, chiave: string) {
    const isRisposta = risposte?.find((risp) => {
      return risp === chiave;
    })

    if (isRisposta) {
      return true;
    } else {
      return false;
    }
  }

  salvaBozza() {
    this.completato.emit({ info: this.infoAz, isBozza: true });
  }

  salva() {
    if (!this._validaInformativa()) {
      console.error('ERRORE VALIDAZIONE');
      this.utilityService.opneSnackBar('Rispondi a tutte le domande per salvare la compilazione.', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
      return;
    }
    this.completato.emit({ info: this.infoAz, isBozza: false });
  }

  private _validaInformativa(): boolean {
    return this.infoAz?.sezioni
      .map((sez) => {
        return sez.domande;
      })
      .flatMap((domar) => domar)
      .map((domanda) => {

        switch (domanda.tipo) {
          case 'scelta_singola':
          case 'scelta_multipla':
            return domanda.risposte?.length > 0;
          case 'testo':
            return domanda.risposta !== null
          case 'numero_intero':
          case 'numero_decimale':
            if (!domanda.risposta || typeof domanda.risposta === 'string') {
              // non deve essere stringa ma json
              return false;
            } else if (domanda.risposta?.valore === null || domanda.risposta?.valore === undefined || !domanda.risposta?.um) {
              // Se c'è la categoria deve tornare anche l'um
              return false;
            } else {
              return true;
            }
          case 'data':
            return !!domanda.data;

          default:
            // Tipo non riconosciuto
            return false;
        }

      })
      .reduce((prec, curr) => prec && curr, true);

  }

  private _parametriDaUrl() {

    this._infoAz.sezioni.forEach((sez) => {
      sez.domande.forEach((dom: Domande) => {

        if (dom.tipo === 'numero_decimale' || dom.tipo === 'numero_intero') {

          if (!dom.risposta) {

            dom.risposta = {
              valore: undefined,
              um: undefined
            }
          }

          if (dom.idCategoriaUnitaDiMisura) {

            this.arrayUm[dom.idCategoriaUnitaDiMisura] = [];

            const filters: Filters[] = [{
              chiave: 'idCategoriaUnitaDiMisura',
              operator: 'eq',
              values: [dom.idCategoriaUnitaDiMisura]
            }];

            let obs;

            if (this._temporaryToken) {
              obs = this.unitaDiMisuraService.getUnitaDiMisuraConTempToken(this._temporaryToken, 0, 1000, '', filters);
            } else {
              obs = this.unitaDiMisuraService.getUnitaDiMisura(0, 1000, '', filters);
            }

            obs.subscribe((um) => {
              this.arrayUm[dom.idCategoriaUnitaDiMisura] = um?.content || [];
            });

          }

        } /* else if (dom.tipo === 'data') {
          if (dom.data) {
            dom.data = new Date(dom.data);
          }
        } */
      })
    });


    this.inizializzato = true;
  }
}
