import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { BehaviorSubject, Observable, Subject, debounceTime, map, merge, of, switchMap, tap } from 'rxjs';

interface DatiInput {
  id: number | string | undefined;
  txt_ricerca: string | number | undefined;
}

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ]
})
export class AutocompleteComponent implements OnInit, ControlValueAccessor {

  @ViewChild(MatInput, { read: ElementRef }) inputElementRef!: ElementRef;

  @Input()
  public loadDataFn!: ((id: any, txtRicerca: string) => Observable<any[]>);

  @Input()
  public displayFn: ((record: any) => string) = (record) => '---';

  @Input()
  public compare = (record1: any, record2: any): boolean => true;

  @Input()
  set disabled(disabled) {
    if (disabled) {
      this.autocompleteFormControl.disable();
    } else {
      this.autocompleteFormControl.enable();
    }
  }

  get disabled() {
    return this.autocompleteFormControl.disabled;
  }

  private _label = '';

  @Input()
  set label(label) {
    this._label = label;
  }

  get label() {
    return this._label;
  }

  private _spinnerLoading = false;

  @Input()
  set spinnerLoading(spinnerLoading) {
    this._spinnerLoading = spinnerLoading;
  }

  get spinnerLoading() {
    return this._spinnerLoading;
  }

  private _chiaveId = '';

  @Input()
  set chiaveId(chiaveId) {
    this._chiaveId = chiaveId;
  }

  get chiaveId() {
    return this._chiaveId;
  }

  private _required = false;

  @Input()
  set required(required) {
    this._required = required;

    this._updateRequired();
  }

  get required() {
    return this._required;
  }

  private _numeroRecOptions: number = 0;

  @Input()
  set numeroRecOptions(numeroRecOptions) {
    this._numeroRecOptions = numeroRecOptions;
  }

  get numeroRecOptions() {
    return this._numeroRecOptions;
  }

  @Output() valueChange = new EventEmitter<any>();
  @Output() recordChange = new EventEmitter<any>();

  private _value: any;

  set value(value: any) {
    this._value = value;
    this.autocompleteFormControl.updateValueAndValidity();
  }

  get value() {
    return this._value;
  }

  private _autocompleteFormControl = new FormControl<any | undefined>(undefined);

  get autocompleteFormControl() {
    return this._autocompleteFormControl;
  }

  public datiFiltrati: Observable<any[]> | undefined = undefined;


  private _inputListener = new Subject<string | undefined>();
  private _idSetListener = new Subject<any>();
  private _svuotaListaValori = new Subject<void>();
  public showAltriRecordDisponibili: boolean = false;
  /**
   * Metodi per gestire implementazione di ControlValueAccessor
   */
  onChange: any = () => { };
  onTouched: any = () => { };

  ngOnInit() {

    if (this.numeroRecOptions <= 0) {
      this.numeroRecOptions = 10;
    }

    this.datiFiltrati =
      merge(

        // Se riceve un valore quì, azzera la lista
        this._svuotaListaValori.pipe(map((val) => {
          return [];
        })),

        // Questo merge gestisce sia l'input dal campo di testo (ricerca) che il set diretto del record per id
        merge(

          // Ricerca
          this._inputListener.asObservable().pipe(
            debounceTime(200),
            map((value): DatiInput => {
              return ({ id: undefined, txt_ricerca: value });
            })),

          // Set diretto per id
          this._idSetListener.asObservable().pipe(map((value): DatiInput => {
            return { id: value, txt_ricerca: undefined };
          }))

        ).pipe(
          switchMap((value: DatiInput): Observable<any[]> => {
            // this.isEmpty = false;

            if (value.id) {
              return this.loadDataFn(value.id, '').pipe(
                tap((result) => {
                  if (result?.length) {
                    this.autocompleteFormControl.setValue(result[0]);
                    this._selezionaOpzione(result[0]);
                  }
                })
              );
            } else if (value.txt_ricerca || value.txt_ricerca === '') {
              return this.loadDataFn(undefined, (value.txt_ricerca ? value.txt_ricerca.toString() : ''));
            } else {
              return of([]);
            }

          }),
          tap((esito: any) => {

            if (esito.length > this.numeroRecOptions) {
              this.showAltriRecordDisponibili = true;
            } else {
              this.showAltriRecordDisponibili = false;
            }
          })
        )
      )
  }


  writeValue(value: any): void {

    if (this.value !== value) {
      this.value = value;

      if (!this.value) {
        // Se il valore del componente è stato svuotato, si svuota anche l'input ed i valori dell'input
        this.autocompleteFormControl.setValue(undefined);
        this._inputListener.next(undefined);
      } else {
        // Se il valore è pieno si deve recuperare il record corrispondente
        setTimeout(() => { this._idSetListener.next(value[this.chiaveId || 'id']); });
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = (_: any) => {
      this.valueChange.emit(this.value);
      this.recordChange.emit(this.getSelectedRecord());
      fn(_);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  seleziona(event: any) {
    this._selezionaOpzione(event.option.value);
  }

  getSelectedRecord() {
    return this.autocompleteFormControl.value;
  }

  inputTyped(value: any) {
    // Quando viene scritto nel campo di testo, si azzera il valore del componente.
    if (this.value !== null) {
      this.value = null;
      this.onChange(this.value);
    }

    this._inputListener.next(value.target.value);
  }

  onFocus(event: FocusEvent): void {

    if (this.value) {
      // C'è già un valore selezionato, non si estraggono altri record
      this._svuotaListaValori.next();
      // this.isEmpty = false;
      return;
    }

    // this.isEmpty = false; // Questo impedisce di mostrare "nessun dato" quando si fa focus e non era stato trovato niente con la ricerca precedente

    const tipoDatoInput = typeof this._autocompleteFormControl.value;
    if (tipoDatoInput === 'string' || tipoDatoInput === 'number') {
      this._inputListener.next(this._autocompleteFormControl.value);
    } else {
      this._inputListener.next('');
    }

  }

  private _selezionaOpzione(record: any) {

    this.value = record;

    this.inputElementRef.nativeElement.blur();
    this.onChange(this.value);

  }

  private _updateRequired() {
    if (this._required) {
      this._autocompleteFormControl.addValidators([this._requiredValidator()]);
    } else {
      this._autocompleteFormControl.removeValidators([this._requiredValidator()]);
    }
  }

  private _requiredValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      return !this.value ? { required: true } : null;
    }
  }
}
