import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InformativaAziendale } from 'src/app/services/informativa-aziendale/informativa-aziendale.service';

@Component({
  selector: 'app-dialog-anteprima-compilazione-info-az',
  templateUrl: './dialog-anteprima-compilazione-info-az.component.html',
  styleUrls: ['./dialog-anteprima-compilazione-info-az.component.scss']
})
export class DialogAnteprimaCompilazioneInfoAzComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogAnteprimaCompilazioneInfoAzComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      infoAz: InformativaAziendale,
      inConf: boolean
    }
  ) {

  }

  finitaCompilazione(event: { info: InformativaAziendale, isBozza: boolean; }) {

    if (event.info) {
      this.dialogRef.close(event);
    }
  }

}
