import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, Form } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { FigliStdr, StandardRendicontazioneService, STDR } from 'src/app/services/standard-rendicontazione/standard-rendicontazione.service';
import { RadioGroupComponent } from 'src/app/components/radio-group/radio-group.component';
import { finalize } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Settore, SettoriService } from 'src/app/services/settori/settori.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-dialog-crea-standard-rendicontazione',
  templateUrl: './dialog-crea-standard-rendicontazione.component.html',
  styleUrls: ['./dialog-crea-standard-rendicontazione.component.scss']
})
export class DialogCreaStandardRendicontazioneComponent implements AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public formStdr = new FormGroup({
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    codice: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

    generale: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    settoriale: new FormControl<boolean>(true, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    compliance: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    obbligatorio: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    figli: new FormArray([]),
  });

  public arraySettori: Settore[] = [];

  public arrayFigliStdrSalvati: any[] = [];

  public infoObbligatorio: string = 'Se selezionato, la compilazione del target nei contesti sarà obbligatoria solo se lo standard scelto all’interno del contesto sarà uguale allo standard del KPI, le soglie saranno superate, e il target relativo allo standard del KPI selezionato sarà definito.'


  constructor(
    private readonly strstdrService: StandardRendicontazioneService,
    public dialogRef: MatDialogRef<DialogCreaStandardRendicontazioneComponent>,
    private readonly utilityService: UtilityService,
    private readonly settoreService: SettoriService,
    private readonly cdRef: ChangeDetectorRef,

    @Inject(MAT_DIALOG_DATA) public data: {
      stdr: STDR
    }
  ) {

  }

  public options = [
    { label: 'Si', value: true },
    { label: 'No', value: false }
  ]

  compareById(settore1: any, settore2: any): boolean {
    return settore1?.id === settore2?.id; // Sostituisci "id" con la proprietà univoca degli ambiti
  }

  ngAfterViewInit(): void {



    this._caricaSettori();

    if (this.data.stdr) {
      this._init(this.data.stdr);

    } else if (this.formStdr.get('settoriale')?.value) {
      // Faccio nascere la creazione con una gestione del sotto figlio di default
      const figliArray = this.formStdr.get('figli') as FormArray;
      figliArray.clear();
      figliArray.push(this.mappaFigliStdr(figliArray?.value));
    }

    setTimeout(() => {
      this.formStdr.get('settoriale')?.valueChanges.subscribe((value: any) => {
        if (value && this.arrayFigliStdrSalvati.length) {
          const figliStdrArray = this.formStdr.get('figli') as FormArray;
          figliStdrArray.patchValue(this.arrayFigliStdrSalvati);
        }
      });
    }, 0);

    this.cdRef.detectChanges(); // Forza il rilevamento delle modifiche

  }

  private _init(stdr: STDR) {
    Object.entries(stdr).forEach(([key, value]) => {

      if (key === 'figli') {
        const figliArray = this.formStdr.get('figli') as FormArray;
        figliArray.clear();

        (value).forEach((figlio: any) => {
          figliArray.push(this.mappaFigliStdr(figlio));
        });

      } else {
        this.formStdr.get(key)?.setValue((stdr as any)[key]);
      }
    });

    if (this.data?.stdr?.id) {
      this.formStdr.get('compliance')?.disable();
    }

  }

  /**
   * Metodo di salvataggio stdr
   * @returns 
   */
  public salva() {

    const isSettoriale = this.formStdr.get('settoriale')?.value;
    const figliStdrControl = this.formStdr.get('figli') as FormArray;

    if (!isSettoriale) {
      this.arrayFigliStdrSalvati = figliStdrControl?.value
      figliStdrControl.clear();
    }

    if (this.formStdr.valid) {

      const id = this.formStdr.get('id')?.value;

      console.log(this.formStdr.getRawValue())
      console.log('FORM VALIDO')

      this.spinnerOver.show();
      if (id) {
        return this.strstdrService.putStdr(id, this.formStdr.getRawValue())
          .pipe(
            finalize(() => this.spinnerOver.hide())
          )
          .subscribe(
            {
              next: () => {
                this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                  duration: 2000,
                  panelClass: ['success-snackbar']
                });
                this.dialogRef.close();
              },
              error: (err: any) => {
                if (err.error.status === 'NOT_ACCEPTABLE') {
                  this.formStdr.get('compliance')?.setValue(this.data?.stdr?.compliance)
                }
                this.utilityService.openDialog({
                  titolo: 'Attenzione',
                  descrizione: err?.error?.message,
                  bottoni: [{ nome_btn: 'Chiudi' }]
                })
              }

            }
          );
      } else {
        406
        return this.strstdrService.postStdr(this.formStdr.getRawValue())
          .pipe(
            finalize(() => this.spinnerOver.hide())
          ).subscribe(
            {
              next: () => {
                this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                  duration: 2000,
                  panelClass: ['success-snackbar']
                });
                this.dialogRef.close();
              },
              error: (err: any) => {
                this.utilityService.openDialog({
                  titolo: 'Attenzione',
                  descrizione: err?.error?.message,
                  bottoni: [{ nome_btn: 'Chiudi' }]
                })
              }

            }
          );
      }




    } else {
      console.log('FORM NON VALIDO')
      Object.values(this.formStdr.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
      if (this.formStdr.get('settoriale')?.value) {
        Object.values(this.formStdr.controls.figli.controls).forEach(
          (formGroup: any) => {
            Object.values(formGroup.controls).forEach((control: any) => {
              control.markAsTouched();
            });

          }
        );
      }
    }
    return null;
  }

  mappaFigliStdr(stdr?: FigliStdr) {
    const figli = new FormGroup({
      id: new FormControl<string | undefined>(stdr?.id),
      codice: new FormControl<string | undefined>(stdr?.codice, Validators.required),
      descrizione: new FormControl<string | undefined>(stdr?.descrizione, Validators.required),
      settore: new FormControl<Settore | undefined>(stdr?.settore, Validators.required)
    });
    return figli
  }

  addFigliStdr(formArray: FormArray) {
    const figli = new FormGroup({
      id: new FormControl<string | undefined>(undefined),
      codice: new FormControl<string | undefined>(undefined, Validators.required),
      descrizione: new FormControl<string | undefined>(undefined, Validators.required),
      settore: new FormControl<Settore | undefined>(undefined, Validators.required)
    });
    formArray.push(figli);
  }

  elimina(formArray: FormArray, index: number) {
    formArray.removeAt(index);
  }

  private _caricaSettori() {

    this.spinnerOver.show();

    this.settoreService.getSettori(0, 1000, '', [], [{
      chiave: 'titolo',
      sort: 'asc'
    }])
      .pipe(
        finalize(() => this.spinnerOver.hide())
      )
      .subscribe({
        next: (risp) => {
          this.arraySettori = risp?.content.filter((set: any) => !set.generico) || [];
        },
        error: (err) => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero dei settori',
            bottoni: [{ nome_btn: 'Ok' }]
          });

          this.dialogRef.close();

        }
      })
  }

  dropFigliStdr(event: CdkDragDrop<any[]>, formArrayFigli: FormArray) {
    const currentGroup = formArrayFigli.at(event.previousIndex);
    formArrayFigli.removeAt(event.previousIndex, { emitEvent: false });
    formArrayFigli.insert(event.currentIndex, currentGroup, { emitEvent: false });
  }

  slideSettoriale(slideEvent: MatSlideToggleChange) {
    if (slideEvent.checked && !this.formStdr.get('figli')?.value.length) {
      const figliArray = this.formStdr.get('figli') as FormArray;
      figliArray.clear();
      figliArray.push(this.mappaFigliStdr(figliArray?.value));
    }
  }

  apriInfo() {

    const dialogContent: string = `
    <div>
      <h3>Logica di selezione delle Tematiche, Azioni e KPI</h3>
    
      <h4>1. Selezione automatica delle Tematiche</h4>
      <p>
        Dopo il primo step del <strong>Bilancio di Sostenibilità (BdS)</strong>, alcune <strong>tematiche</strong> vengono selezionate automaticamente se sono collegate a uno <strong>Standard di Rendicontazione / Compliance</strong> che coincide con quello del bilancio.
      </p>
      <p><strong>Nello step delle tematiche:</strong></p>
      <ul>
        <li>Se lo <strong>Standard di Rendicontazione / Compliance</strong> coincide con quello del <strong>Bilancio di Sostenibilità</strong>, la tematica sarà pre-selezionata.</li>
        <li>Se inoltre lo <strong>Standard / Compliance</strong> che ha causato la selezione ha il flag <strong>"Obbligatorio con Trigger"</strong> attivo e il <strong>Trigger</strong> è superato, la tematica diventa obbligatoria (indicata con un lucchetto).</li>
      </ul>
    
      <h4>2. Azioni, Buone Pratiche e KPI consigliati e obbligatori</h4>
      <p>
        Il sistema verifica se tra le <strong>ABP</strong> e i <strong>KPI</strong> delle tematiche selezionate ci sono elementi collegati a uno <strong>Standard di Rendicontazione / Compliance</strong> che coincide con quello del BdS.
      </p>
      <ul>
        <li>Questi elementi vengono contrassegnati come <strong>"Consigliati"</strong>.</li>
        <li>Perché una <strong>ABP</strong> o un <strong>KPI</strong> diventi obbligatorio, lo <strong>Standard / Compliance</strong> collegato deve avere il flag <strong>"Obbligatorio con Trigger"</strong> attivo e il <strong>Trigger</strong> deve essere superato.</li>
      </ul>
    
      <h4>3. Compilazione dei Target nei KPI</h4>
      <p>
        Durante la compilazione dei <strong>Target</strong> nei KPI, il sistema verifica se lo <strong>Standard / Compliance</strong> del KPI coincide con quello del BdS.
      </p>
      <p>Il Target diventa obbligatorio se tutte queste condizioni sono soddisfatte:</p>
      <ul>
        <li>Lo <strong>Standard / Compliance</strong> del KPI ha il flag <strong>"Target"</strong> attivo.</li>
        <li>Il <strong>Trigger</strong> è stato superato.</li>
        <li>Lo <strong>Standard / Compliance</strong> che coincide con il BdS ha il flag <strong>"Obbligatorio con Trigger"</strong> attivo.</li>
      </ul>
    </div>
    `;

    this.utilityService.openDialog({
      titolo: 'Obbligatorio con Trigger',
      descrizione: dialogContent,
      fontWeight: 'normal',
      bottoni: [
        {
          nome_btn: 'Ok',
          id_btn: 'ok'
        },

      ]
    }, 1200);
  }

}
