<div mat-dialog-title>{{this.isUtenteGo ? 'Report Tematico' : 'Bilancio di Sostenibilità'}}</div>

<div mat-dialog-content class="dialog-center-cont" style="padding: 0px !important;">
    <app-spinner-overlay></app-spinner-overlay>
    <mat-stepper [linear]="!isPubblicato()" #stepper (selectionChange)="cambioStep($event)">
        <!-- STEP 1 -->
        <mat-step [stepControl]="formCreazioneBilancio" [editable]="true">

            <ng-scrollbar [visibility]="'hover'">
                <ng-template matStepLabel>
                    {{isUtenteGo ? 'Report' : 'Bilancio'}}
                </ng-template>

                <form class="form-step1" [formGroup]="formCreazioneBilancio" appFormContainer [larghezza]="12"
                    [gap]="10" [rowGap]="5" style="align-items: center;">

                    <ng-container *ngIf="!isUtenteGo">
                        <div appFormElement [larghezza]="12" [riempi]="true">
                            <div class="titolo-sezione-dialog">Creazione Bilancio di Sostenibilità</div>
                            <div class="descrizione-sezione-dialog">
                                Seleziona l'anno e definisci gli standard di rendicontazione e le normative al quale
                                allineare il Bilancio di Sostenibilità. <br>
                                Scegli tra l'approccio generico e quello settoriale. Se selezioni l'approccio settoriale
                                il sistema elaborerà un framework basato sulle specificità del settore di appartenenza.
                                La scelta del sotto-settore aumenterà l'accuratezza dell'approccio selezionato.
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="isUtenteGo">
                        <div appFormElement [larghezza]="12" [riempi]="true">
                            <div class="titolo-sezione-dialog">Creazione Report Tematico</div>
                            <div class="descrizione-sezione-dialog">
                                Seleziona l'anno e l'Assessment di riferimento per individuare al meglio i tuoi elementi
                                per il
                                tuo
                                Report Tematico.
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!isUtenteGo"> <!-- MOSTRA SOLO SE UTENTE NON GO -->

                        <div appFormElement [larghezza]="2" style="display:flex; flex-direction: column">
                            <mat-label>Tipo Settore</mat-label>
                            <mat-radio-group class="radio-tipo-sett" aria-label="Tipo settore"
                                formControlName="tipoSettore">
                                <mat-radio-button aria-label="Generico" value="GENERALE"
                                    [disabled]="this.formCreazioneBilancio.get('id')?.value || !this.settoreGenerale">
                                    <div class="eti-check">
                                        Generico
                                        <mat-icon matTooltip="Adatto ad aziende di ogni settore">info_outline</mat-icon>
                                    </div>
                                </mat-radio-button>
                                <mat-radio-button aria-label="Specifico" value="SPECIFICO"
                                    [disabled]="this.formCreazioneBilancio.get('id')?.value || !(this.settoriAzienda.length)">
                                    <div class="eti-check">
                                        Specifico
                                        <mat-icon
                                            matTooltip="Sviluppato ad hoc per il settore di appartenenza dell'azienda">info_outline</mat-icon>
                                    </div>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </ng-container>

                    <mat-form-field appFormElement [larghezza]=" this.formCreazioneBilancio.get('tipoSettore')?.value === 'SPECIFICO' ? 0.5 : 3" [riempi]="true">
                        <mat-label>Anno</mat-label>
                        <mat-select (selectionChange)="onSelectionChangeAnno($event)" formControlName="anno"
                            placeholder="Anno">
                            <mat-option *ngFor="let aa of arrayAnni" [value]="aa"
                                [disabled]="ctrAnnoBilanciUtilizzati(aa)">{{aa}}</mat-option>
                        </mat-select>
                    </mat-form-field>



                    <ng-container *ngIf="!isUtenteGo"> <!-- MOSTRA SOLO SE UTENTE NON GO -->
                        <mat-form-field appFormElement [larghezza]="3" class="input-form" [riempi]="true"
                            *ngIf="this.formCreazioneBilancio.get('tipoSettore')?.value === 'SPECIFICO'">
                            <mat-label>Settore</mat-label>
                            <mat-select formControlName="settori" placeholder="Settore" [compareWith]="compareById">
                                <mat-option *ngFor="let set of settoriAzienda" [value]="set"
                                    [disabled]="ctrSettoreBilanciUtilizzati(set)">{{set.titolo}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appFormElement [larghezza]="3" class="input-form" [riempi]="true"
                            *ngIf="this.formCreazioneBilancio.get('tipoSettore')?.value === 'SPECIFICO'">
                            <mat-label>Sotto-settore</mat-label>
                            <mat-select formControlName="sottoSettori" placeholder="Sotto-ettore"
                                [compareWith]="compareById" multiple>
                                <mat-option *ngFor="let sottoSet of arraySottoSettori"
                                    [value]="sottoSet">{{sottoSet.titolo}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div appFormElement [larghezza]="6">
                            <app-select-custom [nascondiFigli]="true" formControlName="stdr"
                                titolo="Standard di rendicontazione"></app-select-custom>
                        </div>

                        <div appFormElement [larghezza]="6">
                            <app-select-custom [nascondiFigli]="true" formControlName="compliance"
                                titolo="Compliance Normativi"></app-select-custom>
                        </div>


                        <div appFormElement [larghezza]="12">
                            <app-separatore-label [styleSeparatore]="'small'"></app-separatore-label>
                        </div>

                        <div appFormElement [larghezza]="12" [riempi]="true">

                            <div class="titolo-sezione-dialog">Informazioni Aziendali</div>

                            <div class="descrizione-sezione-dialog">
                                Compila le informazioni dimensionali dell'azienda per verificare il regime di
                                rendicontazione di sostenibilità da utilizzare.
                            </div>
                        </div>


                        <mat-form-field appFormElement [larghezza]="4" [riempi]="true">
                            <mat-label>Fatturato</mat-label>
                            <input matInput placeholder="Fatturato" formControlName="fatturato" type="number"
                                appInputNumber inputmode="numeric" min="0" max="999999999999"
                                (keydown)="onKeyDown($event)" (keyup)="keyUpSoglie()" />
                            <mat-error appFormError></mat-error>
                        </mat-form-field>


                        <mat-form-field appFormElement [larghezza]="4" [riempi]="true">
                            <mat-label>Nr. Dipendenti</mat-label>
                            <input matInput placeholder="Nr. Dipendenti" formControlName="nrDipendenti" type="number"
                                appInputNumber min="0" max="2147483646" (keydown)="onKeyDownDip($event)"
                                inputmode="numeric" (keyup)="keyUpSoglie()" />
                            <mat-error appFormError></mat-error>
                        </mat-form-field>



                        <div appFormElement [larghezza]="12">
                            <app-separatore-label [styleSeparatore]="'small'"></app-separatore-label>
                        </div>

                        <div appFormElement [larghezza]="12" [riempi]="true">

                            <div class="titolo-sezione-dialog">Non partire da zero, lega il tuo bilancio ad
                                altre
                                attività</div>

                            <div class="descrizione-sezione-dialog">
                                Collegando altri moduli al bilancio potrai utilizzare le informazioni inserite
                                precedentemente. Selezionare l'Analisi di Materialità avrà un impatto sulla scelta delle
                                tematiche di sostenibilità da rendicontare.
                            </div>
                        </div>

                        <div appFormElement [larghezza]="4">
                            <mat-form-field
                                *ngIf="formCreazioneBilancio.get('id')?.value || (formCreazioneBilancio.get('settori')?.value && arrayAnalisiMaterialita.length)"
                                style="width: 100%;">
                                <mat-label>{{mostraDescrizionMaterialita()}}</mat-label>
                                <mat-select formControlName="idMaterialita" placeholder="Analisi Materialità"
                                    (selectionChange)="onSelectionChangeMaterialita($event)">
                                    <mat-option *ngFor="let mate of arrayAnalisiMaterialita"
                                        [compareWith]="compareAnalisiMaterialita"
                                        [value]="mate">{{mate.nome}}</mat-option>
                                </mat-select>
                                <button [disabled]="hasValue('idMaterialita')" matSuffix mat-icon-button type="button"
                                    aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                    (click)="clearInput('idMaterialita', $event)">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>

                            <button [disabled]="this.formCreazioneBilancio.get('id')?.value" mat-stroked-button
                                *ngIf=" !formCreazioneBilancio.get('id')?.value && !arrayAnalisiMaterialita?.length"
                                (click)="addMaterialita()"
                                style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                                + Aggiungi Analisi di Materialità
                            </button>
                        </div>
                        <div appFormElement [larghezza]="4">
                            <mat-form-field
                                *ngIf="formCreazioneBilancio.get('id')?.value || (formCreazioneBilancio.get('settori')?.value && arraySintesiSostenibilita?.length)"
                                style="width: 100%;">
                                <mat-label>{{mostraDescrizionSintesi()}}</mat-label>
                                <mat-select formControlName="idSintesiSostenibilita"
                                    placeholder="Sintesi di Sostenibilità"
                                    (selectionChange)="onSelectionChangeSintesi($event)">
                                    <mat-option *ngFor="let sintesi of arraySintesiSostenibilita"
                                        [compareWith]="compareSintesiSostenibilita"
                                        [value]="sintesi">{{sintesi.nome}}</mat-option>
                                </mat-select>
                                <button [disabled]="hasValue('idSintesiSostenibilita')" matSuffix mat-icon-button
                                    type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                    (click)="clearInput('idSintesiSostenibilita', $event)">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>



                            <button [disabled]="this.formCreazioneBilancio.get('id')?.value" mat-stroked-button
                                *ngIf="!formCreazioneBilancio.get('id')?.value && !arraySintesiSostenibilita?.length"
                                (click)="addSintesi()"
                                style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                                + Aggiungi Sintesi di Sostenibilità
                            </button>
                        </div>

                        <div appFormElement [larghezza]="4">
                            <mat-form-field
                                *ngIf="formCreazioneBilancio.get('id')?.value || (formCreazioneBilancio.get('settori')?.value && arrayPolicySostenibilita?.length)"
                                style="width: 100%;">
                                <mat-label>{{mostraDescrizionPolicy()}}</mat-label>
                                <mat-select formControlName="idPolicySostenibilita"
                                    placeholder="Policy di Sostenibilità"
                                    (selectionChange)="onSelectionChangePolicy($event)">
                                    <mat-option *ngFor="let policy of arrayPolicySostenibilita"
                                        [compareWith]="comparePolicySostenibilita"
                                        [value]="policy">{{policy.nome}}</mat-option>
                                </mat-select>
                                <button [disabled]="hasValue('idPolicySostenibilita')" matSuffix mat-icon-button
                                    type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                    (click)="clearInput('idPolicySostenibilita', $event)">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>



                            <button [disabled]="this.formCreazioneBilancio.get('id')?.value" mat-stroked-button
                                *ngIf="!formCreazioneBilancio.get('id')?.value && !arrayPolicySostenibilita?.length"
                                (click)="addPolicy()"
                                style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                                + Aggiungi Policy di Sostenibilità
                            </button>
                        </div>

                        <div appFormElement [larghezza]="4">
                            <mat-form-field
                                *ngIf="formCreazioneBilancio.get('id')?.value || (formCreazioneBilancio.get('settori')?.value && arrayPianoSostenibilita?.length)"
                                style="width: 100%;">
                                <mat-label>{{mostraDescrizionPiano()}}</mat-label>
                                <mat-select formControlName="idPianoSostenibilita" placeholder="Piano di Sostenibilità"
                                    (selectionChange)="onSelectionChangePiano($event)">
                                    <mat-option *ngFor="let piano of arrayPianoSostenibilita"
                                        [compareWith]="comparePianoSostenibilita"
                                        [value]="piano">{{piano.nome}}</mat-option>
                                </mat-select>
                                <button [disabled]="hasValue('idPianoSostenibilita')" matSuffix mat-icon-button
                                    type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                    (click)="clearInput('idPianoSostenibilita', $event)">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>

                            <button [disabled]="this.formCreazioneBilancio.get('id')?.value" mat-stroked-button
                                *ngIf="!formCreazioneBilancio.get('id')?.value && !arrayPianoSostenibilita?.length"
                                (click)="addPiano()"
                                style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                                + Aggiungi Piano di Sostenibilità
                            </button>
                        </div>
                    </ng-container> <!-- MOSTRA SOLO SE UTENTE NON GO -->

                    <ng-container *ngIf="isUtenteGo"> <!-- MOSTRA SOLO SE UTENTE GO  -->
                        <div appFormElement [larghezza]="12">
                            <app-separatore-label [styleSeparatore]="'small'"></app-separatore-label>
                        </div>

                        <div appFormElement [larghezza]="12" [riempi]="true">

                            <div class="titolo-sezione-dialog">Non partire da zero</div>

                            <div class="descrizione-sezione-dialog">
                                Lega il tuo report all'Assessment di
                                Sostenibilità effettuato in Sostenibile.go
                            </div>
                        </div>
                    </ng-container> <!-- MOSTRA SOLO SE UTENTE GO  -->

                    <div appFormElement [larghezza]=" isUtenteGo ? 6: 4">
                        <mat-form-field
                            *ngIf=" ((formCreazioneBilancio.get('id')?.value || (formCreazioneBilancio.get('settori')?.value) || (isUtenteGo)) &&  arrayAnalisiAutovalutazione?.length)"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizioneAssessment()}}</mat-label>
                            <mat-select formControlName="idAutovalutazioneCompilata"
                                placeholder="Assessment di Sostenibilità"
                                (selectionChange)="onSelectionChangeAutovalutazione($event)">
                                <mat-option *ngFor="let auto of arrayAnalisiAutovalutazione"
                                    [compareWith]="compareAnalisiAutovalutazione" [value]="auto">{{auto.titolo}} -
                                    {{auto.esercizio}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idAutovalutazioneCompilata')" matSuffix mat-icon-button
                                type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idAutovalutazioneCompilata', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>

                    </div>

                    <ng-container *ngIf="!isUtenteGo">
                        <div appFormElement [larghezza]="12" class="segnalazione"
                            [ngClass]="{'show': mostraDescrizione}">
                            <mat-icon>error</mat-icon>
                            <div class="descrizione">
                                {{guidaDescrizione}}
                            </div>
                        </div>
                    </ng-container>

                </form>
            </ng-scrollbar>
        </mat-step>

        <!-- STEP 3 -->
        <mat-step [editable]="true" errorMessage="Campi obbligatori">
            <ng-template matStepLabel>
                Informativa
            </ng-template>

            <div appFormElement [larghezza]="3" [riempi]="true">

                <div class="titolo-sezione-dialog">Informativa Aziendale</div>

                <div class="descrizione-sezione-dialog">
                    L'informativa aziendale contiene dati rilevanti nella redazione del Bilancio di Sostenibilità, come
                    ad esempio il periodo di rendicontazione, il campo di applicazione ed altri dati definiti dagli
                    standard di reportistica. <br>
                    Assegna la compilazione dell'informativa aziendale ad una figura aziendale tramite il pulsante
                    <i>Figura</i> e notifica l'attività attraverso il pulsante <i>invia</i> , oppure compilala adesso
                    attraverso il pulsante di <i>compilazione</i>. Monitora la compilazione da parte della figura
                    aziendale attraverso il simbolo di <i>Stato</i>.
                </div>
                <div class="card-tabella mat-elevation-z2">
                    <div class="cont-tabella">

                        <table mat-table [dataSource]="arrayInformativa ? arrayInformativa: []"
                            *ngIf="arrayInformativa">

                            <ng-container matColumnDef="figura">
                                <th mat-header-cell class="titolo-colonna" *matHeaderCellDef>
                                    Figure Aziendali
                                </th>
                                <td mat-cell class="dati-righe" *matCellDef="let elem">{{ elem?.figura }}</td>
                            </ng-container>
                            <ng-container matColumnDef="nome">
                                <th mat-header-cell class="titolo-colonna" *matHeaderCellDef>Nome </th>
                                <td mat-cell class="dati-righe" *matCellDef="let elem">{{ elem?.nome }}</td>
                            </ng-container>
                            <ng-container matColumnDef="email">
                                <th mat-header-cell class="titolo-colonna" *matHeaderCellDef>Email</th>
                                <td mat-cell class="dati-righe" *matCellDef="let elem">{{ elem?.email}}</td>
                            </ng-container>
                            <ng-container matColumnDef="stato">
                                <th mat-header-cell class="titolo-colonna" *matHeaderCellDef>Stato</th>
                                <td mat-cell class="dati-righe" *matCellDef="let elem">
                                    <!-- {{ elem?.stato }} -->
                                    <div *ngIf="elem?.stato === 'ROSSO'"
                                        style="display: flex;justify-content: center;align-items:center;background-color:#cf5b61;width:25px;text-align: center;border-radius: 15px;height:25px">
                                    </div>
                                    <div *ngIf="elem?.stato === 'GIALLO'"
                                        style="display: flex;justify-content: center;align-items:center;background-color:#c7a045;width:25px;text-align: center;border-radius: 15px;height:25px">
                                    </div>
                                    <div *ngIf="elem?.stato === 'VERDE'"
                                        style="display: flex;justify-content: center;align-items:center;background-color:#729373;width:25px;text-align: center;border-radius: 15px;height:25px">
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="azioni">
                                <th mat-header-cell class="titolo-colonna" *matHeaderCellDef style="text-align: right;">
                                    Azioni</th>
                                <td mat-cell class="dati-righe" *matCellDef="let elem">
                                    <div appFormElement [larghezza]="0" [minWidth]="50"
                                        style="display: flex; justify-content: end; column-gap: 10px">
                                        <button class="icone-button" mat-icon-button (click)="compilazione(elem)"
                                            [title]="(elem?.stato === 'VERDE' || isPermessoSoloRead ) ? 'Visualizza' : 'Compila'"
                                            [attr.aria-label]="(elem?.stato === 'VERDE' || isPermessoSoloRead ) ? 'Visualizza' : 'Compila'">
                                            <mat-icon class="icone">
                                                {{(elem?.stato === 'VERDE' || isPermessoSoloRead ) ? 'visibility' :
                                                'history_edu'}}
                                            </mat-icon>
                                        </button>
                                        <button class="icone-button" mat-icon-button (click)="figura(elem)"
                                            title="Figura" [attr.aria-label]="'Figura'"
                                            *ngIf="elem.stato !== 'VERDE' && !isPermessoSoloRead">
                                            <mat-icon class="icone">
                                                person
                                            </mat-icon>
                                        </button>
                                        <button class="icone-button" mat-icon-button
                                            (click)="inviaMailInformativa(elem)" title="Invia"
                                            *ngIf="!elem.ultimoInvio && elem.stato !== 'VERDE' && isPermessoInvioMail"
                                            [attr.aria-label]="'Invia'">
                                            <mat-icon class="icone">
                                                mail
                                            </mat-icon>
                                        </button>
                                        <button class="icone-button" mat-icon-button
                                            (click)="inviaNotifInformativa(elem)" title="Notifica"
                                            *ngIf="elem.ultimoInvio !== '' && elem.stato !== 'VERDE' && isPermessoInvioMail"
                                            [attr.aria-label]="'Notifica'">
                                            <mat-icon class="icone">
                                                notifications_active
                                            </mat-icon>
                                        </button>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="riga-colonna">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="riga-riga"></tr>

                        </table>

                    </div>
                </div>
            </div>
        </mat-step>

        <!-- ? CREAZIONE SOURVEY  -->
        <mat-step [editable]="true" errorMessage="Campi obbligatori">
            <ng-template matStepLabel>
                Tematiche
            </ng-template>

            <app-cfg-questionario #surveyComp [isPermessoSoloRead]="isPermessoSoloRead" [tipoQuestionario]="'BILANCIO'"
                [cfgQuestionario]="bilancio" [idSostenibilita]="formCreazioneBilancio.get('id')?.value"
                [nascondiTitolo]="true"></app-cfg-questionario>

        </mat-step>
        <!--  ---------------------- I M P A T T I ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Impatti
            </ng-template>
            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">
                    <div class="titolo-sezione-dialog">
                        Selezione degli Impatti
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Gli impatti descrivono sia le conseguenze che un tema, problema o fattore di sostenibilità hanno
                        sull'azienda stessa, sia l'effetto che l'azienda genera sull'ambiente e sugli stakeholder.<br>
                        <br>
                        Per ogni tematica scelta, è necessario selezionare almeno un impatto
                    </div>

                </div>
            </div>
            <app-selezione-impatti-bilancio [isPermessoSoloRead]="isPermessoSoloRead"
                [objQuestionario]="objQuestionarioImpatti" chiaveElemento="elementi"
                [idContest]="this.formCreazioneBilancio.get('id')?.value" [stato]="bilancio?.stato" [isImpatto]="true"
                [isMaterialitaSelezionata]="isMaterialitaSelezionata" chiaveElementoSelezione="impatto">

            </app-selezione-impatti-bilancio>

        </mat-step>
        <!--  ---------------------- I M P E G N I ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Impegni
            </ng-template>
            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">
                    <div class="titolo-sezione-dialog">
                        Selezione degli Impegni
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Gli impegni rappresentano delle dichiarazioni di intento dell'azienda ad operare creando impatti
                        positivi e limitando quelli negativi rispetto alle specifiche tematiche di sostenibilità. <br>
                        <br>
                        Seleziona almeno un impegno aziendale e descrivilo attraverso la compilazione. Scegliendo dalla
                        lista proposta troverai linee guida ad hoc per la compilazione.
                    </div>

                </div>
            </div>

            <app-selezione-impegni-bilancio [isPermessoSoloRead]="isPermessoSoloRead"
                [objQuestionario]="objQuestionarioImpegni" chiaveElemento="elementi"
                [idContest]="this.formCreazioneBilancio.get('id')?.value" [stato]="bilancio?.stato"
                chiaveElementoSelezione="impegno">

            </app-selezione-impegni-bilancio>

        </mat-step>

        <!--  ---------------------- Azioni e Buone Pratiche  ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Azioni e Buone Pratiche
            </ng-template>
            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">
                    <div class="titolo-sezione-dialog">
                        Selezione delle Azioni e Buone Pratiche
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Seleziona le azioni e le buone pratiche che l'azienda mette in atto per le diverse tematiche di
                        sostenibilità. <br>
                        <br>
                        Attraverso il pulsante <mat-icon>edit</mat-icon> potrai modificare la descrizione delle singole
                        azioni selezionate per rappresentare al meglio le iniziative messe in atto dall'azienda, oltre a
                        inserire specifiche istruzioni di compilazione per la figura designata all'attività attraverso
                        il campo <i>“Note”</i>.
                    </div>

                </div>
            </div>

            <app-selezione-azioni-buonepratiche-bilancio [isPermessoSoloRead]="isPermessoSoloRead"
                [objQuestionario]="objQuestionarioAzioniBP" chiaveElemento="elementi"
                [idContest]="this.formCreazioneBilancio.get('id')?.value" [stato]="bilancio?.stato"
                [isSoglieSuperate]="isSogliaSuperata()" chiaveElementoSelezione="azionebp">

            </app-selezione-azioni-buonepratiche-bilancio>

        </mat-step>
        <!--  ---------------------- K P I ---------- -->
        <mat-step>

            <ng-template matStepLabel>KPI</ng-template>

            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">
                    <div class="titolo-sezione-dialog">
                        Selezione dei KPI
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Seleziona i KPI (Key Performance Indicator) che l'azienda misura e monitora. Gli indicatori
                        rappresentano tramite dati quantitativi gli impatti che l'azienda ha in termini di sostenibilità
                        sulle specifiche tematiche.
                    </div>

                </div>
            </div>

            <div style="display: flex;flex-direction: column;padding-top: 10px;">

                <div class="titolo-sezione-dialog" style="padding-bottom: 5px;">
                    Includi Anni Precedenti
                </div>

                <div class="descrizione-sezione-dialog">
                    <mat-slide-toggle labelPosition="before" [disabled]="isPermessoSoloRead"
                        [(ngModel)]="valueToggleAnni" [checked]="valueToggleAnni" (change)="cambioToggle($event)">
                        Abilità la compilazione dei KPI per gli anni precedenti a quello di rendicontazione attraverso
                        il bottone a lato e attiva le funzionalità connesse all'inserimento dello storico degli
                        indicatori.<br>
                        <br>
                        Nota: nei Bilanci di Sostenibilità è buona prassi rendicontare i dati relativi al biennio
                        precedente a quello di rendicontazione.
                    </mat-slide-toggle>
                </div>

            </div>

            <app-selezione-kpi-bilancio [isPermessoSoloRead]="isPermessoSoloRead" [objQuestionario]="objQuestionarioKPI"
                chiaveElemento="elementi" [idContest]="this.formCreazioneBilancio.get('id')?.value"
                [stato]="bilancio?.stato" chiaveElementoSelezione="kpi" [isSoglieSuperate]="isSogliaSuperata()">

            </app-selezione-kpi-bilancio>


        </mat-step>
        <!--  ---------------------- Certificazioni  ---------- -->
        <mat-step>

            <ng-template matStepLabel>Certificazioni</ng-template>

            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">
                    <div class="titolo-sezione-dialog">
                        Selezione delle Certificazioni
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Seleziona le certificazioni possedute dall’azienda
                    </div>

                </div>
            </div>

            <app-selezione-certificazioni-bilancio [isPermessoSoloRead]="isPermessoSoloRead"
                [objQuestionario]="objQuestionarioCertificazioni" chiaveElemento="elementi"
                [idContest]="this.formCreazioneBilancio.get('id')?.value" [stato]="bilancio?.stato"
                chiaveElementoSelezione="certif">
            </app-selezione-certificazioni-bilancio>

        </mat-step>
        <!--  ---------------------- C R U S C O T T O ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Cruscotto
            </ng-template>
            <div class="barra-titolo-cruscotto">

                <div style="display: flex;flex-direction: column;flex: 1;">
                    <div class="titolo-sezione-dialog">
                        {{isUtenteGo ? 'Cruscotto Report Tematico' : 'Cruscotto Bilancio di Sostenibilità'}}
                    </div>

                    <div class="descrizione-sezione-dialog">
                        Visualizza e gestisci ogni aspetto relativo alla compilazione: avanzamento, configurazione dei
                        testi mail, invio e notifiche.
                    </div>
                </div>

                <div class="contatori">
                    <div style="text-align: center;">
                        <div class="titolo-cont">KPI</div>
                        <div class="contatore">
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #729373;">{{kpiCompilati}}
                                </div>
                                <div class="label">compilati</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #CF5B61;">{{kpiNonCompilati}}
                                </div>
                                <div class="label">non compilati</div>
                            </div>
                        </div>
                    </div>

                    <div style="text-align: center;">
                        <div class="titolo-cont">AZIONE E BUONE PRATICHE</div>
                        <div class="contatore">
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #729373;">{{azioneCompilati}}
                                </div>
                                <div class="label">compilati</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #CF5B61;">{{azioneNonCompilati}}
                                </div>
                                <div class="label">non compilati</div>
                            </div>
                        </div>
                    </div>

                    <div style="text-align: center;">
                        <div class="titolo-cont">IMPEGNI</div>
                        <div class="contatore">
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #729373;">{{impegniCompilati}}
                                </div>
                                <div class="label">compilati</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #CF5B61;">{{impegniNonCompilati}}
                                </div>
                                <div class="label">non compilati</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ng-scrollbar>
                <div style="padding-right: 15px;">
                    <app-lista-tabellare #tabella [defaultPageSize]="4" [fnCaricamentoDati]="fnCaricamentoDatiCruscotto"
                        [colonne]="colonne" [maxNrButton]="1" [filtri]="filtri"
                        (bottoniListaClick)="bottoniListaClick($event)"
                        *ngIf="this.formCreazioneBilancio.get('id')?.value">
                        <ng-container btnEnd>

                            <button [disabled]="!isPermessoInvioMail" mat-flat-button class="primary-button"
                                color="primary" (click)="template()">
                                <mat-icon matSuffix>settings</mat-icon>
                                Config. Testi
                            </button>

                            <button [disabled]="!isPermessoInvioMail" mat-flat-button class="primary-button"
                                color="primary" (click)="inviaTutti()">
                                <mat-icon matSuffix>mail</mat-icon>
                                Invia a Tutti
                            </button>

                            <button [disabled]="!isPermessoInvioMail" mat-flat-button class="primary-button"
                                color="primary" (click)="notificaTutti()">
                                <mat-icon matSuffix>notifications_active</mat-icon>
                                Notifica Tutti
                            </button>

                        </ng-container>
                    </app-lista-tabellare>
                </div>
            </ng-scrollbar>

        </mat-step>
        <!--  ---------------------- S T A M P A ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Configurazione Stampa
            </ng-template>

            <app-cfg-stampa [isPermessoSoloRead]="isPermessoSoloRead" [fnGetConfigStampa]="fnGetConfigStampa"
                [fnSalvaCfgStampa]="fnSalvaCfgStampa" [fnCaricaImg]="fnImportaImmagine" [fnEliminaImg]="fnEliminaImg"
                (loading)="toggleLoading($any($event))" [titolo]="'Seleziona e compila gli elementi che compongono il Bilancio di Sostenibilità dell\'azienda'"></app-cfg-stampa>


        </mat-step>
    </mat-stepper>

</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-flat-button color="accent" [disabled]="stepper.selectedIndex === 0" (click)="indietro()"
            class="accent-button">Indietro</button>
    </div>
    <ng-container *ngIf="stepper.selectedIndex !== 9">
        <div>
            <button mat-flat-button color="primary" (click)="avantiHTML()" class="primary-button" #btnAvanti>
                Avanti
            </button>
        </div>

        <div *ngIf="mostraBottoneAssegnazioni">
            <button mat-flat-button color="primary" (click)="assegnazioni(stepper.selectedIndex)"
                class="primary-button">
                <mat-icon>person_edit</mat-icon>
                Assegnazioni
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="stepper.selectedIndex === 9">
        <button *ngIf="!isPermessoSoloRead" mat-flat-button color="primary" (click)="salvaCfgStampa()"
            class="primary-button">
            Salva
        </button>
        <button *ngIf="isPermessoSoloRead" mat-flat-button color="primary" matDialogClose class="primary-button">
            Chiudi
        </button>
        <button [disabled]="!this.cfgStampaComponent.isPermessoStampa" mat-flat-button color="accent" (click)="stampa()"
            class="accent-button" style="margin: 0 !important;">
            <mat-icon class="material-icons-outlined">
                print
            </mat-icon>
            Stampa
        </button>
    </ng-container>
</div>