<app-spinner-overlay></app-spinner-overlay>

<div class="container-titolo">
    <div>
        <div class="titolo">
            Policy di Sostenibilità
        </div>
    </div>
    <div class="separatore">
    </div>
</div>

<div style="margin-bottom: 15px;"></div>
<ng-container *ngIf="this.data.comitato">

    <div mat-dialog-content class="dialog-center-cont contenitore">
        <div class="descrizione-comitato">{{this.data.comitato.descrizione}}</div>

        <div class="testo-linea-guida">
            <div style="display: flex;">
                <mat-icon class="material-icons-outlined icone-tem">error</mat-icon>
            </div>
            <div class="titolo-linea-guida">
                <div style="font-weight: bold;">
                    Spiega il ruolo del comitato rispetto alla sostenibilità e al documento “policy di sostenibilità”
                </div>
                {{this.data.comitato.guidaAllaScrittura}}
            </div>
        </div>

        <form [formGroup]="formRisposta" appFormContainer [larghezza]="3">
            <mat-form-field class="risposta" appFormElement [larghezza]="3" [riempi]="true">
                <mat-label>Risposta</mat-label>
                <textarea formControlName="risposta" matInput [placeholder]="'Risposta'" rows="4"></textarea>
                <mat-error appFormError></mat-error>
            </mat-form-field>
        </form>


        <div class="separa" appFormElement [larghezza]="3" [riempi]="true"></div>
    </div>

    <div mat-dialog-actions class="dialog-footer-cont">
        <div>
            <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
        </div>
        <div>
            <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
                Conferma
            </button>
        </div>
    </div>

</ng-container>