<app-spinner-overlay></app-spinner-overlay>
<h2 mat-dialog-title>Standard di Rendicontazione e Compliance</h2>
<div mat-dialog-content class="dialog-center-cont"
    style="    display: flex;    flex-direction: column; padding-top: 20px;">
    <form [formGroup]="formStdr" appFormContainer [larghezza]="4" [rowGap]="5">
        <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
            <mat-label>Codice </mat-label>
            <input matInput placeholder="Codice" formControlName="codice" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
            <mat-label>Descrizione </mat-label>
            <input matInput placeholder="Descrizione" formControlName="descrizione" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <div class="cont-slide">
            <mat-slide-toggle formControlName="compliance" labelPosition="before"><span class="titolo-slide"> Compliance Normativi
                </span></mat-slide-toggle>

            <mat-slide-toggle formControlName="generale" labelPosition="before"><span
                    class="titolo-slide">Generale</span> </mat-slide-toggle>

            <mat-slide-toggle formControlName="settoriale" labelPosition="before"
                (change)="slideSettoriale($event)"><span class="titolo-slide">Settoriale</span> </mat-slide-toggle>

            <div style="display: flex; column-gap: 15px; align-items: center;">
                <mat-slide-toggle formControlName="obbligatorio" labelPosition="before"><span
                        class="titolo-slide">Obbligatorio con Trigger</span> </mat-slide-toggle>

                        <button mat-icon-button aria-label="Informazioni Analisi Risultati" (click)="apriInfo()">
                            <mat-icon class="material-icons-outlined icone-label-chack">
                                info</mat-icon>
                        </button>

            </div>

        </div>

    </form>
    <ng-container *ngIf="this.formStdr.get('settoriale')?.value">

        <div style="padding-top: 25px;"></div>
        <app-separatore-label titoloSeparatore="Lega dei Sotto Standard di Rendicontazione"
            [styleSeparatore]="'medium'"></app-separatore-label>

        <form [formGroup]="formStdr" appFormContainer [larghezza]="4" [rowGap]="20" style="height: 100%;">
            <div class="cont-figli">
                <ng-scrollbar>
                    <div style="padding: 0px 20px;">
                        <mat-accordion appFormElement [larghezza]="3">

                            <div cdkDropList
                                (cdkDropListDropped)="dropFigliStdr($event, $any(this.formStdr.get('figli')))">

                                <mat-expansion-panel expanded="true"
                                    *ngFor="let figliStdr of $any(formStdr.get('figli'))?.controls; let idx2 = index"
                                    cdkDrag>

                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <button mat-icon-button title="Sposta">
                                                <mat-icon> drag_handle </mat-icon>
                                            </button>

                                            <ng-container *ngIf="figliStdr?.controls?.codice?.value">
                                                {{figliStdr?.controls?.codice?.value }}
                                            </ng-container>

                                            <ng-container *ngIf="!figliStdr?.controls?.codice?.value ">
                                                <span style="font-weight: normal;
                                           color: gray;
                                           font-size: 12px;"> Inserisci codice e descrizione del nuovo Standard di
                                                    Rendicontazione da legare...</span>
                                            </ng-container>

                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <div class="testo-risposta">
                                                {{figliStdr?.controls?.descrizione?.value }}
                                            </div>
                                            <button mat-icon-button title="Elimina Sottorisposta"
                                                aria-label="Elimina Sottorisposta" color="warn"
                                                (click)="elimina( $any(formStdr.get('figli')), idx2)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>

                                    <div appFormContainer [larghezza]="3" [formGroup]="figliStdr">

                                        <mat-form-field (mousedown)="$event.stopPropagation()" appFormElement
                                            [larghezza]="1" [riempi]="true">
                                            <mat-label>Codice</mat-label>
                                            <input matInput placeholder="Codice"
                                                [formControl]="figliStdr?.controls?.codice" />
                                            <mat-error appFormError></mat-error>
                                        </mat-form-field>

                                        <mat-form-field (mousedown)="$event.stopPropagation()" appFormElement
                                            [larghezza]="3" [riempi]="true">
                                            <mat-label>Descrizione</mat-label>
                                            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                cdkAutosizeMinRows="1" placeholder="Descrizione"
                                                [formControl]="figliStdr?.controls?.descrizione" rows="1"></textarea>
                                            <mat-error appFormError></mat-error>
                                        </mat-form-field>

                                        <mat-form-field appFormElement [larghezza]="4">
                                            <mat-label>Settore</mat-label>
                                            <mat-select [formControl]="figliStdr?.controls?.settore"
                                                placeholder="Settore" [compareWith]="compareById">
                                                <mat-option *ngFor="let set of arraySettori"
                                                    [value]="set">{{set.titolo}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>


                                    </div>

                                </mat-expansion-panel>


                            </div>
                        </mat-accordion>
                    </div>
                </ng-scrollbar>
                <div style="height: 20px;"></div>


                <button mat-stroked-button (click)="addFigliStdr($any(formStdr.get('figli')))"
                    style="height: 56px;width: 100%;font-weight: bold;background: #f5f5f5;">
                    Aggiungi Standard
                </button>
            </div>
        </form>
    </ng-container>

</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Salva
        </button>
    </div>
</div>