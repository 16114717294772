import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-titolo-subtitolo',
  templateUrl: './titolo-subtitolo.component.html',
  styleUrls: ['./titolo-subtitolo.component.scss']
})
export class TitoloSubtitoloComponent {

  private _titoloSafe: SafeHtml | undefined = undefined;

  get titoloSafe() {
    return this._titoloSafe;
  }

  private _subTitoloSafe: SafeHtml | undefined = undefined;

  get subTitoloSafe() {
    return this._subTitoloSafe;
  }


  private _titolo: string = '';
  private _subTitolo: string = '';

  @Input()
  /* titolo */
  set titolo(titolo: string) {
    this._titolo = titolo;

    this._titoloSafe = this.sanitizer.bypassSecurityTrustHtml(this._titolo);
  }
  get titolo() {
    return this._titolo;
  }

  @Input()
  /* titolo */
  set subTitolo(subTitolo: string) {
    this._subTitolo = subTitolo;

    this._subTitoloSafe = this.sanitizer.bypassSecurityTrustHtml(this._subTitolo);
  }
  get subTitolo() {
    return this._subTitolo;
  }

  constructor(
    private sanitizer: DomSanitizer
  ) { }
}
