import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { PerimetriDestinatari, PerimetriDestinatariGrp, PolicyService } from 'src/app/services/policy/policy.service';
import { CompilaPerimetriDestinatariComponent } from './compila-perimetri-destinatari/compila-perimetri-destinatari.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { catchError, firstValueFrom, Observable, of, tap } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { DialogCreaDestinatariPolicyComponent } from 'src/app/page/configurazioni/cfg-destinatari-policy/dialog/dialog-crea-destinatari-policy/dialog-crea-destinatari-policy.component';

@Component({
  selector: 'app-seleziona-perimetro-destinatari',
  templateUrl: './seleziona-perimetro-destinatari.component.html',
  styleUrls: ['./seleziona-perimetro-destinatari.component.scss']
})
export class SelezionaPerimetroDestinatariComponent {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public arrayPerimetriDestinatari: PerimetriDestinatariGrp[] = [];;
  public nrSelezionatiPerimetri: number = 0;
  public nrSelezionatiDestinatari: number = 0;
  public nrSelezionati: number = 0;
  private _idPolicy: string | undefined;
  public indexTab: number | undefined = undefined;
  @Input()
  get idPolicy(): string | undefined {
    return this._idPolicy;
  }

  set idPolicy(idPolicy: string | undefined) {
    this._idPolicy = idPolicy;
  }

  private _isPermessoSoloRead = false;
  @Input()
  set isPermessoSoloRead(isPermessoSoloRead: boolean) {
    this._isPermessoSoloRead = isPermessoSoloRead;
  }
  get isPermessoSoloRead() {
    return this._isPermessoSoloRead;
  }
  public step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  constructor(
    private readonly utilityService: UtilityService,
    public dialog: MatDialog,
    private readonly policyService: PolicyService,
  ) {

  }

  onChangeCheck(event: MatCheckboxChange, elemento: PerimetriDestinatari, indexTab: number) {

    if (event.checked && !elemento.lineaGuidaCompilata) {
      this.compilaPerimetro(elemento, indexTab);
    }
  }

  /**
   * Get Perimetri e Destinatari da compilare 
   * @returns 
   */
  public getPerimetriDestinatari(): Observable<any> {
    this.spinnerOver.show();

    if (this.idPolicy) {
      return this.policyService.getPerimetriDestinatari(this.idPolicy).pipe(
        tap((esito) => {
          this.arrayPerimetriDestinatari = esito;
        }),
        catchError((err) => {
          console.error(err)
          return of()
        }),
        tap({
          finalize: () => this.spinnerOver.hide()
        })
      )
    }
    return of()
  }

  public salvaPerimetriDestinatari(): Observable<any> {
    this.spinnerOver.show();
    if (this.idPolicy) {

      return this.policyService.putPerimetriDestinatari(this.idPolicy, this.arrayPerimetriDestinatari).pipe(
        tap((esito) => {


        }),
        catchError((err) => {
          console.error(err);
          return of()
        }),
        tap({
          finalize: () => this.spinnerOver.hide()
        })

      )
    }
    return of();
  }

  /**
   * Metodo di compilazione perimetro o destinatari
   * @param perimetriDestinatari 
   */
  async compilaPerimetro(perimetriDestinatari: PerimetriDestinatari, index: number) {
    this.indexTab = index;

    try {

      if (!this.isPermessoSoloRead) {
        await firstValueFrom(this.salvaPerimetriDestinatari());
      }


      const dialogCompilaComitato = this.dialog.open(CompilaPerimetriDestinatariComponent, {
        data: {
          perimetroDestinatario: perimetriDestinatari,
          idPolicy: this._idPolicy,
          isPermessoSoloRead: this.isPermessoSoloRead
        },
        panelClass: 'dialog-container',
        disableClose: true,
        width: '70%',
        maxHeight: '95%',
        autoFocus: false,
      });

      dialogCompilaComitato.afterClosed().subscribe((esito: any) => {
        if (esito) {

          this.getPerimetriDestinatari().subscribe();
        }
      });

    } catch (error) {
      console.error(error);

    }
  }

  /**
   * Ritorna il numero di elementi selezionati
   * @param elem 
   * @returns 
   */
  public numeroSelezionatiCount(elem: PerimetriDestinatariGrp): number {
    this.nrSelezionati = elem.perimetriDestinatari.filter(perimetroDestinatario => perimetroDestinatario.selected).length;
    return this.nrSelezionati;
  }

  /**
 * Controllo se i Perimetri o destinatari selezionati sono stati compilati
 */
  isPerimetriDestinataoriSelezionatiCompilati(): boolean {

    const elementoNonCompilato = this.arrayPerimetriDestinatari.some(perimDest =>
      perimDest.perimetriDestinatari.some(elemento => elemento.selected && !elemento.lineaGuidaCompilata)
    );

    if (elementoNonCompilato) {
      this.utilityService.opneSnackBar('Compilare tutti i comitati selezionati', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
      return false;
    }
    return true;
  }

  creaNuovoDestinatario() {
    const dialogCreaDestinatarioPolicy = this.dialog.open(DialogCreaDestinatariPolicyComponent, {
      data: {
        idPolicySostenibilita: this._idPolicy
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '75%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaDestinatarioPolicy.afterClosed().subscribe(() => {
      this.getPerimetriDestinatari().subscribe();
    });
  }
}
