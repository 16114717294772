<h2 mat-dialog-title>KPI</h2>
<div mat-dialog-content class="dialog-center-cont">
    <app-spinner-overlay></app-spinner-overlay>
    <form [formGroup]="formKPI" appFormContainer [larghezza]="6">

        <mat-form-field appFormElement [larghezza]="3" [riempi]="true">
            <mat-label>Nome Breve</mat-label>
            <input matInput placeholder="Nome Breve" formControlName="nomeBreve" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field class="input-form" appFormElement [larghezza]="3" [riempi]="true" *ngIf="!this.data.contesto">
            <mat-label>Settori</mat-label>
            <mat-select formControlName="settori" placeholder="Settori" [(value)]="selectedSettori" multiple
                [compareWith]="compareSettori">
                <mat-option *ngFor="let set of arraySettori" [value]="set">{{set.titolo}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="6" [riempi]="true">
            <mat-label>Descrizione</mat-label>
            <textarea matInput placeholder="Descrizione" formControlName="descrizione" rows="4"></textarea>
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <app-autocomplete appFormElement [larghezza]="3" [riempi]="true" formControlName="idCategoriaUnitaDiMisura"
            [label]="'Categorie'" [loadDataFn]="getCategorie" [displayFn]="displayFn" [compare]="compareCategorie"
            (recordChange)="getUnitaDiMisura($event)" [required]="true" [chiaveId]="'id'" [numeroRecOptions]="infiniteRecords" >
        </app-autocomplete>

        <mat-form-field class="input-form" appFormElement [larghezza]="3" [riempi]="true">
            <mat-label>Unità di Misura di Riferimento</mat-label>
            <mat-select formControlName="idUnitaDiRiferimento" placeholder="Unità di Misura di Riferimento"
                [(value)]="selectedUnitaDiMisura" [compareWith]="compareUnitaDiMisura">
                <mat-option *ngFor="let um of arrayUnitaDiMisura" [value]="um">{{um.descrizione}}</mat-option>
            </mat-select>
            <mat-error appFormError></mat-error>
        </mat-form-field>


        <mat-form-field appFormElement [larghezza]="6" [riempi]="true" *ngIf="!this.data.contesto">
            <mat-label>Note compilazione</mat-label>
            <textarea matInput placeholder="Note compilazione" formControlName="note" rows="4"></textarea>
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="2" [riempi]="true" *ngIf="!this.data.contesto">
            <mat-label>TAG</mat-label>
            <input matInput placeholder="TAG" formControlName="tag" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <div appFormElement [larghezza]="4" [riempi]="true" *ngIf="!this.data.contesto">
            <button mat-stroked-button (click)="opeDialogTarget()" [matBadge]="selectedStdr.length || 0 "
                style="height: 56px;font-weight: bold;background-color: #f5f5f5;">
                Configurazione Standard di Rendicontazione, Compliance Normativi e Target
            </button>
        </div>

        <!--     <section class="section" *ngIf="!this.data.contesto">
            <mat-checkbox color="primary" formControlName="evidenziaNote">
                <h1 style="font-size: 14px;
                font-weight: bold;">Evidenzia Note in Infobox</h1>
            </mat-checkbox>
        </section> -->

    </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Salva
        </button>
    </div>
</div>
