import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './page/login/login.component';
import { MaterialModule } from './modules/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './page/home/home.component';
import { WidgetDashboardBarComponent } from './components/widget-dashboard-bar/widget-dashboard-bar.component';
import { WidgetDashboardChartComponent } from './components/widget-dashboard-chart/widget-dashboard-chart.component';
import { AutovalutazioniComponent } from './page/autovalutazioni/autovalutazioni.component';
import { StatisticheComponent } from './page/statistiche/statistiche.component';
import { AziendeComponent } from './page/aziende/aziende.component';
import { HttpClientModule } from '@angular/common/http';
import { DashboardAziendaComponent } from './page/dashboard-azienda/dashboard-azienda.component';
import { NgChartsModule } from 'ng2-charts';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { FormContainerDirective } from './directives/forms/form-container.directive';
import { FormElementDirective } from './directives/forms/form-element.directive';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SeparatoreLabelComponent } from './components/separatore-label/separatore-label.component';
import { TitoloSubtitoloComponent } from './components/titolo-subtitolo/titolo-subtitolo.component';
import { ListaTabellareComponent } from './components/lista-tabellare/lista-tabellare.component';
import { ListaTabellareSempliceComponent } from './components/lista-tabellare-semplice/lista-tabellare-semplice.component';
import { DialogFiltroTabellareComponent } from './components/lista-tabellare/dialog/dialog-filtro-tabellare/dialog-filtro-tabellare.component';
import { FormErrorDirective } from './directives/forms/form-error.directive';
import { CfgAutovalutazioniComponent } from './page/configurazioni/cfg-autovalutazioni/cfg-autovalutazioni.component';
import { DialogCreaAziendaComponent } from './page/aziende/dialog/dialog-crea-azienda/dialog-crea-azienda.component';
import { DialogCreaUtentiComponent } from './page/utenti/dialog/dialog-crea-utenti/dialog-crea-utenti.component';
import { UtentiComponent } from './page/utenti/utenti.component';
import { CfgAmbitiComponent } from './page/configurazioni/cfg-ambiti/cfg-ambiti.component';
import { DialogCreaAmbitoComponent } from './page/configurazioni/cfg-ambiti/dialog/dialog-crea-ambito/dialog-crea-ambito.component';
import { DialogCreaCfgAutovalutazioneComponent } from './page/configurazioni/cfg-autovalutazioni/dialog/dialog-crea-cfg-autovalutazione/dialog-crea-cfg-autovalutazione.component';
import { DialogAlertComponent } from './components/dialog-alert/dialog-alert.component';
import { DialogCreaCfgTematicaComponent } from './page/configurazioni/cfg-tematiche/dialog/dialog-crea-cfg-tematica/dialog-crea-cfg-tematica.component';
import { CfgTematicheComponent } from './page/configurazioni/cfg-tematiche/cfg-tematiche.component';
import { DialogAnteprimaComponent } from './page/configurazioni/cfg-autovalutazioni/dialog/dialog-anteprima/dialog-anteprima.component';
import { DialogVisUtenteComponent } from './page/aziende/dialog/dialog-vis-utente/dialog-vis-utente.component';
import { ListaUtentiComponent } from './components/lista-utenti/lista-utenti.component';
import { DialogVisAziendeComponent } from './page/utenti/dialog/dialog-vis-aziende/dialog-vis-aziende.component';
import { ListaAziendeComponent } from './components/lista-aziende/lista-aziende.component';
import { CfgCategorieStakeholderComponent } from './page/configurazioni/cfg-categorie-stakeholder/cfg-categorie-stakeholder.component';
import { StakeholderComponent } from './page/analisi-materialita/stakeholder/stakeholder.component';
import { MaterialitaComponent } from './page/analisi-materialita/materialita/materialita.component';
import { DialogCreaStakeholderComponent } from './page/analisi-materialita/stakeholder/dialog/dialog-crea-stakeholder/dialog-crea-stakeholder.component';
import { DialogCreaCategoriaStakeholderComponent } from './page/configurazioni/cfg-categorie-stakeholder/dialog/dialog-crea-categoria-stakeholder/dialog-crea-categoria-stakeholder.component';
import { DialogCreaMaterialitaComponent } from './page/analisi-materialita/materialita/dialog/dialog-crea-materialita/dialog-crea-materialita.component';
import { CfgScritturaImpattoComponent } from './page/configurazioni/cfg-scrittura-impatto/cfg-scrittura-impatto/cfg-scrittura-impatto.component';
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { CfgImpattiComponent } from './page/configurazioni/cfg-impatti/cfg-impatti.component';
import { DialogCreaCfgImpattoComponent } from './page/configurazioni/cfg-impatti/dialog/dialog-crea-cfg-impatto/dialog-crea-cfg-impatto.component';
import { SelezionaStakeholdersComponent } from './components/materialita/seleziona-stakeholders/seleziona-stakeholders.component';
import { DialogFiltroGenericoComponent } from './components/dialog-filtro-generico/dialog-filtro-generico.component';
import { DialogCreaTemplateMailComponent } from './page/analisi-materialita/materialita/dialog/dialog-crea-template-mail/dialog-crea-template-mail.component';
import { CfgSettoriComponent } from './page/configurazioni/cfg-settori/cfg-settori.component';
import { DialogCreaCfgSettoriComponent } from './page/configurazioni/cfg-settori/dialog/dialog-crea-cfg-settori/dialog-crea-cfg-settori.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { it } from 'date-fns/locale';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';

import { DialogAnteprimaSurveyComponent } from './page/analisi-materialita/materialita/dialog/dialog-anteprima-survey/dialog-anteprima-survey.component';
import { StarRatingConfigService, StarRatingModule } from 'angular-star-rating';
import { NgStarRatingService } from './services/ng-star-rating/ng-star-rating.service';
import { MyRatingStarComponent } from './components/my-rating-star/my-rating-star.component';
import { AnteprimaSurveyComponent } from './components/anteprima-survey/anteprima-survey.component';
import { SurveyEsternoComponent } from './page/survey-esterno/survey-esterno.component';
import { AnalisiRisultatiComponent } from './components/analisi-risultati/analisi-risultati.component';
import { DialogImpostaEsclusioniComponent } from './components/analisi-risultati/dialog/dialog-imposta-esclusioni/dialog-imposta-esclusioni.component';
import { FigureAziendeComponent } from './page/aziende/figure-aziende/figure-aziende.component';
import { DialogCreaFigureAziendeComponent } from './page/aziende/figure-aziende/dialog/dialog-crea-figure-aziende/dialog-crea-figure-aziende.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { DialogCompilaAutovalutazioneComponent } from './page/autovalutazioni/dialog/dialog-compila-autovalutazione/dialog-compila-autovalutazione.component';
import { CfgImpegniComponent } from './page/configurazioni/cfg-impegni/cfg-impegni.component';
import { DialogCreaImpegnoComponent } from './page/configurazioni/cfg-impegni/dialog/dialog-crea-impegno/dialog-crea-impegno.component';
import { CfgAzioneEBuonaPraticaComponent } from './page/configurazioni/cfg-azione-e-buona-pratica/cfg-azione-e-buona-pratica.component';
import { DialogCreaCfgAzioneEBuonaPraticaComponent } from './page/configurazioni/cfg-azione-e-buona-pratica/dialog/dialog-crea-cfg-azione-e-buona-pratica/dialog-crea-cfg-azione-e-buona-pratica.component';
import { DialogCreaAutovalutazioneComponent } from './page/autovalutazioni/dialog/dialog-crea-autovalutazione/dialog-crea-autovalutazione.component';
import { CustomTooltipDirective } from './directives/tooltip/custom-tooltip.directive';
import { CompilaAutovalutazioneComponent } from './components/compila-autovalutazione/compila-autovalutazione.component';
import { SintesiSostenibilitaComponent } from './page/sintesi-sostenibilita/sintesi-sostenibilita.component';
import { BilancioSostebilitaComponent } from './page/bilancio-sostebilita/bilancio-sostebilita.component';
import { AutovalutazioneEsternoComponent } from './page/autovalutazione-esterno/autovalutazione-esterno.component';
import { DialogCreaSintesiSostenibilitaComponent } from './page/sintesi-sostenibilita/dialog/dialog-crea-sintesi-sostenibilita/dialog-crea-sintesi-sostenibilita.component';
import { CustomTooltipComponent } from './directives/tooltip/custom-tooltip/custom-tooltip/custom-tooltip.component';
import { CfgUnitaDiMisuraComponent } from './page/configurazioni/cfg-unita-di-misura/cfg-unita-di-misura.component';
import { DialogCreaUnitaDiMisuraComponent } from './page/configurazioni/cfg-unita-di-misura/dialog/dialog-crea-unita-di-misura/dialog-crea-unita-di-misura.component';
import { CfgKPIComponent } from './page/configurazioni/cfg-kpi/cfg-kpi.component';
import { DialogCreaKPIComponent } from './page/configurazioni/cfg-kpi/dialog/dialog-crea-kpi/dialog-crea-kpi.component';
import { SelezioneElementiQuestionarioComponent } from './components/selezione-elementi-questionario/selezione-elementi-questionario.component';
import { SelezioneImpegniSintesiComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-impegni-sintesi/selezione-impegni-sintesi.component';
import { SelezioneAzioniBuonepraticheSintesiComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-azioni-buonepratiche-sintesi/selezione-azioni-buonepratiche-sintesi.component';
import { SelezioneKPISintesiComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-kpi-sintesi/selezione-kpi-sintesi.component';
import { DialogAssociaFiguraAziendaleComponent } from './components/selezione-elementi-questionario/dialog/dialog-associa-figura-aziendale/dialog-associa-figura-aziendale.component';
import { DialogImportazioneExcelComponent } from './page/analisi-materialita/stakeholder/dialog/dialog-importazione-excel/dialog-importazione-excel.component';
import { PasswordDimenticataComponent } from './page/password-dimenticata/password-dimenticata.component';
import { CfgQuestionarioComponent } from './components/cfg-questionario/cfg-questionario.component';
import { DialogCompilazioneFigureComponent } from './page/sintesi-sostenibilita/dialog/dialog-compilazione-figure/dialog-compilazione-figure.component';
import { DialogAssegnazioniComponent } from './page/sintesi-sostenibilita/dialog/dialog-assegnazioni/dialog-assegnazioni.component';
import { DialogCompilaSintesiSostenibilitaComponent } from './page/sintesi-sostenibilita/dialog/dialog-compila-sintesi-sostenibilita/dialog-compila-sintesi-sostenibilita.component';
import { CompilaSintesiSostenibilitaComponent } from './components/compila-sintesi-sostenibilita/compila-sintesi-sostenibilita.component';
import { SintesiSostenibilitaEsternaComponent } from './page/sintesi-sostenibilita-esterna/sintesi-sostenibilita-esterna.component';
import { CfgInformativaAziendaleComponent } from './page/configurazioni/cfg-informativa-aziendale/cfg-informativa-aziendale.component';
import { DialogCreaInformativaAziendaleComponent } from './page/configurazioni/cfg-informativa-aziendale/dialog/dialog-crea-informativa-aziendale/dialog-crea-informativa-aziendale.component';
import { DialogAnteprimaCompilazioneInfoAzComponent } from './page/configurazioni/cfg-informativa-aziendale/dialog/dialog-anteprima-compilazione-info-az/dialog-anteprima-compilazione-info-az.component';
import { CompilaInformativaAziendaleComponent } from './components/compila-informativa-aziendale/compila-informativa-aziendale.component';
import { SelezioneImpattiBilancioComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-impatti-bilancio/selezione-impatti-bilancio.component';
import { SelezioneImpegniBilancioComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-impegni-bilancio/selezione-impegni-bilancio.component';
import { SelezioneAzioniBuonepraticheBilancioComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-azioni-buonepratiche-bilancio/selezione-azioni-buonepratiche-bilancio.component';
import { SelezioneKpiBilancioComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-kpi-bilancio/selezione-kpi-bilancio.component';
import { InformativaAziendaleEsternaComponent } from './page/informativa-aziendale-esterna/informativa-aziendale-esterna.component';
import { DialogCreaBilancioSostenibilitaComponent } from './page/bilancio-sostebilita/dialog/dialog-crea-bilancio-sostenibilita/dialog-crea-bilancio-sostenibilita.component';
import { CfgCertificazioniComponent } from './page/configurazioni/cfg-certificazioni/cfg-certificazioni.component';
import { DialogCreaCertificazioniComponent } from './page/configurazioni/cfg-certificazioni/dialog/dialog-crea-certificazioni/dialog-crea-certificazioni.component';
import { DialogInviaMailComponent } from './page/analisi-materialita/materialita/dialog/dialog-crea-template-mail/dialog-invia-mail/dialog-invia-mail.component';
import { DialogTarghetStdrComponent } from './page/configurazioni/cfg-kpi/dialog/dialog-targhet-stdr/dialog-targhet-stdr.component';
import { BilancioSostenibilitaEsternaComponent } from './page/bilancio-sostenibilita-esterna/bilancio-sostenibilita-esterna.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { DialogGestioneLicenzeComponent } from './page/utenti/dialog/dialog-gestione-licenze/dialog-gestione-licenze.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EditorComponent } from './components/editor/editor.component';
import { SelezioneImpattiMaterialitaComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-impatti-materialita/selezione-impatti-materialita.component';
import { SelezioneCertificazioniBilancioComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-certificazioni-bilancio/selezione-certificazioni-bilancio.component';
import { SelezioneCertificazioniSintesiComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-certificazioni-sintesi/selezione-certificazioni-sintesi.component';
import { DialogEditDescrizioneIndiceComponent } from './page/bilancio-sostebilita/dialog/dialog-edit-descrizione-indice/dialog-edit-descrizione-indice.component';
import { DialogSottoSettoriComponent } from './page/configurazioni/cfg-settori/dialog/dialog-sotto-settori/dialog-sotto-settori.component';
import { DialogCreaCfgSottoSettoriComponent } from './page/configurazioni/cfg-settori/dialog/dialog-sotto-settori/dialog/dialog-crea-cfg-sotto-settori/dialog-crea-cfg-sotto-settori.component';
import { PolicySostenibilitaComponent } from './page/policy-sostenibilita/policy-sostenibilita.component';
import { CfgComitatiComponent } from './page/configurazioni/cfg-comitati/cfg-comitati.component';
import { CfgDestinatariPolicyComponent } from './page/configurazioni/cfg-destinatari-policy/cfg-destinatari-policy.component';
import { CfgPerimetroApplicazionePolicyComponent } from './page/configurazioni/cfg-perimetro-applicazione-policy/cfg-perimetro-applicazione-policy.component';
import { DialogCreaComitatiComponent } from './page/configurazioni/cfg-comitati/dialog/dialog-crea-comitati/dialog-crea-comitati.component';
import { DialogCreaDestinatariPolicyComponent } from './page/configurazioni/cfg-destinatari-policy/dialog/dialog-crea-destinatari-policy/dialog-crea-destinatari-policy.component';
import { DialogCreaPerimetroAppPolicyComponent } from './page/configurazioni/cfg-perimetro-applicazione-policy/dialog/dialog-crea-perimetro-app-policy/dialog-crea-perimetro-app-policy.component';
import { DialogCreaPolicySostenibilitaComponent } from './page/policy-sostenibilita/dialog/dialog-crea-policy-sostenibilita/dialog-crea-policy-sostenibilita.component';
import { SelezioneImpegniPolicyComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-impegni-policy/selezione-impegni-policy.component';
import { SelezioneAzioniBuonepratichePolicyComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-azioni-buonepratiche-policy/selezione-azioni-buonepratiche-policy.component';
import { SelezioneCertificazioniPolicyComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-certificazioni-policy/selezione-certificazioni-policy.component';
import { SelezionaComitatiComponent } from './page/policy-sostenibilita/dialog/seleziona-comitati/seleziona-comitati.component';
import { CompilaComitatiComponent } from './page/policy-sostenibilita/dialog/seleziona-comitati/compila-comitati/compila-comitati.component';
import { PolicySostenibilitaEsternaComponent } from './page/policy-sostenibilita-esterna/policy-sostenibilita-esterna.component';
import { SelezionaPerimetroDestinatariComponent } from './page/policy-sostenibilita/dialog/seleziona-perimetro-destinatari/seleziona-perimetro-destinatari.component';
import { CompilaPerimetriDestinatariComponent } from './page/policy-sostenibilita/dialog/seleziona-perimetro-destinatari/compila-perimetri-destinatari/compila-perimetri-destinatari.component';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { CfgLineeGuidaStampeComponent } from './page/configurazioni/cfg-linee-guida-stampe/cfg-linee-guida-stampe.component';
import { CfgStampaComponent } from './components/cfg-stampa/cfg-stampa.component';
import { PianoSostenibilitaComponent } from './page/piano-sostenibilita/piano-sostenibilita.component';
import { DialogCreaPianoSostenibilitaComponent } from './page/piano-sostenibilita/dialog/dialog-crea-piano-sostenibilita/dialog-crea-piano-sostenibilita.component';
import { CfgObiettiviPianoComponent } from './page/configurazioni/cfg-obiettivi-piano/cfg-obiettivi-piano.component';
import { DialogCreaObiettiviPianoComponent } from './page/configurazioni/cfg-obiettivi-piano/dialog/dialog-crea-obiettivi-piano/dialog-crea-obiettivi-piano.component';
import { SelezioneObiettiviPianoComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-obiettivi-piano/selezione-obiettivi-piano.component';
import { DialogAssociaTargetObiettiviComponent } from './page/piano-sostenibilita/dialog/dialog-associa-target-obiettivi/dialog-associa-target-obiettivi.component';
import { SelezioneObiettiviTargetPianoComponent } from './components/selezione-elementi-questionario/metodi-selezione/selezione-obiettivi-target-piano/selezione-obiettivi-target-piano.component';
import { PianoSostenibilitaEsternaComponent } from './page/piano-sostenibilita-esterna/piano-sostenibilita-esterna.component';
import { CfgObiettiviAziendaliComponent } from './page/configurazioni/cfg-obiettivi-aziendali/cfg-obiettivi-aziendali.component';
import { DialogCreaObiettivoAziendaliComponent } from './page/configurazioni/cfg-obiettivi-aziendali/dialog/dialog-crea-obiettivo-aziendali/dialog-crea-obiettivo-aziendali.component';
import { CfgModelliSviluppoPianoComponent } from './page/configurazioni/cfg-modelli-sviluppo-piano/cfg-modelli-sviluppo-piano.component';
import { DialogCreaCfgModelliSviluppoPianoComponent } from './page/configurazioni/cfg-modelli-sviluppo-piano/dialog-crea-cfg-modelli-sviluppo-piano/dialog-crea-cfg-modelli-sviluppo-piano.component';
import { InputNumberDirective } from './directives/input-number.directive';
import { GestioneLicenzeComponent } from './page/utenti/gestione-licenze/gestione-licenze.component';
import { RiepilogoLicenzeComponent } from './page/utenti/riepilogo-licenze/riepilogo-licenze.component';
import { DialogRiepilogoLicenzeComponent } from './page/utenti/dialog/dialog-riepilogo-licenze/dialog-riepilogo-licenze.component';
import { CfgStandardRendicontazioneComponent } from './page/configurazioni/cfg-standard-rendicontazione/cfg-standard-rendicontazione.component';
import { DialogCreaStandardRendicontazioneComponent } from './page/configurazioni/cfg-standard-rendicontazione/dialog/dialog-crea-standard-rendicontazione/dialog-crea-standard-rendicontazione.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { SelectCustomComponent } from './components/select-custom/select-custom.component';
/* import { SwiperModule } from 'swiper/angular';
 */

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HomeComponent,
    WidgetDashboardBarComponent,
    WidgetDashboardChartComponent,
    AutovalutazioniComponent,
    StatisticheComponent,
    AziendeComponent,
    DashboardAziendaComponent,
    LineChartComponent,
    FormContainerDirective,
    FormElementDirective,
    SearchBarComponent,
    DialogCreaUtentiComponent,
    SeparatoreLabelComponent,
    TitoloSubtitoloComponent,
    FormElementDirective,
    ListaTabellareComponent,
    ListaTabellareSempliceComponent,
    DialogFiltroTabellareComponent,
    DialogCreaAziendaComponent,
    FormErrorDirective,
    CfgAutovalutazioniComponent,
    UtentiComponent,
    CfgAmbitiComponent,
    DialogCreaAmbitoComponent,
    DialogCreaCfgAutovalutazioneComponent,
    DialogAlertComponent,
    CfgTematicheComponent,
    DialogCreaCfgTematicaComponent,
    DialogAnteprimaComponent,
    DialogVisUtenteComponent,
    ListaUtentiComponent,
    DialogVisAziendeComponent,
    ListaAziendeComponent,
    CfgCategorieStakeholderComponent,
    StakeholderComponent,
    MaterialitaComponent,
    DialogCreaStakeholderComponent,
    DialogCreaCategoriaStakeholderComponent,
    DialogCreaMaterialitaComponent,
    CfgScritturaImpattoComponent,
    CfgImpattiComponent,
    DialogCreaCfgImpattoComponent,
    SelezionaStakeholdersComponent,
    DialogFiltroGenericoComponent,
    DialogCreaTemplateMailComponent,
    CfgSettoriComponent,
    DialogCreaCfgSettoriComponent,
    DialogAnteprimaSurveyComponent,
    MyRatingStarComponent,
    AnteprimaSurveyComponent,
    DialogImportazioneExcelComponent,
    SurveyEsternoComponent,
    AnalisiRisultatiComponent,
    DialogImpostaEsclusioniComponent,
    DialogImpostaEsclusioniComponent,
    FigureAziendeComponent,
    DialogCreaFigureAziendeComponent,
    SpinnerOverlayComponent,
    DialogCompilaAutovalutazioneComponent,
    CfgImpegniComponent,
    DialogCreaImpegnoComponent,
    CfgAzioneEBuonaPraticaComponent,
    DialogCreaCfgAzioneEBuonaPraticaComponent,
    DialogCreaAutovalutazioneComponent,
    CompilaAutovalutazioneComponent,
    SintesiSostenibilitaComponent,
    BilancioSostebilitaComponent,
    AutovalutazioneEsternoComponent,
    DialogCreaSintesiSostenibilitaComponent,
    CustomTooltipDirective,
    CustomTooltipComponent,
    CfgUnitaDiMisuraComponent,
    DialogCreaUnitaDiMisuraComponent,
    CfgKPIComponent,
    DialogCreaKPIComponent,
    SelezioneElementiQuestionarioComponent,
    SelezioneImpegniSintesiComponent,
    SelezioneAzioniBuonepraticheSintesiComponent,
    SelezioneKPISintesiComponent,
    DialogAssociaFiguraAziendaleComponent,
    PasswordDimenticataComponent,
    CfgQuestionarioComponent,
    DialogCompilazioneFigureComponent,
    DialogAssegnazioniComponent,
    DialogCompilaSintesiSostenibilitaComponent,
    CompilaSintesiSostenibilitaComponent,
    DialogCreaBilancioSostenibilitaComponent,
    SintesiSostenibilitaEsternaComponent,
    SelezioneImpattiBilancioComponent,
    SelezioneImpegniBilancioComponent,
    SelezioneAzioniBuonepraticheBilancioComponent,
    SelezioneKpiBilancioComponent,
    CfgInformativaAziendaleComponent,
    DialogCreaInformativaAziendaleComponent,
    DialogAnteprimaCompilazioneInfoAzComponent,
    CompilaInformativaAziendaleComponent,
    InformativaAziendaleEsternaComponent,
    CfgCertificazioniComponent,
    DialogCreaCertificazioniComponent,
    DialogInviaMailComponent,
    DialogTarghetStdrComponent,
    BilancioSostenibilitaEsternaComponent,
    AutocompleteComponent,
    DialogGestioneLicenzeComponent,
    EditorComponent,
    SelezioneImpattiMaterialitaComponent,
    SelezioneCertificazioniBilancioComponent,
    SelezioneCertificazioniSintesiComponent,
    DialogEditDescrizioneIndiceComponent,
    DialogSottoSettoriComponent,
    DialogCreaCfgSottoSettoriComponent,
    PolicySostenibilitaComponent,
    CfgComitatiComponent,
    CfgDestinatariPolicyComponent,
    CfgPerimetroApplicazionePolicyComponent,
    DialogCreaComitatiComponent,
    DialogCreaDestinatariPolicyComponent,
    DialogCreaPerimetroAppPolicyComponent,
    DialogCreaPolicySostenibilitaComponent,
    SelezioneImpegniPolicyComponent,
    SelezioneAzioniBuonepratichePolicyComponent,
    SelezioneCertificazioniPolicyComponent,
    SelezionaComitatiComponent,
    CompilaComitatiComponent,
    PolicySostenibilitaEsternaComponent,
    SelezionaPerimetroDestinatariComponent,
    CompilaPerimetriDestinatariComponent,
    CfgLineeGuidaStampeComponent,
    CfgStampaComponent,
    PianoSostenibilitaComponent,
    DialogCreaPianoSostenibilitaComponent,
    CfgObiettiviPianoComponent,
    DialogCreaObiettiviPianoComponent,
    SelezioneObiettiviPianoComponent,
    DialogAssociaTargetObiettiviComponent,
    SelezioneObiettiviTargetPianoComponent,
    PianoSostenibilitaEsternaComponent,
    CfgObiettiviAziendaliComponent,
    DialogCreaObiettivoAziendaliComponent,
    CfgModelliSviluppoPianoComponent,
    DialogCreaCfgModelliSviluppoPianoComponent,
    InputNumberDirective,
    GestioneLicenzeComponent,
    RiepilogoLicenzeComponent,
    DialogRiepilogoLicenzeComponent,
    CfgStandardRendicontazioneComponent,
    DialogCreaStandardRendicontazioneComponent,
    RadioGroupComponent,
    SelectCustomComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgChartsModule,
    NgScrollbarModule,
    CKEditorModule,
    StarRatingModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'IT-it' },
    { provide: MAT_DATE_LOCALE, useValue: it },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        /* https://github.com/MurhafSousli/ngx-scrollbar/wiki/global-options */
      }
    },
    {
      provide: StarRatingConfigService, useClass: NgStarRatingService
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
