import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { finalize, map, of, pipe } from 'rxjs';
import { Figli, Padre } from 'src/app/components/select-custom/select-custom.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { AzioneBP, AzioneEBuonaPraticaService } from 'src/app/services/azione-e-buona-pratica/azione-e-buona-pratica.service';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { PianoService } from 'src/app/services/piano/piano.service';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { Settore, SettoriService } from 'src/app/services/settori/settori.service';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';
import { StandardRendicontazioneService, STDR } from 'src/app/services/standard-rendicontazione/standard-rendicontazione.service';
import { TematicheService } from 'src/app/services/tematiche/tematiche.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-crea-cfg-azione-e-buona-pratica',
  templateUrl: './dialog-crea-cfg-azione-e-buona-pratica.component.html',
  styleUrls: ['./dialog-crea-cfg-azione-e-buona-pratica.component.scss']
})
export class DialogCreaCfgAzioneEBuonaPraticaComponent implements AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public selectedSettori: string[] = [];
  public arrayStdr: {
    chiave: string;
    descrizione: string;
  }[] = [];;
  public arraySettori: Settore[] = [];

  private objStdrOriginale: STDR[] = [];

  /* Form Controls */
  public formAzioneBP = new FormGroup({


    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    titolo: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),

    settori: new FormControl<Settore[]>([], {
      nonNullable: true,
    }),

    note: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    tag: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    creatoDaContesto: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    selected: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    compilato: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    figura: new FormControl<any>(undefined, {
      nonNullable: true,
      validators: [],
    }),

  });

  public stdr = new FormControl<Padre[]>([]);
  public compliance = new FormControl<Padre[]>([]);

  constructor(
    public dialogRef: MatDialogRef<DialogCreaCfgAzioneEBuonaPraticaComponent>,
    public azioneBPService: AzioneEBuonaPraticaService,
    private readonly utilityService: UtilityService,
    private readonly settoriService: SettoriService,
    private readonly tematicaService: TematicheService,
    private readonly sintesiService: SintesiService,
    private readonly bilancioService: BilancioService,
    private readonly policyService: PolicyService,
    private readonly pianoService: PianoService,
    private readonly stdrService: StandardRendicontazioneService,
    private readonly cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: {
      azioneBP: AzioneBP,
      idContest: string,
      idAmbito: string,
      idTematica: string,
      contesto: string,
      idObiettivo: string,
    }
  ) {

  }

  ngAfterViewInit(): void {
    this._getSettori();
    if (this.data?.azioneBP) {
      Object.keys(this.data.azioneBP).forEach((key) => {
        this.formAzioneBP.get(key)?.setValue((this.data.azioneBP as any)[key]);
      });
      const idSettori = (this.formAzioneBP.get('settori')?.value)?.map(set => set.id) || []
      this.getStdrBySettore(idSettori)
    }
    this.cdRef.detectChanges(); // Forza il rilevamento delle modifiche
    /* this.getStdr(); */

  }

  onChangeSettore(event: MatSelectChange) {

    const idSettoriSelezionati: string[] = event.value.map((settore: Settore) => (settore.id))

    this.getStdrBySettore(idSettoriSelezionati)

  }

  /**
   *  Get stdr by settore selezionato 
   * @param settoriIds 
   */
  getStdrBySettore(settoriIds: string[]) {

    const idAzione = this.data?.azioneBP?.id ? this.data?.azioneBP?.id : null;
    const isCreazione = !idAzione

    this.spinnerOver.show();
    this.azioneBPService.getStdrBySettore(idAzione, settoriIds, isCreazione)
      .pipe(
        finalize(() => this.spinnerOver.hide())
      ).subscribe(
        {
          next: (esito: STDR[]) => {
            /*     console.log(esito); */
            this.objStdrOriginale = esito;

            this.compliance.setValue(this.stdrService.setOggettoSelectionCompliance(esito))

            this.stdr.setValue(this.stdrService.setOggettoSelectionStdr(esito));

          },
          error: (err) => {
            console.error(err);
          }
        }
      )
  }

  /**
   * Funzione di salvataggio di un nuovo AzioneBP
   * @returns 
   */
  compareSettori(settore1: Settore, settore2: Settore): boolean {
    return settore1.id === settore2.id; // Sostituisci "id" con la proprietà univoca degli AzioneBP
  }

  public salva() {

    if (this.formAzioneBP.valid) {

      const id = this.formAzioneBP.get('id')?.value;
      let stdrMerged;
      let complianceMerged;

      this.stdrService.mergeSelectedStdr(this.stdr.getRawValue() as any, this.objStdrOriginale).subscribe(mergedResult => {
        stdrMerged = mergedResult.filter(padre => !padre.compliance)
      });

      this.stdrService.mergeSelectedStdr(this.compliance.getRawValue() as any, this.objStdrOriginale).subscribe(mergedResult => {
        complianceMerged = mergedResult.filter(padre => padre.compliance)
      });


      const objSalvataggio = {
        ...this.formAzioneBP.getRawValue(),
        compliance: complianceMerged,
        stdr: stdrMerged
      }

      // Se sono in contesto: sintesi, bilancio, policy etc...
      if (this.data.idContest) {
        return this.salvaInContesto(objSalvataggio);
      }


      if (id) {
        this.spinnerOver.show();
        return this.azioneBPService.putAzioneBP(id, objSalvataggio)
          .pipe(finalize(() => {
            this.spinnerOver.hide();
          }))
          .subscribe(
            {
              next: (result: any) => {
                this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                  duration: 2000,
                  panelClass: ['success-snackbar']
                });
                this.dialogRef.close();
              },
              error: (err: any) => {
                console.error('Errore durante il salvataggio dell\'AzioneBP:', err);

                this.utilityService.openDialog({
                  titolo: 'Attenzione',
                  descrizione: 'Errore nel salvataggio dell\'AzioneBP',
                  bottoni: [{ nome_btn: 'Chiudi' }]
                })
              }

            }
          );
      } else {
        this.spinnerOver.show();
        return this.azioneBPService.postAzioneBP(objSalvataggio)
          .pipe(finalize(() => this.spinnerOver.hide()))
          .subscribe(
            {
              next: (result: any) => {
                this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                  duration: 2000,
                  panelClass: ['success-snackbar']
                });
                this.dialogRef.close();
              },
              error: (err: any) => {
                console.error('Errore durante il salvataggio degli AzioneBP:', err);
                this.utilityService.openDialog({
                  titolo: 'Attenzione',
                  descrizione: 'Errore nel salvataggio degli AzioneBP',
                  bottoni: [{ nome_btn: 'Chiudi' }]
                })
              }

            }
          );
      }
    } else {
      Object.values(this.formAzioneBP.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }
  private _getSettori() {
    this.spinnerOver.show();
    this.settoriService.getSettori(0, 1000, '', [], [{
      chiave: 'titolo',
      sort: 'desc'
    }])
      .pipe(finalize(() => this.spinnerOver.hide()))
      .subscribe({
        next: (result: any) => {
          this.arraySettori = result?.content || [];
        },
        error: (err: any) => {
          console.error('Errore durante il caricamento dei settori:', err);

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero dei settori',
            bottoni: [{ nome_btn: 'Ok' }]
          })
        }
      })
  }

  /**
     * Metodo che mi salva una nuova azione e buona pratica effimera in bilancio/sintesi
     * @returns 
     */
  public salvaInContesto(objSalvataggio: any) {

    if (!this.data.idAmbito) {
      this.mostraMessaggio('Attenzione', 'Ambito non trovato o non riconosciuto');
      return;
    } else if (!this.data.idTematica) {
      this.mostraMessaggio('Attenzione', 'Tematica non trovata o non riconosciuta');
      return;
    }

    if (!this.formAzioneBP.get('id')?.value) {
      this.formAzioneBP.get('creatoDaContesto')?.setValue(true);
    }

    let serviceCall;

    if (this.data.contesto == 'sintesi') {
      serviceCall = this.sintesiService.putAzioneBPEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        objSalvataggio);
    } else if (this.data.contesto == 'bilancio') {
      serviceCall = this.bilancioService.putAzioniBPEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        objSalvataggio);
    } else if (this.data.contesto == 'policy') {
      serviceCall = this.policyService.putAzioniBPEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        objSalvataggio);
    } else if (this.data.contesto == 'piano') {
      serviceCall = this.pianoService.putTargetQualitativiEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.data.idObiettivo,
        objSalvataggio);
    }

    if (serviceCall) {
      this.spinnerOver.show();
      serviceCall.pipe(
        finalize(() => this.spinnerOver.hide())
      ).subscribe({
        next: (result: any) => {
          this.mostraMessaggioSnackbar('Salvataggio effettuato');
          this.dialogRef.close(result);
        },
        error: (err: any) => {
          console.error('Errore durante il salvataggio dell\'azione buona pratoca:', err);
          this.mostraMessaggio('Attenzione', 'Errore nel salvataggio dell\'azione buona pratoca');
        }
      });
    }
  }

  private mostraMessaggio(titolo: string, descrizione: string) {
    this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizione,
      bottoni: [{ nome_btn: 'Chiudi' }]
    });
  }

  private mostraMessaggioSnackbar(messaggio: string) {
    this.utilityService.opneSnackBar(messaggio, '', {
      duration: 2000,
      panelClass: ['success-snackbar']
    });
  }
}