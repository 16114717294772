import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { SelezioneObiettiviTargetPianoComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-obiettivi-target-piano/selezione-obiettivi-target-piano.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { DialogAssegnazioniComponent } from 'src/app/page/sintesi-sostenibilita/dialog/dialog-assegnazioni/dialog-assegnazioni.component';
import { PianoService } from 'src/app/services/piano/piano.service';
import { UtenteService } from 'src/app/services/utente/utente.service';

@Component({
  selector: 'app-dialog-associa-target-obiettivi',
  templateUrl: './dialog-associa-target-obiettivi.component.html',
  styleUrls: ['./dialog-associa-target-obiettivi.component.scss']
})
export class DialogAssociaTargetObiettiviComponent implements AfterViewInit {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild('tabGroupTarget') tabGroupTarget!: MatTabGroup;
  @ViewChild('targetQualitativi') targetQualitativi!: SelezioneObiettiviTargetPianoComponent;
  @ViewChild('targetQuantitativi') targetQuantitativi!: SelezioneObiettiviTargetPianoComponent;

  public objQualitativiQuantitativi: any;
  constructor(
    public dialogRef: MatDialogRef<DialogAssociaTargetObiettiviComponent>,
    private readonly pianoService: PianoService,
    public dialog: MatDialog,
    public utenteService: UtenteService,
    @Inject(MAT_DIALOG_DATA) public data: {
      listaId: any,
      stato: string,
      titoloObiettivo: string,
      desObiettivo: string,
      isPermessoSoloRead: boolean
    }) {
  }

  ngAfterViewInit(): void {
    this.carica();
  }

  /**
   * Attenzione modificare perchè viene utilizzato anche in app-selezione-obiettivi-target-piano
   */
  public carica = () => {
    this.spinnerOver.show();
    this.pianoService.getTargetQualitativiQuantitativi(this.data.listaId).subscribe({
      next: (esito) => {
        console.log(esito);
        this.objQualitativiQuantitativi = esito;
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err)
        this.spinnerOver.hide();
      }
    })
  }


  salva(): Observable<any> {
    if (this.tabGroupTarget.selectedIndex === 0) {
      return this.targetQualitativi.salva(true, this.data.listaId.idAmbito, this.data.listaId.idTematica);
    } else {
      return this.targetQuantitativi.salva(true, this.data.listaId.idAmbito, this.data.listaId.idTematica);
    }
  }


  public async salvaInHTML() {
    await firstValueFrom(this.salva()),
      this.carica();
      this.dialogRef.close()
  }

  /**
   * Metodo che mi crea l'oggetto per mandare in salvataggio gli elementi selezionati
   * @returns 
   */
  getOggettoElementiSelezionatiTarget = () => {
    let objElemSel = {
      targetQualitativi: [{
        idAmbito: '',
        idTematica: '',
        idElementi: [] as string[]
      }],
      targetQuantitativi: [{
        idAmbito: '',
        idTematica: '',
        idElementi: [] as string[]
      }]
    };

    const mappaTarget = (source: any[], target: any) => {
      source.forEach((ambito: any) => {
        target[0].idAmbito = ambito.id;
        ambito.tematiche.forEach((tematica: any) => {
          target[0].idTematica = tematica.id;
          tematica.elementi.forEach((elemento: any) => {
            if (elemento.selected) {
              target[0].idElementi.push(elemento.id);
            }
          });
        });
      });
    };

    mappaTarget(this.objQualitativiQuantitativi.targetQualitativi, objElemSel.targetQualitativi);
    mappaTarget(this.objQualitativiQuantitativi.targetQuantitativi, objElemSel.targetQuantitativi);

    return objElemSel;
  }

  assegnazioni() {
    if (this.data?.isPermessoSoloRead) {
      return this.apriDialogAssegnazioni();
    }

    this.salva()
      .pipe(
        switchMap(() => {
          return this.apriDialogAssegnazioni();
        })
      )
      .subscribe(() => {
        if (this.data?.stato === 'PUBBLICATO') {
          return;
        }
        this.carica();

      });
    return of()
  }

  apriDialogAssegnazioni() {
    const dialogAssegnazioniFigAzz = this.dialog.open(DialogAssegnazioniComponent, {
      data: {
        idContesto: this.data.listaId.idPianoSostenibilita,
        stato: this.data?.stato,
        contesto: 'piano',
        isPermessoSoloRead: this.data?.isPermessoSoloRead
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '100%',
      maxHeight: '95%',
      autoFocus: false,
    });

    return dialogAssegnazioniFigAzz.afterClosed();
  };
}

