import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaCfgSettoriComponent } from './dialog/dialog-crea-cfg-settori/dialog-crea-cfg-settori.component';
import { Settore, SettoriService } from 'src/app/services/settori/settori.service';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { DialogSottoSettoriComponent } from './dialog/dialog-sotto-settori/dialog-sotto-settori.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { Ateco, JsonFileService } from 'src/app/services/json-file/json-file.service';
import { map } from 'rxjs';


@Component({
  selector: 'app-cfg-settori',
  templateUrl: './cfg-settori.component.html',
  styleUrls: ['./cfg-settori.component.scss']
})
export class CfgSettoriComponent implements OnInit {

  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public filtri: Filtri[] = [
    {
      titolo: 'Settore',
      forControlName: 'titolo',
      input: 'text',
      riempi: true
    },
    /* {
      titolo: 'Codice Ateco',
      forControlName: 'codiciAteco',
      input: 'text',
      riempi: true
    }, */
    {
      titolo: 'Codice Ateco',
      forControlName: 'codiceAteco',
      input: 'multiple-option-chip',
      riempi: true,
      larghezza: 3,
      fnDatiOption: (ricerca: string | undefined) => this.jsonFileService.getAtecoFiltrato(ricerca).pipe(map((ateco: any) => ateco.map((atec: any) => atec._id + ' - ' + atec.desc) || [])),
      numeroRecOptions: 10

    },
    {
      forControlName: 'sottoSettori',
      input: 'multiple-option',
      titolo: 'Sotto Settore',
      fnDatiOption: () => (this.settoriService.getSettori(0, 1000)).pipe(map((value) => value?.content || [])),
      descValueOption: 'titolo',
      idValueOption: 'id',
      riempi: true,
      larghezza: 1
    },
  ];

  public colonne: { [key: string]: Colonna } = {
    titolo: {
      title: 'Settori',
      value: 'titolo',
      sortable: true
    },
    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('EDIT_CONFIGURAZIONI'))
    },
    sottoSettori: {
      type: 'button',
      buttonIcon: 'account_tree',
      title: 'Sotto-settori',
      buttonId: 'sottoSettori',
      buttonMostraSempre: true
    },
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('DELETE_CONFIGURAZIONI'))
    },
  };

  constructor(
    private settoriService: SettoriService,
    public dialog: MatDialog,
    private utilityService: UtilityService,
    public utenteService: UtenteService,
    private jsonFileService: JsonFileService,
  ) { }

  ngOnInit(): void {
    /**
   * Metodo che mi riempie un array dei codici ateco.
   * E' stato inserito in questo componente poichè nella creazione dell'azienda
   * risultava abbastanza lento. 
   * 
   */
    this.settoriService.setCodiciAteco();
  }

  creaNuovo(record?: Settore) {

    const dialogCreaSettore = this.dialog.open(DialogCreaCfgSettoriComponent, {
      data: {
        settore: record,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaSettore.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public mostraSottoSettori(settore: Settore) {

    const dialogCreaSettore = this.dialog.open(DialogSottoSettoriComponent, {
      data: {
        settore,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaSettore.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });

  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    if (!sortBy) {
      sortBy = [{
        chiave: 'titolo',
        sort: 'asc'
      }];
    }

    if (!filters?.find((filt) => filt.chiave === 'codiciAteco')) {
      filters?.push({
        chiave: 'codiciAteco',
        operator: 'ne',
        values: ['SOSGO']
      });
    }

    return this.settoriService.getSettori(page, pageSize, ricerca, filters, sortBy);
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaNuovo(event.data);
        break;
      case 'sottoSettori':
        this.mostraSottoSettori(event.data);
        break;
      case 'elimina':
        this._elimina(event.data.id);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  private _elimina(id: string) {
    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare il settore?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI',
          background_color: 'var(--mat-color-primary)',
          handler: () => {
            this.spinnerOver.show();
            this.settoriService.deleteSettore(id).subscribe(
              {
                next: (result: any) => {
                  this.tabella.caricaDati();
                  this.spinnerOver.hide();
                },
                error: (err: any) => {
                  this.spinnerOver.hide();
                  if (err.status === 406) {

                    this.utilityService.openDialog({
                      titolo: 'Attenzione',
                      descrizione: 'Eliminazione non permessa: ' + err.error.message,
                      bottoni: [
                        {
                          nome_btn: 'Chiudi', handler: () => {
                            this.tabella.caricaDati();
                          }
                        }]
                    });
                  } else {

                    this.utilityService.openDialog({
                      titolo: 'Attenzione',
                      descrizione: 'Errore eliminazione: ' + err.error.message,
                      bottoni: [
                        {
                          nome_btn: 'Chiudi', handler: () => {
                            this.tabella.caricaDati();
                          }
                        }]
                    });
                  }
                }
              }
            );
          }
        }]
    });
  }
}
