import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';


export interface Localita {
  id: string;
  nome: string;
}

export interface Ateco {
  _id: string;
  desc: string;
}

@Injectable({
  providedIn: 'root'
})
export class JsonFileService {

  private jsonRegioni = 'assets/json/regioni.json';
  private jsonComuni = 'assets/json/comuni.json';
  private jsonProvince = 'assets/json/province.json';
  private jsonAteco = 'assets/json/ateco.json'

  constructor(private http: HttpClient) { }

  /**
   * Lettura del file json delle regioni italiane
   * @returns regioni
   */
  public getRegioni(): Observable<Localita[]> {
    return this.http.get<Localita[]>(this.jsonRegioni).pipe(
      map((regioni: any[]) => {
        return regioni.map(item => ({
          id: item.id,
          nome: item.nome
        }));
      })
    );
  }

  public getRegioniByNome(regione: string): Observable<Localita[]> {
    return this.http.get<Localita[]>(this.jsonRegioni).pipe(
      map(
        (regioni: any[]) => {
          return regioni
            .filter(item => item.nome === regione)
            .map(filteredItem => ({
              id: filteredItem.id,
              nome: filteredItem.nome
            }));
        })
    );
  }

  /**
   * Lettura del file json delle Province italiane
   * @returns province
   */
  public getProvince(id: string): Observable<any> {
    return this.http.get<Localita[]>(this.jsonProvince).pipe(
      map(
        (province: any[]) => {
          return province
            .filter(item => item.id_regione === id) // Filtra gli elementi in base all'id
            .map(filteredItem => ({
              id: filteredItem.id,
              nome: filteredItem.nome
            }));
        }
      )
    );
  }

  public getProvinceByNome(provincia: string): Observable<any> {
    return this.http.get<Localita[]>(this.jsonProvince).pipe(
      map(
        (province: any[]) => {
          return province
            .filter(item => item.nome === provincia) // Filtra gli elementi in base all'id
            .map(filteredItem => ({
              id: filteredItem.id,
              nome: filteredItem.nome
            }));
        }
      )
    );
  }
  /**
   * Lettura del file json dei Comuni italiani
   * @returns comuni
  */
  public getComuni(id: string): Observable<Localita[]> {
    return this.http.get<Localita[]>(this.jsonComuni).pipe(
      map(
        (comuni: any[]) => {
          return comuni
            .filter(item => item.id_provincia === id) // Filtra gli elementi in base all'id
            .map(
              filteredItem => ({
                id: filteredItem.id,
                nome: filteredItem.nome
              })
            )
        }
      )
    )
  };

  public getComuniByNome(comune: string): Observable<Localita[]> {
    return this.http.get<Localita[]>(this.jsonComuni).pipe(
      map(
        (comuni: any[]) => {
          return comuni
            .filter(item => item.nome === comune) // Filtra gli elementi in base all'id
            .map(
              filteredItem => ({
                id: filteredItem.id,
                nome: filteredItem.nome
              })
            )
        }
      )
    )
  };

  public getAteco(): Observable<Ateco[]> {
    return this.http.get<Ateco[]>(this.jsonAteco).pipe(
      map(
        (ateco: any[]) => {
          return ateco
            .map(item => ({
              _id: item._id,
              desc: item.desc
            }));
        }
      )
    )
  }

  public getAtecoFiltrato(ricerca: string | undefined): Observable<Ateco[]> {
    return this.http.get<Ateco[]>(this.jsonAteco).pipe(
      map((ateco: any[]) => {
        if (!ricerca) {
          return ateco.map(item => ({
            _id: item._id,
            desc: item.desc
          }));
        }
        return ateco
          .filter(item =>
            item._id.includes(ricerca) || item.desc.toLowerCase().includes(ricerca.toLowerCase())
          )
          .map(item => ({
            _id: item._id,
            desc: item.desc
          }));
      })
    );
  }

  public getAtecoById(idAteco: string[]): Observable<Ateco[]> {
    return this.http.get<Ateco[]>(this.jsonAteco).pipe(
      map(
        (ateco: any[]) => {
          return ateco
            .filter(item => idAteco.indexOf(item._id) > -1) // Filtra gli elementi in base all'id
            .map(
              filteredItem => ({
                _id: filteredItem._id,
                desc: filteredItem.desc

              })
            )
        }
      )
    )
  };

}

