import { PerimetriDestinatari, PolicyService } from './../../../../../services/policy/policy.service';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-crea-destinatari-policy',
  templateUrl: './dialog-crea-destinatari-policy.component.html',
  styleUrls: ['./dialog-crea-destinatari-policy.component.scss']
})
export class DialogCreaDestinatariPolicyComponent {

  public formDestinatarioPolicy = new FormGroup({
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    titolo: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    descrizione: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreaDestinatariPolicyComponent>,
    private readonly policyService: PolicyService,

    @Inject(MAT_DIALOG_DATA) public data: {
      idPolicySostenibilita: string,
    }
  ) { }

  public salva() {

    if (this.formDestinatarioPolicy.valid) {

      const objDestinatario: PerimetriDestinatari = {
        chiaveElemento: 'destinatario',
        descrizione: this.formDestinatarioPolicy.get('titolo')?.value,
        lineaGuidaDestinatario: this.formDestinatarioPolicy.get('descrizione')?.value,
      }

      this.policyService.putDestinatariEffimeri(this.data.idPolicySostenibilita, objDestinatario)
        .pipe(finalize(() => this.dialogRef.close()))
        .subscribe()


    } else {
      Object.values(this.formDestinatarioPolicy.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }
}
