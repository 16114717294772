import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { SelezionaStakeholdersComponent } from 'src/app/components/materialita/seleziona-stakeholders/seleziona-stakeholders.component';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { MaterialitaService } from 'src/app/services/materialita/materialita.service';
import { Settore, SettoriService, SottoSettore } from 'src/app/services/settori/settori.service';
import { StakeholderService, CategoriaStakeholders } from 'src/app/services/stakeholder/stakeholder.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaTemplateMailComponent } from '../dialog-crea-template-mail/dialog-crea-template-mail.component';
import { Subscription, catchError, map, of, switchMap, firstValueFrom, forkJoin, Observable, throwError, finalize } from 'rxjs';
import { DialogAnteprimaSurveyComponent } from '../dialog-anteprima-survey/dialog-anteprima-survey.component';
import { MatButton } from '@angular/material/button';
import { DialogCreaStakeholderComponent } from '../../../stakeholder/dialog/dialog-crea-stakeholder/dialog-crea-stakeholder.component';
import { AnalisiRisultatiComponent } from 'src/app/components/analisi-risultati/analisi-risultati.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import * as dateFns from 'date-fns';
import { SelezioneImpattiMaterialitaComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-impatti-materialita/selezione-impatti-materialita.component';
import { DialogCreaCategoriaStakeholderComponent } from 'src/app/page/configurazioni/cfg-categorie-stakeholder/dialog/dialog-crea-categoria-stakeholder/dialog-crea-categoria-stakeholder.component';
import { UtenteService } from 'src/app/services/utente/utente.service';

@Component({
  selector: 'app-dialog-crea-materialita',
  templateUrl: './dialog-crea-materialita.component.html',
  styleUrls: ['./dialog-crea-materialita.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: { showError: true },
  }],
})
export class DialogCreaMaterialitaComponent implements OnDestroy, AfterViewInit {
  @ViewChild('stepper') stepper!: MatStepper;

  @ViewChild('stEsterni') stEsterni!: SelezionaStakeholdersComponent;

  @ViewChild('stInterni') stInterni!: SelezionaStakeholdersComponent;

  @ViewChild('btnAvanti') btnAvanti!: MatButton;

  /* @ViewChild(CfgQuestionarioComponent) componenteSurvey!: CfgQuestionarioComponent; */

  @ViewChild(AnalisiRisultatiComponent) analisiRisultatiComponent!: AnalisiRisultatiComponent;

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  @ViewChild(SelezioneImpattiMaterialitaComponent) componenteSelImpattiMaterialita!: SelezioneImpattiMaterialitaComponent;

  public isPermessoInvioMail: boolean = this.utenteService.isPermessoAttivo('INVIO_MAIL');
  public isPermessoSoloRead: boolean = false;

  private numeroDipendetiAzienda: number = 0;
  private numeroFatturatoAzienda: number = 0;

  public mappaAnnoSettore: { [anno: string]: { [settore: string]: boolean } } = {};
  public searchValue: string = '';
  public arrayAnni: string[] = [];
  public objSurveyImpattiChk: any[] = [];

  public arraySottoSettori: SottoSettore[] = [];
  public settoreGenerale: Settore | undefined = undefined;

  public settoriAzienda: Settore[] = [];

  private _survey: any = {};

  get survey() {
    return this._survey;
  }

  //Manuali
  public manualiCompilati: number = 0;
  public manualiNonCompilati: number = 0;
  //Mail
  public MailDaInviare: number = 0;
  public MailInviate: number = 0;
  public Mailcompilate: number = 0;
  //Totali
  public totaliManuali: number = 0;
  public totaliMail: number = 0;


  /* Form Controls */

  public formMaterialita = new FormGroup({

    /* Id */
    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    /* idAzienda */
    idAzienda: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    /* idSurvey */
    idSurvey: new FormControl<string>(''),
    /* anno */
    anno: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    /* nrDipendenti */
    nrDipendenti: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(999999999999)],
    }),
    /* Fatturato */
    fatturato: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(999999999999)],
    }),

    titolo: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),

    tipoSettore: new FormControl<'GENERALE' | 'SPECIFICO' | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    settori: new FormControl<Settore | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    sottoSettori: new FormControl<SottoSettore[]>([], {
      nonNullable: true
    }),

    mailInvioSurvey: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    mailNotificaSurvey: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    landingPageSurvey: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    esclusioni: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    sogliaInterni: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    sogliaEsterni: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    shInterni: new FormControl<any[] | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    shEsterni: new FormControl<any[] | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    tematicheRaggruppate: new FormControl<{ [key: string]: boolean } | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    dataUltimaConferma: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
    abilitaStep: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [Validators.required],
    }),

  });

  /* Form control che serve solo a validare la presenza dell'id materialita per lo stepper */
  public formMaterialitaCheckId = new FormControl<string | undefined>(undefined, {
    nonNullable: true,
    validators: [Validators.required],
  });

  /* Form control che serve solo a validare lo step analisi risultati */
  public formAbilitaStepAnalisiRisultati = new FormControl<boolean>(false, [Validators.requiredTrue]);
  /*************************************************************** IMPATTI ***********************************************/


  public minDate = new Date();

  public formImpatti = new FormGroup({
    tipoPunteggio: new FormControl<string | undefined>(undefined, {
      validators: [Validators.required],
    }),
    scadenzaSurvey: new FormControl<Date | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });


  /*************************************************************** CREAZIONE SURVEY **************************************/

  public arrayTematicheSel: { [key: string]: any } = {};
  public arrayTematicheDisponibili: { [key: string]: any } = {};
  public loadingTemDisp: { [key: string]: any } = {};

  // Questa form serve solo per bloccare lo stepper quando i check non sono correttamente impostati.
  // Viene aggiornato nei vari punti in cui vengono variate le checkbox.
  public formCheckImpatti = new FormControl<boolean>(false, [Validators.requiredTrue]);

  /******************************************************************** END CRUSCOTTO SURVEY *************************************/


  /******************************************************************** INTERNI */
  private _subInterni: Subscription | undefined;

  public formStepInterni = new FormGroup({
    peso: new FormControl<number | undefined>(undefined, [Validators.min(100), Validators.max(100)]),
    nrSel: new FormControl<number | undefined>(undefined, [Validators.min(1)])
  })

  /******************************************************************** END INTERNI */

  /******************************************************************** ESTERNI */
  private _subEsterni: Subscription | undefined;

  public formStepEsterni = new FormGroup({
    peso: new FormControl<number | undefined>(undefined, [Validators.min(100), Validators.max(100)]),
    nrSel: new FormControl<number | undefined>(undefined, [Validators.min(1)])
  })

  /******************************************************************** END ESTERNI */

  @ViewChild('tabella') tabella!: ListaTabellareComponent;

  public filtri: Filtri[] = [
    {
      titolo: 'Nome',
      forControlName: 'nome',
      input: 'text',
    },
    {
      titolo: 'Cognome',
      forControlName: 'cognome',
      input: 'text',
    },
    {
      titolo: 'Email',
      forControlName: 'email',
      input: 'text',
    },
    {
      titolo: 'Tipologia',
      forControlName: 'tipologia',
      input: 'option',
      idValueOption: 'id',
      descValueOption: 'descrizione',
      fnDatiOption: () => this.stakeholderService.getTipologia()
    },
    {
      titolo: 'Categoria',
      forControlName: 'categoria',
      fnDatiOption: () => this.stakeholderService.getCategorieStake(0, 1000).pipe(map((value) => value?.content || [])),
      idValueOption: 'id',
      descValueOption: 'titolo',
      input: 'multiple-option'
    },
    {
      titolo: 'Ultimo Invio',
      forControlName: 'ultimoInvio',
      input: 'date',
    },
    {
      titolo: 'Status',
      forControlName: 'stato',
      input: 'multiple-option',
      idValueOption: 'id',
      descValueOption: 'descrizione',
      fnDatiOption: () => this.stakeholderService.getStatus()
    },
    {
      titolo: 'Mod. compilazione',
      forControlName: 'modalitaCompilazione',
      input: 'multiple-option',
      idValueOption: 'id',
      descValueOption: 'descrizione',
      fnDatiOption: () => this.stakeholderService.getModalitaCompilazione()
    },
  ];

  public colonne: { [key: string]: Colonna } = {
    stakeholder: {
      title: 'Stakeholders',
      sortable: true,
      campiSort: ['nome', 'cognome'],
      value: (record: any) => (record?.nome + ' ' + record?.cognome)
    },
    tipologia: {
      title: 'Tipologia',
      value: 'tipologia',
      sortable: true
    },
    categoria: {
      title: 'Categoria',
      sortable: true,
      value: (record: any) => (record?.categoria?.titolo)
    },
    email: {
      title: 'Email',
      value: 'email',
      sortable: true
    },
    modalitaCompilazione: {
      title: 'Modalità compilazione',
      sortable: true,
      value: (record: any) => {
        if (record.modalitaCompilazione === 'INVIAMAIL') {
          return 'Email';
        } else if (record.modalitaCompilazione === 'MANUALE') {
          return 'Manuale';
        } else {
          return record.modalitaCompilazione;
        }
      }
    },
    ultimoInvio: {
      title: 'Ultimo Invio',
      sortable: true,
      value: (record) => record.ultimoInvio ? dateFns.format(new Date(record.ultimoInvio), 'dd/MM/yyyy HH:mm') : ''
    },
    /* stato: {
      title: 'Stato',
      value: (record: any) => {
        if (record?.stato === 'ROSSO') { // non compilato
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#cf5b61;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else if (record?.stato === 'GIALLO') { // ha inviato la mail ma non è stato compilato (valore solo per gli stakeholder con modalita compilazione "INVIAMAIL")
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#c7a045;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else if (record?.stato === 'VERDE') { // compilato
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#729373;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else {
          return '';
        }
      }
    }, */
    stato: {
      title: 'Stato',
      sortable: true,
      value: (record: any) => {
        if (record?.stato?.codice) {
          return '<div style="display: flex;">' + record?.stato.descrizione + '</div>'
        } else {
          return '';
        }

      }
    },

    compila: {
      type: 'button',
      sortable: false,
      buttonIcon: (record: any) => {
        if (record) {
          if (record.idSurveyCompilato) {
            return 'visibility';
          } else if (record?.modalitaCompilazione === 'MANUALE') {
            return 'history_edu';
          } else if (record.ultimoInvio) {
            return 'notifications_active';
          } else {
            return 'mail';
          }
        } else {
          return '';
        }
      },
      title: (record: any) => {
        if (record) {
          if (record.idSurveyCompilato) {
            return 'Visualizza';
          } else if (record?.modalitaCompilazione === 'MANUALE') {
            return 'Compila';
          } else if (record.ultimoInvio) {
            return 'Invia notifica';
          } else {
            return 'Email';
          }
        } else {
          return '';
        }
      },
      buttonId: 'azione',
      buttonMostraSempre: true,
      nascondiButton: (record: any) => this.ctrBottoneCompila(record),
    },

    visualizza: {
      type: 'button',
      buttonIcon: 'visibility',
      title: 'Visualizzaaa',
      buttonId: 'azione',
      sortable: false,
      buttonMostraSempre: true,
      nascondiButton: (record: any) => this.ctrBottoneVisualizza(record),
    },

    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      sortable: false,
      buttonMostraSempre: true,
      nascondiButton: (record: any) => !!this.formMaterialita.get('dataUltimaConferma')?.value || record.stato?.codice !== 'NONCOMP',
    },
  };

  private _subValueChangeSettore?: Subscription;
  private _subValueChangeTipoSettore: Subscription | undefined = undefined;
  private _subTipoPunteggio?: Subscription;
  private _subDataScadenzSurvey?: Subscription;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogCreaMaterialitaComponent>,
    private readonly materialitaService: MaterialitaService,
    private readonly utilityService: UtilityService,
    private readonly aziendaService: AziendaService,
    private readonly settoriService: SettoriService,
    private readonly stakeholderService: StakeholderService,
    private utenteService: UtenteService,
    @Inject(MAT_DIALOG_DATA) public data: {
      materialita: any
    }

  ) {
    if (this.data.materialita?.stato === 'PUBBLICATO') {
      this.formImpatti.get('scadenzaSurvey')?.disable();
      this.formImpatti.get('tipoPunteggio')?.disable();
      this._refreshSurvey();
    }
  }

  async ngAfterViewInit() {

    this.isPermessoSoloRead = this.isPermessoSoloReadFunx();

    if (this.isPermessoSoloRead) {
      this.formMaterialita.disable();
      this.formImpatti.disable();
    }

    await this._caricaSettori();

    if (!this.isPermessoSoloRead) {
      this._subInterni = this.stInterni.totPeso.subscribe((peso) => {
        this.formStepInterni.get('peso')?.setValue(peso);
      });

      this._subEsterni = this.stEsterni.totPeso.subscribe((peso) => {
        this.formStepEsterni.get('peso')?.setValue(peso);
      });
    }


    this._subValueChangeSettore = this.formMaterialita.get('settori')?.valueChanges.subscribe((value) => {
      if (value) {
        this.arraySottoSettori = this.settoriAzienda.find(sett => sett.id === value.id)?.sottoSettori || [];

        this._updateSottoSettoriSelezionati();
      }
    });

    this._subValueChangeTipoSettore = this.formMaterialita.get('tipoSettore')?.valueChanges.subscribe((value) => {
      if (value === 'GENERALE') {
        this.formMaterialita.get('settori')?.setValue(this.settoreGenerale);
      } else if (value === 'SPECIFICO') {
        this.formMaterialita.get('settori')?.setValue(this.settoriAzienda?.length ? this.settoriAzienda[0] : undefined);
      }
    });

    this._subTipoPunteggio = this.formImpatti.get('tipoPunteggio')?.valueChanges.subscribe((val) => {

      const id = this.formMaterialita.get('id')?.value;

      if (id && val) {

        this.spinnerOver.show();

        this.formMaterialitaCheckId.setValue(id);
        this.formCheckImpatti.setValue(true);

        this.materialitaService.salvaTipoPunteggio(id, val).subscribe({
          next: (result) => {

            this.spinnerOver.hide();
          },
          error: (err) => {
            console.error(err);
            this.spinnerOver.hide();

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Salvataggio tipo punteggio non riuscito',
              bottoni: [{ nome_btn: 'Ok' }]
            });

          }
        });
      }

    });

    this._subDataScadenzSurvey = this.formImpatti.get('scadenzaSurvey')?.valueChanges.subscribe((val) => {

      const id = this.formMaterialita.get('id')?.value;

      if (id && val) {

        this.spinnerOver.show();

        this.materialitaService.salvaScadenzaSurvey(id, val).subscribe({
          next: (result) => {

            this.spinnerOver.hide();
          },
          error: (err) => {
            console.error(err);
            this.spinnerOver.hide();

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Salvataggio data scadenza non riuscito',
              bottoni: [{ nome_btn: 'Ok' }]
            });

          }
        });
      }
    });

    const annoCorrente = new Date().getFullYear();

    // Aggiungi gli anni indietro di 5 anni e in avanti di 1 anno all'array
    for (let i = annoCorrente - 5; i <= annoCorrente + 1; i++) {
      this.arrayAnni.push(i.toString());
    }

    if (this.data?.materialita) {

      this._setDatiMaterialita(this.data.materialita);

    } else {
      this.aziendaService.azienda.then((azienda) => {
        if (azienda) {

          if (this.settoriAzienda?.length) {
            this.formMaterialita.get('tipoSettore')?.setValue('SPECIFICO');
          } else {
            this.formMaterialita.get('tipoSettore')?.setValue('GENERALE');
          }

          this.formMaterialita.get('idAzienda')?.setValue(azienda.id);

          this.aziendaService.getAziendaByIdAzienda(azienda.id).subscribe((risp) => {

            this.numeroDipendetiAzienda = risp.dipendenti;
            this.numeroFatturatoAzienda = risp.fatturato;

            if (!this.data?.materialita?.id) {
              this.formMaterialita.get('nrDipendenti')?.setValue(this.numeroDipendetiAzienda);
              this.formMaterialita.get('fatturato')?.setValue(this.numeroFatturatoAzienda);
            }
          });
        }
      });
    }

    this.getStatoSurvey();

    this.gestioneStepperMaterialita();

  }

  ngOnDestroy(): void {
    this._subInterni?.unsubscribe();
    this._subEsterni?.unsubscribe();
    this._subValueChangeSettore?.unsubscribe();
    this._subValueChangeTipoSettore?.unsubscribe();
    this._subTipoPunteggio?.unsubscribe();
    this._subDataScadenzSurvey?.unsubscribe();
  }

  onKeyDown(event: KeyboardEvent): void {
    // Verifica se il tasto premuto è il carattere "-"
    if (event.key === '-') {
      // Impedisce l'inserimento del carattere "-"
      event.preventDefault();
    }
  }

  onKeyDownDip(event: KeyboardEvent): void {
    if (event.key === ',' || event.key === '.') {
      // Impedisce l'inserimento del carattere "-"
      event.preventDefault();
    }
  }

  isPermessoSoloReadFunx() {
    const isEdit = this.utenteService.isPermessoAttivo('EDIT_MATERIALITA');
    const isCreate = this.utenteService.isPermessoAttivo('CREATE_MATERIALITA')
    return (!isEdit && !isCreate)
  }


  /**
 * Questionario pubblicato
 * @returns true === PUBBLICATO
 */
  public isPubblicato(): boolean {
    return (this._survey?.stato === 'PUBBLICATO')
  }


  indietro() {

    switch (this.stepper.selectedIndex) {
      case 0:
        return true;
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        this.stepper.previous();
        return true;
      default:
        return null;
    }

  }



  public async avanti() {

    let result: boolean = false;
    this.spinnerOver.show();


    switch (this.stepper.selectedIndex) {
      case 0:
        result = await this.salvaStepMaterialita();
        break;
      case 1:
        result = await this.salvaStepImpatti();
        break;
      case 2:
        result = await this._salvaShInterni();
        break;
      case 3:
        result = await this._salvaShEsterni();
        break;
      case 4:
        result = await this._confermaSurvey();
        break;

      default:
        break;
    }

    this.spinnerOver.hide();

    return result;
  }

  public async avantiHTML() {
    if (this.isPermessoSoloRead || await this.avanti()) {
      return this.stepper.next();
    }
  }

  async ctrDipendentiFatturato(): Promise<boolean> {
    const id = this.formMaterialita.get('id')?.value;
    const nrDipendenti = this.formMaterialita.get('nrDipendenti')?.value;
    const fatturato = this.formMaterialita.get('fatturato')?.value;
    if (!id && (nrDipendenti !== this.numeroDipendetiAzienda || fatturato !== this.numeroFatturatoAzienda)) {
      /* IMPLEMENTARE LA DIALOG DI VARIAIZONE FATTUARTO E DIPENDENTI */
      const dialog = await this.utilityService.openDialog({
        titolo: 'Variazione Dipendenti e Fatturato',
        descrizione: 'I valori inseriti per il <strong>fatturato</strong> e il <strong>numero di dipendenti</strong> possono essere <strong>aggiornati</strong> anche nell\'<strong>Azienda</strong>.<br>Vuoi procedere con  l\'<strong>aggiornamento</strong>?',
        fontWeight: 'normal',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return false;
      }
      return true;
    }
    return false;
  }

  public async salvaStepMaterialita(): Promise<boolean> {

    if (this.formMaterialita.valid) {


      const aggiornaDatiAzienda = await this.ctrDipendentiFatturato();

      const id = this.formMaterialita.get('id')?.value;

      const valueMaterialita = {
        idAzienda: this.formMaterialita.get('idAzienda')?.value,
        fatturato: this.formMaterialita.get('fatturato')?.value,
        anno: this.formMaterialita.get('anno')?.value,
        nrDipendenti: this.formMaterialita.get('nrDipendenti')?.value,
        titolo: this.formMaterialita.get('titolo')?.value,
        tipoSettore: this.formMaterialita.get('tipoSettore')?.value,
        settori: this.formMaterialita.get('settori')?.value,
        sottoSettori: this.formMaterialita.get('sottoSettori')?.value,
        aggiornaDatiAzienda: aggiornaDatiAzienda
      }

      // Aggiunge all'oggetto principale anche gli sh interni ed esterni, mergiando i dati con quelli nella materialità.
      // Questo perchè gli stakeholders in materialità contengono delle info in più rispetto a quelli estratti dalla libreria.
      const shInterni = this.formMaterialita.get('shInterni')?.value || [];
      const shEsterni = this.formMaterialita.get('shEsterni')?.value || [];

      const valueMaterialitaCompleto = Object.assign({}, valueMaterialita, {
        shInterni: this._mergeStakeholdersMaterialita(this.stInterni.getValoriSelezionati(), shInterni),
        shEsterni: this._mergeStakeholdersMaterialita(this.stEsterni.getValoriSelezionati(), shEsterni)
      });

      if (id) {
        return true
      } else {

        return firstValueFrom(this.materialitaService.postMaterialita(valueMaterialitaCompleto).pipe(
          switchMap((result) => {


            this.formMaterialita.get('id')?.setValue(result.id);
            this.formMaterialitaCheckId.setValue(result.id);

            return this.materialitaService.getMaterialitaById(result.id);
          }),
          map(result => {
            this._setDatiMaterialita(result);

            this.utilityService.opneSnackBar('Salvataggio effettuato', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });
            return true;
          }),
          catchError(err => {
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || 'Errore nel salvataggio',
              bottoni: [{ nome_btn: 'Chiudi' }]
            })
            return of(false);
          })
        ))
      }
    } else {
      Object.values(this.formMaterialita.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return false;

  }

  /**
    * Metodo salvataggio IMPATTI
    * @returns
    */
  public salvaStepImpatti(): Promise<boolean> {

    if (this.survey?.stato === 'PUBBLICATO') {
      return Promise.resolve(true);
    }

    return firstValueFrom(
      this.componenteSelImpattiMaterialita.salva().pipe(
        map(esito => {
          return esito;
        })
      )
    )
  }

  stampa() {

    const idMaterialita = this.formMaterialita.get('id')?.value;

    if (idMaterialita) {

      this.spinnerOver.show();

      this._eseguiConferma().pipe(
        switchMap(() => {
          return this.materialitaService.stampa(idMaterialita);
        })
      ).subscribe({
        next: (result) => {

          window.open(result?.body, '_blank');

          this.spinnerOver.hide();
        },
        error: (err) => {
          console.error(err);

          this.spinnerOver.hide();

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Stampa non riuscita. Verificare le configurazioni e riprovare.',
            bottoni: [{ nome_btn: 'Ok' }]
          });

        }
      });

    }

  }

  conferma() {

    this._eseguiConferma().subscribe({
      next: (result) => {
        console.log(result);

      },
      error: (err) => {
        console.log(err);

      }
    });
  }

  public cambioStep1Materialità() {
    const idMaterialita = this.formMaterialita.get('id')?.value;

    if (idMaterialita) {
      // Aggiorno la materialità
      this.btnAvanti.disabled = true;

      this.spinnerOver.show();
      this.materialitaService.getMaterialitaById(idMaterialita)
        .pipe(switchMap((materialita) => {

          this._setDatiMaterialita(materialita);

          // Si recupera la survey generata alla creazione della materialità
          return this._refreshSurvey();
        }))
        .subscribe({
          next: (esito) => {
            if (esito) {
              this.btnAvanti.disabled = false;

            }
            this.spinnerOver.hide();
          },
          error: (err) => {
            console.error('ERRORE RECUPERO MATERIALITA : ', err);

            this.spinnerOver.hide();

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Non è stato possibile recuperare i dati di materialità.',
              bottoni: [{ nome_btn: 'Ok' }]
            });

          }
        });
    }

  }


  cambioStep(event: StepperSelectionEvent) {

    //Mi trovo nella Creazione Survey
    if (event.selectedIndex === 0) {

      this.cambioStep1Materialità();

    } else if (event.selectedIndex === 1) {

      this.spinnerOver.show();

      if (this.survey?.stato === 'PUBBLICATO') {
        this.formImpatti.disable({ emitEvent: false });
      }

      this.btnAvanti.disabled = true;

      this._getImpattiMaterialita();

    } else if (event.selectedIndex === 2) {

      this.stInterni.refreshStakeholders();
      this.spinnerOver.hide();
    } else if (event.selectedIndex === 3) {

      this.stEsterni.refreshStakeholders();

    } else if (event.selectedIndex === 4) {

      if (!this.formImpatti.valid) {
        this.formImpatti.markAllAsTouched();
      }

      const id = this.formMaterialita.get('id')?.value;
      if (id) {
        this.spinnerOver.show();
        this.caricaTotaliCruscotto(id);

        this.tabella.caricaDati();
        this.spinnerOver.hide();
      }

    } else if (event.selectedIndex === 5) {
      this.analisiRisultatiComponent.inizializzaComponente();
    }
  }


  /**
   * Setta nel formGroup i dati della materialita
   * @param materialita
   */
  private _setDatiMaterialita(materialita: any) {

    let settoreSel: Settore | null = null;

    Object.keys(materialita).forEach((value, index, array) => {

      if (value === 'settori') {
        // Settore si imposta per ultimo, per non far scattare il change quando non è ancora settato l'array dei sottosettori
        settoreSel = materialita.settori[0];
      } else {
        this.formMaterialita.get(value)?.setValue((materialita as any)[value]);
      }

    });

    if (settoreSel) {

      if (!this.settoriAzienda.find((set) => set.id === settoreSel!.id)) {
        // Il settore della materialità non è negli array dei settori azienda.
        // Potrebbe essere stato tolto a posteriori, dopo la creazione della materialità.
        // Per non lasciare il campo vuoto, si setta nell'array il settore che viene dalla materialità.
        this.settoriAzienda = [settoreSel];
      }

      this.formMaterialita.get('settori')?.setValue(settoreSel);
    }

    if (this.formMaterialita.get('id')?.value) {
      this.formMaterialitaCheckId.setValue(this.formMaterialita.get('id')?.value);
      this.formMaterialita.get('anno')?.disable();
      this.formMaterialita.get('settori')?.disable();
      this.formMaterialita.get('titolo')?.disable();
      this.formMaterialita.get('fatturato')?.disable();
      this.formMaterialita.get('nrDipendenti')?.disable();
      this.formMaterialita.get('sottoSettori')?.disable();
    }

  }


  /*********************************************** CREAZIONE SURVEY */

  drop(event: CdkDragDrop<{ descrizione: string; chiave: string; id: string }[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }


  compareById(element1: any, element2: any): boolean {
    return element1?.id === element2?.id; // Sostituisci "id" con la proprietà univoca degli ambiti
  }


  /*********************************************** IMPATTI ****************************/

  /**
* Get ws Impatti Materialità
* @returns
*/
  private _getImpattiMaterialita(): Promise<boolean> {
    const id = this.formMaterialita.get('id')?.value;

    if (!id) {
      return Promise.resolve(false);
    }
    this.spinnerOver.show();
    return firstValueFrom(
      this.materialitaService.getImpattiCheck(id).pipe(
        map(impatti => {
          this.objSurveyImpattiChk = impatti
          this.componenteSelImpattiMaterialita.objQuestionario = impatti;
          this.updateCheckStatus();
          this.getStatoSurvey();
          this.btnAvanti.disabled = false;
          this.spinnerOver.hide();
          return true;
        }),
        catchError(err => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero degli impatti',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          this.spinnerOver.hide();
          return of(false)

        }),
        finalize(() => this.spinnerOver.hide())
      ))
  }



  /*********************************************** CRUSCOTTO SURVEY *******************/

  template() {
    const id = this.formMaterialita.get('id')?.value; //id materialità

    if (id) {

      const dialogCreaTemplateMail = this.dialog.open(DialogCreaTemplateMailComponent, {
        data: {
          idMaterialita: id,
          idSurvey: this.survey?.id,
          isPermessoSoloRead: this.isPermessoSoloRead
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '100%',
        height: '95%',
        autoFocus: false,
      });

      dialogCreaTemplateMail.afterClosed().subscribe((result) => {

      });

    }
  }

  /**
   * Metodo che mi notifica (aggiorna lo stato della mail giù creta con invia mial) lo stakeholder
   */
  async notificaTutti() {

    const idMaterialita = this.formMaterialita.get('id')?.value; //id materialità
    const scadenzaSurvey = this.formImpatti.get('scadenzaSurvey')?.value;
    const tipoPunteggio = this.formImpatti.get('tipoPunteggio')?.value;

    if (idMaterialita && scadenzaSurvey && tipoPunteggio) {
      const testiMail = await firstValueFrom(this.materialitaService.getTemplateMail(idMaterialita))

      if (!testiMail?.mailNotifica) {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Prima di fare l\'invio compilare i Testi Mail',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return;
      }
      const dialog = await this.utilityService.openDialog({
        titolo: 'Confermare invio?',
        descrizione: 'Sarà inviata una notifica a tutti',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }
      this.spinnerOver.show();
      this.materialitaService.putInviaNotificaMultiplo(idMaterialita).subscribe({
        next: (esito) => {

          this.tabella.caricaDati();
          this.caricaTotaliCruscotto(idMaterialita);
          this.formImpatti.disable({ emitEvent: false });
          this.spinnerOver.hide();
        },
        error: (err) => {
          console.error(err);
          this.spinnerOver.hide();
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Non è stato possibile inviare la notifica.',
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      })
    } else {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Dati insufficienti per inviare la notifica.',
        bottoni: [{ nome_btn: 'Ok' }]
      });
    }
  }

  /**
   * Metodo che invia una mail a tutti gli stakeholder esterni che la devono ricevere per compilare una survey
   */
  async inviaTutti() {

    const idMaterialita = this.formMaterialita.get('id')?.value; //id materialità
    const scadenzaSurvey = this.formImpatti.get('scadenzaSurvey')?.value;
    const tipoPunteggio = this.formImpatti.get('tipoPunteggio')?.value;

    if (idMaterialita && scadenzaSurvey && tipoPunteggio) {

      const testiMail = await firstValueFrom(this.materialitaService.getTemplateMail(idMaterialita))

      if (!testiMail?.mailInvio) {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Prima di fare l\'invio compilare i Testi Mail',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return;
      }

      if (this._survey.stato !== 'PUBBLICATO') {

        const risultatoObservable = await this._alertPubblicazione(
          `<div>
            <p>
              <strong>Si prega di notare che questa operazione invierà la Survey di Materialità agli stakeholder selezionati.</strong>
            </p>
            <ul>
              <li>L'invio è definitivo e NON potrà essere annullato.</li>
              <li>Non sarà più possibile <strong>aggiungere</strong> o <strong>modificare</strong> gli impatti e la tipologia punteggio selezionata</li>
              <li>Sarà possibile aggiungere nuovi stakeholder ma <strong>NON</strong> rimuoverli.</li>
            </ul>
            <p><strong>Vuoi procedere con l'invio mail?</strong></p>
          </div>`);
        if (risultatoObservable) {
          const risultato = await firstValueFrom(risultatoObservable);
          if (!risultato) {
            return;
          }
        }
      }
      this.spinnerOver.show();
      this.materialitaService.postInvioMailMultiplo(idMaterialita).subscribe({
        next: (esito) => {

          this.tabella.caricaDati();
          this.caricaTotaliCruscotto(idMaterialita);
          this.formImpatti.disable({ emitEvent: false });

          this.utilityService.opneSnackBar('Mail inviate', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });

          this.spinnerOver.hide();
        },
        error: (err) => {
          console.error(err);
          this.spinnerOver.hide();
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Non è stato possibile inviare la mail.',
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      });
    } else {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Dati insufficienti per inviare la mail.',
        bottoni: [{ nome_btn: 'Ok' }]
      });
    }
  }

  applicaFiltroRicerca(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = filterValue;
    /* this.getStakeholders().subscribe((stake) => {
      this.arrayGroupCateStake = stake;
    }); */
  }


  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    const idMaterialita = this.formMaterialita.get('id')?.value

    if (idMaterialita) {
      return this.materialitaService.getCruscotti(idMaterialita, page, pageSize, ricerca, filters, sortBy);
    } else {
      return of(false);
    }

  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.modificaSh(event.data);
        break;
      case 'azione':
        if (event.data) {
          if (event.data.idSurveyCompilato || event.data?.modalitaCompilazione === 'MANUALE') {
            this._compila(event.data);
          } else if (event.data.ultimoInvio) {
            this._inviaNotifica(event.data);
          } else if (event.data?.modalitaCompilazione === 'INVIAMAIL') {
            this._inviaMail(event.data);
          }
        }
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  private async _inviaMail(dati: any) {
    const idMaterialita = this.formMaterialita.get('id')?.value; //id materialità
    const idStakeholder = dati.id;
    const scadenzaSurvey = this.formImpatti.get('scadenzaSurvey')?.value;
    const tipoPunteggio = this.formImpatti.get('tipoPunteggio')?.value;

    if (idMaterialita && scadenzaSurvey && tipoPunteggio) {

      const testiMail = await firstValueFrom(this.materialitaService.getTemplateMail(idMaterialita))

      if (!testiMail?.mailInvio) {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Prima di fare l\'invio compilare i Testi Mail',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return;
      }

      const dialog = await this.utilityService.openDialog({
        titolo: 'Confermare invio?',
        descrizione: 'Sarà inviata una mail',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }


      this.materialitaService.postInvioMailSingolo(idMaterialita, idStakeholder).subscribe({
        next: (esito) => {

          this.tabella.caricaDati();
          this.caricaTotaliCruscotto(idMaterialita);
          this.formImpatti.disable({ emitEvent: false });
        },
        error: (err) => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Non è stato possibile inviare la mail.',
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      })

    } else {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Dati insufficienti per inviare la mail.',
        bottoni: [{ nome_btn: 'Ok' }]
      })
    }
  }

  private async _inviaNotifica(dati: any) {
    const idMaterialita = this.formMaterialita.get('id')?.value; //id materialità
    const scadenzaSurvey = this.formImpatti.get('scadenzaSurvey')?.value;
    const tipoPunteggio = this.formImpatti.get('tipoPunteggio')?.value;




    const idStakeholder = dati.id;

    if (idMaterialita && scadenzaSurvey && tipoPunteggio) {


      const testiMail = await firstValueFrom(this.materialitaService.getTemplateMail(idMaterialita))

      if (!testiMail?.mailNotifica) {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Prima di fare l\'invio compilare i Testi Mail',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return;
      }


      const dialog = await this.utilityService.openDialog({
        titolo: 'Confermare invio?',
        descrizione: 'Sarà inviata una notifica a chi ha già ricevuto la mail.',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }

      this.spinnerOver.show();
      this.materialitaService.putInviaNotificaSingolo(idMaterialita, idStakeholder).pipe(
        finalize(() => this.spinnerOver.hide())
      ).subscribe({
        next: (esito) => {

          this.tabella.caricaDati();
          this.caricaTotaliCruscotto(idMaterialita);
          this.utilityService.opneSnackBar('Notifiche inviate', '', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });
        },
        error: (err) => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Non è stato possibile inviare la notifica.',
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      })

    } else {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Dati insufficienti per inviare la notifica.',
        bottoni: [{ nome_btn: 'Ok' }]
      })
    }
  }

  private async _alertPubblicazione(descrizione?: string): Promise<Observable<boolean> | undefined> {
    const idMaterialita = this.formMaterialita.get('id')?.value;

    let titolo: string = 'ATTENZIONE!';

    const dialog = await this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizione,
      fontWeight: '500',
      bottoni: [
        { nome_btn: 'No', id_btn: 'N' },
        { nome_btn: 'Si', id_btn: 'S' }
      ]
    });

    const valDialog = await firstValueFrom(dialog.beforeClosed());
    if (valDialog === 'N') {
      return of(false);
    }

    // PUBBLICO LA MATERIALITà E ASPETTO LA RISPOSTA
    this.spinnerOver.show();
    this._survey = await firstValueFrom(this.materialitaService.pubblicaMaterialita(idMaterialita!));

    if (this._survey) {
      this.completaStep();
    }

    this.spinnerOver.hide();
    return of(true);
  }

  async _compila(dati: any) {
    const idMaterialita = this.formMaterialita.get('id')?.value; //id materialità
    const scadenzaSurvey = this.formImpatti.get('scadenzaSurvey')?.value;
    const tipoPunteggio = this.formImpatti.get('tipoPunteggio')?.value;

    const idStakeholder = dati.id;
    const idSurvey = this.survey?.id || null;
    const idSurveyCompilato = dati.idSurveyCompilato;

    if (idMaterialita && idStakeholder && idSurvey && scadenzaSurvey && tipoPunteggio) {

      if (this._survey.stato !== 'PUBBLICATO') {

        const currentDate = new Date();
        const scadenzaSurveyDate = new Date(scadenzaSurvey);

        // Imposta le ore, i minuti, i secondi e i millisecondi a zero
        const surveyDate = new Date(scadenzaSurveyDate);
        const today = new Date(currentDate);

        surveyDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        if (surveyDate < today) {
          this.utilityService.opneSnackBar('La data di scadenza non può essere minore di quella corrente', '', {
            duration: 2000,
            panelClass: ['red-snackbar']
          });
          return;
        }


        const risultatoObservable = await this._alertPubblicazione(
          `<div>
            <p>
              <strong>Si prega di notare che questa operazione avvierà la compilazione della Survey di Materialità.</strong>
            </p>
            <ul>
              <li>L'operazione è definitiva e NON potrà essere annullata.</li>
              <li>Non sarà più possibile <strong>aggiungere</strong> o <strong>modificare</strong> gli impatti e la tipologia punteggio selezionata</li>
              <li>Sarà possibile aggiungere nuovi stakeholder ma <strong>NON</strong> rimuoverli.</li>
            </ul>
            <p><strong>Vuoi procedere con la compilazione?</strong></p>
          </div>`
        );
        if (risultatoObservable) {
          const risultato = await firstValueFrom(risultatoObservable);
          if (!risultato) {
            return;
          }
        }
      }

      const dialogAnteprimaSurvey = this.dialog.open(DialogAnteprimaSurveyComponent, {
        data: {
          idMaterialita: idMaterialita,
          idStakeholder: idStakeholder,
          idSurvey: idSurvey,
          idSurveyCompilato: idSurveyCompilato
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '100%',
        height: '95%',
        autoFocus: false,
      });

      dialogAnteprimaSurvey.afterClosed().subscribe((result) => {

        this.completaStep();
        this.tabella.caricaDati();
        this.caricaTotaliCruscotto(idMaterialita);
        this.formImpatti.disable({ emitEvent: false });
      });

    } else {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Dati insufficienti per compilare la Survey.',
        bottoni: [{ nome_btn: 'Ok' }]
      })
    }
  }

  /**
   * Modifica stakeHolder in lista curscotto survey
   */
  public modificaSh(st: any) {
    const dialogCreaAmbito = this.dialog.open(DialogCreaStakeholderComponent, {
      data: {
        readOnly: !!(this.formMaterialita.get('dataUltimaConferma')?.value),
        stakeholder: st,
        isStakeHolderInMat: true,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });
    dialogCreaAmbito.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  /**
   * Metodo che riprende lo stato della survey.
   */
  getStatoSurvey() {
    this._refreshSurvey().subscribe({
      next: (result) => {

      },
      error: (err) => {
        console.error('Errore', err);
      }
    });

  }


  caricaTotaliCruscotto(idMaterialita: string) {
    this.materialitaService.getTotaliCruscotto(idMaterialita).subscribe({
      next: (totaliCruscotto) => {

        this.manualiCompilati = totaliCruscotto.manuali.compilati
        this.manualiNonCompilati = totaliCruscotto.manuali.nCompilati
        this.MailDaInviare = totaliCruscotto.mail.nInviate
        this.MailInviate = totaliCruscotto.mail.inviate
        this.Mailcompilate = totaliCruscotto.mail.compilati
        this.totaliManuali = totaliCruscotto.totali.totaleManuali
        this.totaliMail = totaliCruscotto.totali.totaleInviati
      },
      error: (err) => {
        console.error(err);
      }
    })
  }

  /**
   * Riceve in ingresso un'array di categorie stakeholder, con all'interno un array di stakeholders selezionati,
   * e l'array delle categorie con all'interno gli stakeholders già persenti in materialità.
   * Unisce ai dati selezionati quelli presenti in materialità, e ritorna l'array rimappato con le in formazioni
   * nel formato della amterialità.
   *
   * @param catStakeSelezionati
   * @returns
   */
  private _mergeStakeholdersMaterialita(catStakeSelezionati: CategoriaStakeholders[], catStakeMaterialita: any[]) {
    return catStakeSelezionati.map(categoria => {

      const catDaMaterialita = catStakeMaterialita.find((catMat: any) => catMat.id === categoria.id);

      if (catDaMaterialita) {
        categoria.stakeholders = categoria.stakeholders.map(stake => {
          const stakeDaMaterialita = catDaMaterialita.stakeholders.find(((matStak: any) => matStak.id === stake.id));
          if (stakeDaMaterialita) {
            return stakeDaMaterialita;
          } else {
            return stake;
          }
        });
      }

      return categoria;

    })
  }

  /**
   * Metodo che mi controlla se selezionato almeno un impatto per tematica
   * @param ambiti
   * @returns [true] no impatti checked per tematica  [false] almeno 1 impatto per tematica checked.
   */
  updateCheckStatus() {

    for (let amb of this.objSurveyImpattiChk) {

      for (let tem of amb.tematiche) {
        let impattoCheckato = false;

        for (let imp of tem.elementi) {

          if (imp.selected) {
            impattoCheckato = true;
            break;
          }
        }

        if (!impattoCheckato) {
          // non ci sono impatti selezionati per questa tematica
          this.formCheckImpatti.setValue(false);
          return true;
        }

      }
    }

    // Tutte le tematiche hanno almeno un impatto selezionato
    this.formCheckImpatti.setValue(true);
    return false;
  }


  private async _caricaSettori() {

    this.spinnerOver.show();

    try {

      const risp = await firstValueFrom(
        forkJoin({
          generale: this.settoriService.getSettoreGenerale().pipe(
            catchError((err) => {
              console.error('ERRORE GET SETTORE GENERALE : ', err);
              // Errore recupero settore generale, potrebbe non esistere. Si torna undefined
              return of(undefined);
            })
          ),
          specifici: this.aziendaService.getSettoriAzienda(),
          materialitaCreate: this.materialitaService.getMaterialita(0, 1000, '', []).pipe(
            catchError((err) => {
              console.error('ERRORE GET MATERIALITA CREATE : ', err);
              return of(undefined);
            })
          ),
        })
      );

      this.settoriAzienda = risp?.specifici || [];
      this.settoreGenerale = risp?.generale;

      for (const materialita of risp?.materialitaCreate?.content) {
        const anno = materialita.anno;
        const settore = materialita.settori[0]?.id;

        if (!this.mappaAnnoSettore[anno]) {
          this.mappaAnnoSettore[anno] = {};
        }

        this.mappaAnnoSettore[anno][settore] = true;
      };

      if (!this.settoriAzienda?.length && !this.settoreGenerale) {
        console.error('NESSUN SETTORE DISPONIBILE, IMPOSSIBILE PROSEGUIRE');
      }

      this.arraySottoSettori = this.settoriAzienda.find(sett => sett.id === this.formMaterialita.get('settori')?.value?.id)?.sottoSettori || [];

      this.spinnerOver.hide();

    } catch (error) {

      console.error(error);

      this.spinnerOver.hide();

      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Errore nel recupero dei settori',
        bottoni: [{ nome_btn: 'Ok' }]
      });

      this.dialogRef.close();

    }

  }

  private _refreshSurvey() {

    const idMaterialita = this.formMaterialita.get('id')?.value;

    if (!idMaterialita) {
      return of(false);
    }

    return this.materialitaService.getSurvey(idMaterialita)
      .pipe(
        map((survey) => {

          if (survey) {


            this._survey = survey;

            if (this._survey) {

              this.formMaterialita.get('titolo')?.setValue(this._survey.titolo);
              this.formMaterialita.get('settori')?.setValue(this._survey?.settori ? this._survey?.settori[0] : null);

              const sottoSettori = this.settoriAzienda.find(sett => sett.id === this.formMaterialita.get('settori')?.value?.id)?.sottoSettori || [];

              this.arraySottoSettori = sottoSettori;

              this._updateSottoSettoriSelezionati()

              if (this._survey.tipoPunteggio) {
                this.formImpatti.get('tipoPunteggio')?.setValue(this._survey.tipoPunteggio, { emitEvent: false });
              }

              if (this._survey.scadenzaSurvey) {
                this.formImpatti.get('scadenzaSurvey')?.setValue(this._survey.scadenzaSurvey, { emitEvent: false });
              }
            }
            this.completaStep()

          } else {
            this._survey = undefined;
          }

          return true;
        }),
        catchError((err) => {
          this.btnAvanti.disabled = false;

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero della survey',
            bottoni: [{ nome_btn: 'Ok' }]
          });

          return of(false);
        }),
      );
  }


  async controlloTestiMail(idMaterialita: string) {

    return this.materialitaService.getTemplateMail(idMaterialita).subscribe(
      {
        next: (template) => {


        },
        error: (err: any) => {
          console.error(err);

        }
      }
    );
  }

  private _ctrShInCategoria(arraySH: any): string[] {
    let categorieNonSelezionate: string[] = [];
    for (const categoria of arraySH) {

      if (Array.isArray(categoria.stakeholders)) {
        if (categoria.idAzienda) {
          if (categoria.stakeholders.length > 0) {
            if (categoria.stakeholders.some((sh: any) => sh.selected)) {
              continue;
            }
          }
          categorieNonSelezionate.push(categoria.titolo)
        }

      }
    }
    return categorieNonSelezionate;
  }


  /*   private _ctrShInCategoria(arraySH: any): boolean {
      for (const categoria of arraySH) {
        if (Array.isArray(categoria.stakeholders)) {
          if (categoria.stakeholders.length <= 0) {
            continue; // Salta questa categoria se non ci sono stakeholders
          }

          if (categoria.stakeholders.some((sh: any) => sh.selected)) {
            continue; // Passa alla prossima categoria se almeno uno degli stakeholders è selezionato
          }
        }
        return false; // Se non ci sono stakeholders selezionati, restituisci false
      }
      return true; // Se tutte le categorie hanno almeno uno stakeholder selezionato, restituisci true
    } */

  private async ctrSH(tipoSH: string): Promise<boolean> {
    let stakeholdersFormValue: any;
    let selezionati: any;
    let stController: any;

    if (tipoSH === 'shInterni') {
      stakeholdersFormValue = this.formMaterialita.get('shInterni')?.value || [];
      stController = this.stInterni;
    } else if (tipoSH === 'shEsterni') {
      stakeholdersFormValue = this.formMaterialita.get('shEsterni')?.value || [];
      stController = this.stEsterni;
    } else {
      return false;
    }

    selezionati = this._mergeStakeholdersMaterialita(stController.getValoriSelezionati(), stakeholdersFormValue);

    if (!selezionati.length || !selezionati[0].stakeholders.length) {
      this.utilityService.opneSnackBar('Non hai selezionato nessuno Stakeholder', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
      return false;
    }

    if (stController.ctrPeso()) {
      return false;
    }

    // Controllo se è stato selezionato almeno un sh per categoria
    const categorieNonSelezionate: string[] = this._ctrShInCategoria(stController.arrayGroupCateStake);

    if ((categorieNonSelezionate.length && !this.formMaterialita.get('dataUltimaConferma')?.value) && (this.stepper.selectedIndex === 2 || this.stepper.selectedIndex === 3)) {
      const categorieListString = categorieNonSelezionate.join('<li>');

      const dialog = await this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: `Sono presenti categorie aziendali senza uno stakeholder selezionato:<br><br><li>${categorieListString}<br><br>Vuoi proseguire?`,
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return false;
      }
    }

    return true;
  }

  private async _salvaShInterni(): Promise<boolean> {
    const shInterni = this.formMaterialita.get('shInterni')?.value || [];

    const selezionati = this._mergeStakeholdersMaterialita(this.stInterni.getValoriSelezionati(), shInterni);

    const result = await this.ctrSH('shInterni');
    if (!result) {
      return false;
    }

    this.formStepInterni.get('nrSel')?.setValue(selezionati.length);

    const id = this.formMaterialita.get('id')?.value;

    if (id) {

      return firstValueFrom(this.materialitaService.putMateStake(id, 'INTERNI', selezionati).pipe(
        map(() => {

          if (this.stepper.steps?.get(this.stepper.selectedIndex)) {
            (this.stepper.steps.get(this.stepper.selectedIndex) as MatStep).completed = true;
          }
          return true;
        }),
        catchError(err => {
          console.error('Errore durante il salvataggio', err);

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel salvataggio',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          return of(false)
        })
      ))
    }

    return true;

  }

  private async _salvaShEsterni(): Promise<boolean> {

    const shEsterni = this.formMaterialita.get('shEsterni')?.value || [];

    const selezionati = this._mergeStakeholdersMaterialita(this.stEsterni.getValoriSelezionati(), shEsterni);

    const result = await this.ctrSH('shEsterni');
    if (!result) {
      return false;
    }

    const id = this.formMaterialita.get('id')?.value;

    if (id) {

      return firstValueFrom(this.materialitaService.putMateStake(id, 'ESTERNI', selezionati).pipe(
        map(() => {
          if (this.stepper.steps?.get(this.stepper.selectedIndex)) {
            (this.stepper.steps.get(this.stepper.selectedIndex) as MatStep).completed = true;
          }
          return true;
        }),
        catchError(err => {
          console.error('Errore durante il salvataggio', err);

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel salvataggio',
            bottoni: [{ nome_btn: 'Ok' }]
          });
          return of(false)
        })
      ))
    }

    return true;
  }


  private async _confermaSurvey(): Promise<boolean> {
    if (this.data?.materialita?.dataUltimaConferma || this.formMaterialita.get('dataUltimaConferma')?.value) {
      return true;
    }

    let descrizione: string = `
      <div>
        <p><strong>Vuoi concludere la tua analisi?</strong></p>
        <ul>
          <li>Proseguendo visualizzerai i <strong>risultati nella matrice di materialità</strong>
          e <strong>non sarà</strong> più possibile<br><strong>ricevere</strong> risultati
          tramite mail o <strong>inserirli</strong> manualmente.</li>
        </ul>
        <p><strong>Vuoi procedere? </strong></p>
      </div>
    `;
    let titolo: string = 'Concludi l\'Analisi e genera la Matrice di Materialità';

    // Wrappo il dialogo in una Promise per gestire la risposta asincrona
    const confirmed: boolean = await new Promise<boolean>((resolve) => {
      this.utilityService.openDialog({
        titolo: titolo,
        descrizione: descrizione,
        fontWeight: '500',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N',
            handler: () => {
              resolve(false);
            }
          },
          {
            nome_btn: 'Si',
            id_btn: 'S',
            handler: async () => {
              try {
                const idMaterialita = this.formMaterialita.get('id')?.value!;
                const result = await firstValueFrom(
                  this.materialitaService.putCosolidaRisultato(idMaterialita, '', [])
                );

                this.formMaterialita.get('dataUltimaConferma')?.setValue(result?.dataUltimaConferma);
                this.formAbilitaStepAnalisiRisultati.setValue(true);
                resolve(true);
              } catch (err: any) {
                this.utilityService.openDialog({
                  titolo: 'Attenzione',
                  descrizione: err?.error?.message,
                  bottoni: [
                    {
                      nome_btn: 'Chiudi',
                      handler: () => {
                        this.tabella.caricaDati();
                        resolve(false);
                      }
                    }
                  ]
                });
              }
            }
          }
        ]
      });
    });

    return confirmed;
  }


  noteImpatto(noteImpatto: string) {
    this.utilityService.openDialog({
      titolo: 'Note Impatto',
      descrizione: noteImpatto,

      bottoni: [
        {
          nome_btn: 'Chiudi', handler: () => {
          }
        }]
    }, 1000);
  }
  /**
   * Metodo che mi crea una nuova categoira StakeHolder dentro la materialità
   */
  creaCategoriaSh(stepperIndex: number) {
    let tipo: string = '';
    if (stepperIndex === 2) {
      tipo = 'INTERNI'
    } else if (stepperIndex === 3) {
      tipo = 'ESTERNI'
    }

    const dialogCreaCategoriaSh = this.dialog.open(DialogCreaCategoriaStakeholderComponent, {
      data: {
        cate_stakeholder: {
          idAzienda: this.survey.aziende[0],
          tipologia: tipo,
        },
        inMaterialita: true,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaCategoriaSh.afterClosed().subscribe((result) => {
      this.spinnerOver.show();

      if (stepperIndex === 2) {
        this.stInterni.refreshStakeholders(true);
        this.spinnerOver.hide();
      } else {
        this.stEsterni.refreshStakeholders(true);
        this.spinnerOver.hide();
      }
    });
  }


  private _updateSottoSettoriSelezionati() {
    const sottoSettoriSel = this.formMaterialita.get('sottoSettori')?.value || [];

    const sottoSettoriaggiornati = sottoSettoriSel.filter(
      (sottoSettSel) => {
        return this.arraySottoSettori && sottoSettSel.id && !!this.arraySottoSettori.find(
          (sottoSett) => {
            return sottoSett.id === sottoSettSel.id;
          });
      }
    );

    this.formMaterialita.get('sottoSettori')?.setValue(sottoSettoriaggiornati || []);
  }

  private _eseguiConferma(): Observable<boolean> {

    const idMaterialita = this.formMaterialita.get('id')?.value;

    if (idMaterialita) {

      const imgGraficoMaterialita: string = this.analisiRisultatiComponent.convertGraficoInBase64();
      const legendaGrafico: any = this.analisiRisultatiComponent.oggettoLegendaGrafico();

      return this.materialitaService.putCosolidaRisultato(idMaterialita, imgGraficoMaterialita, legendaGrafico).pipe(
        map((res) => true),
      );
    } else {
      return throwError(() => "Materialità non trovata");
    }

  }

  ctrAnnoMaterialitaUtilizzata(anno: string) {
    return this.isAnnoSettoreValid(anno, this.formMaterialita.get('settori')?.value?.id);
  }

  ctrSettoreMaterialitaUtilizzata(settore: Settore) {
    const anno = this.formMaterialita.get('anno')?.value;
    return this.isAnnoSettoreValid(anno, settore?.id);
  }

  private isAnnoSettoreValid(anno: string | undefined, settoreId: string | undefined) {
    return anno && settoreId && this.mappaAnnoSettore[anno]?.[settoreId] ? true : false;
  }

  ctrBottoneVisualizza(record: any): boolean {
    //Mostra bottone se
    if ((this.data?.materialita?.dataUltimaConferma || this.formMaterialita.get('dataUltimaConferma')?.value) && record?.idSurveyCompilato && record?.stato?.codice === 'COMP') {
      return false;
    }
    return true;

  }
  ctrBottoneCompila(record: any): boolean {
    //Nascondi bottone se
    if (!record || record?.modalitaCompilazione === 'INVIAMAIL' || this.formMaterialita.get('dataUltimaConferma')?.value) {
      return true;
    }
    return false;

  }


  saltaCtrStepper(idx: number) {
    // Se i miei permessi sono dolo in read
    if (this.isPermessoSoloRead) {
      return true;
    }

    if (this.formMaterialita.get('dataUltimaConferma')?.value && idx === 5) {
      return true;
    }

    // Se pubblicato
    if ((this.isPubblicato() || this.data?.materialita?.stato === 'PUBBLICATO') && idx < 5) {
      return true
    }

    // Se torno indietro non faccio ne controlli ne salvataggi
    if (idx < this.stepper.selectedIndex) {
      return true
    }
    return false;
  }

  /**
   * Metodo che gestisce la logica della navigazione sugli stepper
   */
  gestioneStepperMaterialita() {

    setTimeout(() => {

      this.stepper.steps.forEach((step, idx) => {
        step.select = async () => {

          // Salto controllo stepper
          if (this.saltaCtrStepper(idx)) {
            return this.stepper.selectedIndex = idx;
          }

          /* Se step selezionato è completed devo controllare gli step in mezzo
           Esempio (sono su STEP 1 seleziono STEP 6 , devo controllare STEP 2-3-4-5) */
          if (this.stepper.steps.get(idx)?.completed || this.stepper.steps.get(idx)?.hasError) {
            return this.stepper.selectedIndex = await this.ctrStep(idx);
          }


          if (idx === this.stepper.selectedIndex + 1) { // linear
            const isSalvato = await this.avanti();

            if (isSalvato) {
              return this.stepper.selectedIndex = idx;
            }
          }
          return;
        };
      });

    }, 0);

  }

  /**
   * gestione controlli step selezionati
   * @param stepperSelezionato
   * @returns
   */
  private async ctrStep(stepperSelezionato: number): Promise<number> {
    const stepperCorrente = this.stepper.selectedIndex;

    if (stepperSelezionato > stepperCorrente) {
      for (let index = stepperCorrente; index < stepperSelezionato; index++) {

        // Salvo step corrente
        if (stepperCorrente === index) {
          const isSalvato = await this.avanti();
          if (!isSalvato) {
            return stepperCorrente;
          } else {
            continue;
          }
        }

        // Controllo step by step e ritorno l'index dove da errore
        if (await this.ritornoControllo(index)) {
          return index;
        }

      }
    }

    return stepperSelezionato;
  }

  /**
   * Controlli degli stepper
   * @param stepperDaControllare
   * @returns
   */
  private async ritornoControllo(stepperDaControllare: number): Promise<boolean> {
    let isCtrFallito: boolean = false;;
    this.spinnerOver.show();
    switch (stepperDaControllare) {

      case 1: {
        const esito = await this._getImpattiMaterialita();
        if (esito) {
          isCtrFallito = this.componenteSelImpattiMaterialita.ctrStepper('impatto');
        }
        break;
      }
      case 2: {
        const esito = await this.stInterni.refreshStakeholders();
        if (esito) {
          isCtrFallito = !await this.ctrSH('shInterni');
        }
        break;
      }
      case 3: {
        const esito = await this.stEsterni.refreshStakeholders();
        if (esito) {
          isCtrFallito = !await this.ctrSH('shEsterni');
        }
        break;
      }
      case 4: {
        isCtrFallito = !await this._confermaSurvey();
        break;
      }

      default:
        break;
    }
    this.spinnerOver.hide();
    return isCtrFallito

  }

  /**
    * Viene richiamato nel momento in cui si caricano le tamatiche (questionario)
    */
  public completaStep() {
    if (this.isPubblicato()) {
      this.stepper.steps.forEach((step, idx) => {
        step.completed = true;
      });
    }
  }

  ctrCruscottoStepControl() {
    return new FormControl(this.isPermessoSoloRead ? false : (this.formImpatti && this.formAbilitaStepAnalisiRisultati));
  }
}
