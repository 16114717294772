import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FnCaricamentoDati, Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { ListaTabellareComponent, Filtri, Colonna, BottoniListaEvent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { ImpattoService } from 'src/app/services/impatto/impatto.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaCfgImpattoComponent } from './dialog/dialog-crea-cfg-impatto/dialog-crea-cfg-impatto.component';
import { DialogImportazioneExcelComponent } from '../../analisi-materialita/stakeholder/dialog/dialog-importazione-excel/dialog-importazione-excel.component';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { SettoriService } from 'src/app/services/settori/settori.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-cfg-impatti',
  templateUrl: './cfg-impatti.component.html',
  styleUrls: ['./cfg-impatti.component.scss']
})
export class CfgImpattiComponent implements OnInit {

  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  public filtri: Filtri[] = [
    {
      titolo: 'Titolo',
      forControlName: 'titolo',
      input: 'text',
    },
    {
      titolo: 'Descrizione',
      forControlName: 'descrizione',
      input: 'text',
      riempi: true,
      larghezza: 2
    },
   /*  {
      forControlName: 'impattoPotenzialeEffettivo',
      input: 'multiple-option',
      titolo: 'Impatto Potenziale/Effettivo',
      fnDatiOption: () => this.impattoService.getImpattoPotEff(),
      descValueOption: 'descrizione',
      idValueOption: 'codice',
      riempi: true,
    },
    {
      forControlName: 'impattoPositivoNegativo',
      input: 'multiple-option',
      titolo: 'Impatto Positivo/Negativo',
      fnDatiOption: () => this.impattoService.getImpattoPosNeg(),
      descValueOption: 'descrizione',
      idValueOption: 'codice',
      riempi: true
    }, */
    {
      forControlName: 'settori',
      input: 'multiple-option',
      titolo: 'Settore',
      fnDatiOption: () => (this.settoriService.getSettori(0, 1000)).pipe(map((value) => value?.content || [])),
      descValueOption: 'titolo',
      idValueOption: 'id',
      riempi: true,
      larghezza: 1
    },
    {
      titolo: 'TAG',
      forControlName: 'tag',
      input: 'multiple-option-chip',
      riempi: true,
      larghezza: 3,
      fnDatiOption: (ricerca: string | undefined) => this.impattoService.getImpattiTags(0, 1000, ricerca),
      numeroRecOptions: Infinity
    },
  ];

  public colonne: { [key: string]: Colonna } = {
    titolo: {
      title: 'Titolo',
      value: 'titolo',
      sortable: true
    },
    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true
    },
    /*   duplica: {
        type: 'button',
        buttonIcon: 'content_copy',
        title: 'Duplica',
        buttonId: 'duplica',
      }, */
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina'
    },
  };

  constructor(
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private impattoService: ImpattoService,
    public utenteService: UtenteService,
    private settoriService: SettoriService,
  ) { }

  ngOnInit(): void {
  }


  crea(recordImpatto?: any) {
    const dialogCreaImpatto = this.dialog.open(DialogCreaCfgImpattoComponent, {
      data: {
        impatto: recordImpatto,
        isFatturato: true
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '100%',
      height: '99%',
      maxHeight: '99%',
      autoFocus: false,
    });

    dialogCreaImpatto.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    if (!sortBy) {
      sortBy = [{
        chiave: 'titolo',
        sort: 'asc'
      }];
    }

    return this.impattoService.getImpatti(page, pageSize, ricerca, filters, sortBy);
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.crea(event.data);
        break;
      case 'elimina':
        this._elimina(event.data.id);
        break;
      case 'duplica':
        this._duplica();
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
   * Funzione per eliminare una riga da una lista tabellare
   * @param idRiga id della riga da eliminare
   */
  private _elimina(idRiga: any) {
    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare l\'impatto?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)'
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.spinnerOver.show();
            this.impattoService.deleteImpatto(idRiga).subscribe(
              {
                next: (result: any) => {

                  this.tabella.caricaDati();
                  this.spinnerOver.hide();
                },
                error: (err: any) => {
                  this.spinnerOver.hide();
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err.error.message,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );

          }
        }]
    });

  }
  /**
   * Metodo per la duplicazione di un impatto
   */
  public _duplica() {
    this.utilityService.openDialog({
      titolo: 'Attenzione',
      descrizione: 'Da implementare la duplicazione ',
      bottoni: [
        {
          nome_btn: 'Chiudi', handler: () => {
            this.utilityService.closeDialog();
          }
        }]
    })
  }

  public importaImpatti() {
    const dialogImportaImpatti = this.dialog.open(DialogImportazioneExcelComponent, {
      data: {
        titoloImportazione: 'Importazione Impatti',
        subTitoloImportazione: 'Scarica il file di template excel o fai una nuova importazione degli impatti',
        pathTemplateExcel: 'assets/excel/impatti.xlsx',
        azienda: false,
        nomeImport: 'impatti',
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '60%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogImportaImpatti.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

}
