import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { PerimetriDestinatari, PolicyService } from 'src/app/services/policy/policy.service';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-compila-perimetri-destinatari',
  templateUrl: './compila-perimetri-destinatari.component.html',
  styleUrls: ['./compila-perimetri-destinatari.component.scss']
})
export class CompilaPerimetriDestinatariComponent {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  public formPerimDestComp = new FormGroup({

    descrizione: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
    lineaGuidaCompilata: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required, UtilityService.blankValidator],
    }),
  });

  constructor(
    public dialogRef: MatDialogRef<CompilaPerimetriDestinatariComponent>,
    private readonly policyService: PolicyService,
    private readonly utilityService: UtilityService,
    private readonly cdRef: ChangeDetectorRef,

    @Inject(MAT_DIALOG_DATA) public data: {
      perimetroDestinatario: PerimetriDestinatari,
      idPolicy: string,
      isPermessoSoloRead: boolean,
    }) { }

  ngAfterViewInit(): void {


    Object.keys(this.data.perimetroDestinatario).forEach((value) => {
      this.formPerimDestComp.get(value)?.setValue((this.data.perimetroDestinatario as any)[value]);
    })

    if (this.data?.isPermessoSoloRead) {
      this.formPerimDestComp.disable();
    }
    this.cdRef.detectChanges()

  }

  salva() {
    if (this.formPerimDestComp.valid) {
      this.spinnerOver.show();


      const descrizione = this.formPerimDestComp.get('descrizione')?.value || '';
      const lineaGuidaCompilata = this.formPerimDestComp.get('lineaGuidaCompilata')?.value || '';
      const idPerimetroDestinatario = this.data.perimetroDestinatario.id;


      if (idPerimetroDestinatario) {
        let objCall: any;
        if (this.data.perimetroDestinatario.chiaveElemento === 'perimetro') {
          objCall = this.policyService.putCompilazionePerimetriInPolicy(this.data.idPolicy, idPerimetroDestinatario, descrizione, lineaGuidaCompilata)
        } else {
          objCall = this.policyService.putCompilazioneDestinatariInPolicy(this.data.idPolicy, idPerimetroDestinatario, descrizione, lineaGuidaCompilata)
        }

        objCall.subscribe({
          next: (esito: any) => {
            this.utilityService.opneSnackBar('Compilazione effettuata ', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });
            this.spinnerOver.hide();
            this.dialogRef.close(esito);
          },
          error: (err: any) => {
            this.utilityService.opneSnackBar(err?.error?.message || 'Errore compilazione ', '', {
              duration: 2000,
              panelClass: ['red-snackbar']
            });
            this.spinnerOver.hide();
          }

        })
      }
    } else {
      Object.values(this.formPerimDestComp.controls).forEach(
        (control) => {
          control.markAllAsTouched();
        }
      )
    }
  }
}
