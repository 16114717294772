import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map, Subscription } from 'rxjs';
import { FnCaricamentoDati, Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';
import { PianoService } from 'src/app/services/piano/piano.service';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaPianoSostenibilitaComponent } from './dialog/dialog-crea-piano-sostenibilita/dialog-crea-piano-sostenibilita.component';
import { LicenzeService } from 'src/app/services/licenze/licenze.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { SettoriService } from 'src/app/services/settori/settori.service';

@Component({
  selector: 'app-piano-sostenibilita',
  templateUrl: './piano-sostenibilita.component.html',
  styleUrls: ['./piano-sostenibilita.component.scss']
})
export class PianoSostenibilitaComponent {

  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  private azienda: Azienda | undefined = undefined;

  public filtri: Filtri[] = [
    {
      titolo: 'Anno',
      forControlName: 'anno',
      input: 'text',
    },
    {
      forControlName: 'settori',
      input: 'multiple-option',
      titolo: 'Settore',
      fnDatiOption: () => (this.settoriService.getSettori(0, 1000)).pipe(map((value) => value?.content || [])),
      descValueOption: 'titolo',
      idValueOption: 'id',
      riempi: true,
      larghezza: 1
    },
  ];

  public colonne: { [key: string]: Colonna } = {
    anno: {
      title: 'Anno',
      value: 'anno',
      sortable: true
    },
    settore: {
      title: 'Settore',
      sortable: true,
      campiSort: ['settore'],
      value: (record: any) => (record?.settori[0]?.titolo || ''),
    },
    risultatiImpegni: {
      title: 'Target Quantitativi',
      value: (record: any) => (record?.compilazioni?.kpi?.compilati || 0) + '/' + (record?.compilazioni?.kpi?.totali || 0)
    },
    risultatiAzioni: {
      title: 'Target Qualitativi',
      value: (record: any) => (record?.compilazioni?.azioni?.compilati || 0) + '/' + (record?.compilazioni?.azioni?.totali || 0)
    },
    modifica: {
      type: 'button',
      buttonIcon: this.isPermessoSoloRead() ? 'visibility' : 'edit',
      title: this.isPermessoSoloRead() ? 'Visualizza' : 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      sortable: false,
    },
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record: any) => this.nascondiBottoneElimina(record),
      buttonMostraSempre: true
    },
  };

  private _subAziendaCorrente: Subscription | undefined;

  constructor(
    public dialog: MatDialog,
    private readonly utilityService: UtilityService,
    private readonly aziendaService: AziendaService,
    private readonly pianoService: PianoService,
    public utenteService: UtenteService,
    private readonly licenzeService: LicenzeService,
    private readonly settoriService: SettoriService,
  ) { }


  nascondiBottoneElimina(record: any) {
    if (this.utenteService.infoUtente?.isDeveloper) {
      return false; //non nascondere
    }
    if ((record.stato === 'PUBBLICATO' || !this.utenteService.isPermessoAttivo('DELETE_PIANO'))) {
      return true //nascondere
    }
    return false
  }

  isPermessoSoloRead() {
    const isEdit = this.utenteService.isPermessoAttivo('EDIT_PIANO');
    const isCreate = this.utenteService.isPermessoAttivo('CREATE_PIANO')
    return (!isEdit && !isCreate)
  }

  ngAfterViewInit(): void {
    this._subAziendaCorrente = this.aziendaService.aziendaCorrente.subscribe((azienda) => {

      let cambiato = false;

      if (this.azienda && azienda && this.azienda.id !== azienda.id) {
        cambiato = true;
      }

      this.azienda = azienda;

      if (cambiato) {
        this.tabella?.caricaDati();
      }
    });
  }

  ngOnDestroy(): void {
    this._subAziendaCorrente?.unsubscribe();
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    return this.pianoService.getPianoSostenibilita(page, pageSize, ricerca, filters, sortBy);
  };

  /**
   * Metodo di modifica o creazione nuova policy 
   * @param record 
   */
  crea(record?: any) {
    if (!record) {
      this.spinnerOver.show();
      this.licenzeService.getUserHasLicense('Piano').subscribe({
        next: (esito) => {
          this.spinnerOver.hide();
          this.apriDialogCreaPiano(record);
        },
        error: (err) => {
          this.spinnerOver.hide();
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message,
            bottoni: [{ nome_btn: 'Ok' }]
          });
        }
      });
    } else {
      this.apriDialogCreaPiano(record);
    }
  }


  private apriDialogCreaPiano(record: any) {
    const dialogCreaPiano = this.dialog.open(DialogCreaPianoSostenibilitaComponent, {
      data: {
        piano: record
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '100%',
      height: '99%',
      maxHeight: '99%',
      autoFocus: false,
    });

    dialogCreaPiano.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.crea(event.data);
        break;
      case 'elimina':
        this._elimina(event.data);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
  * Funzione per eliminare una riga da una lista tabellare 
  * @param idRiga id della riga da eliminare 
  */
  private _elimina(piano: any) {

    let descrizioneElimina: string;
    let titolo: string;
    descrizioneElimina = '<div> <p><strong> Si prega di notare che questa operazione eliminerà anche tutte le compilazioni raccolte associate.</strong></p> <ul> <li>L’eliminazione è definitiva e <strong>NON può essere annullata</strong>.</li> <li>Tutte le informazioni e le compilazioni raccolte saranno perse <strong>permanentemente</strong>.</li></ul><p><strong>Sei sicuro di voler continuare?</strong></p>'
    titolo = 'Eliminazione Dati Irreversibile'

    this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizioneElimina,
      fontWeight: '500',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)'
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.spinnerOver.show();
            this.pianoService.deletePianoSostenibilita(piano?.id).subscribe(
              {
                next: (result: any) => {

                  this.tabella.caricaDati();
                  this.spinnerOver.hide();
                },
                error: (err: any) => {
                  this.spinnerOver.hide();
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err.error.message,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );

          }
        }]
    });

  }
}
