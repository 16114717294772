<h2 mat-dialog-title>Configurazioni Standard di Rendicontazione, Compliance Normativi e Target</h2>
<div mat-dialog-content class="dialog-center-cont">
    <app-spinner-overlay></app-spinner-overlay>

    <div class="cont-checkbox">
        <div class="sezione">

            <div class="cont-titolo">
                <div style="display: flex;
                align-items: center;
                justify-content: center;">
                    <div class="titolo" style="font-size: 14px !important;display: flex;
                    align-items: center;
                    justify-content: center;">STDR
                    </div>
                </div>
            </div>

            <mat-accordion multi #accordionStdr class="accordion">
                <ng-scrollbar>
                    <mat-expansion-panel *ngFor="let stdr of arrayStdr" expanded>
                        <mat-expansion-panel-header>
                            <mat-panel-title class="panel-title-in-target">

                                <mat-checkbox [checked]="stdr.selected" [(ngModel)]="stdr.selected" color="primary"
                                    [disabled]="isStdrPadreDisabled(stdr)" (click)="$event.stopPropagation()"
                                    
                                    (change)="allCompleteStdr($event, stdr); setAll($event.checked , stdr); toggleCheckbox($event,stdr)">
                                    <div class="nome-stdr"> <a style="font-weight: 600;"> {{stdr.codice}}</a>
                                        {{stdr.descrizione}}</div>
                                </mat-checkbox>

                                <mat-slide-toggle  [matTooltip]="!stdr.target ?  'Attiva Target': 'Disattiva Target'" [(ngModel)]="stdr.target"
                                    [disabled]="!stdr.selected"
                                    (click)=" slideTarget(stdr); $event.stopPropagation()"></mat-slide-toggle>

                            </mat-panel-title>

                            <mat-panel-description>
                                <div *ngIf="isStdrPadreDisabled(stdr)"
                                    style="display: flex; align-items: center; transform: scale(0.9);padding-right: 10px;">
                                    <mat-icon class=" material-icons-outlined"
                                        matTooltip="Disabilitato perché settoriale e non generale"> info </mat-icon>
                                </div>

                                <span *ngIf="stdr.figli.length"
                                    class="nr-sel">{{nrSelezionati}}/{{stdr.figli.length}}</span>
                            </mat-panel-description>

                        </mat-expansion-panel-header>
                        <div style="display: flex; flex-direction: column;">
                            <div *ngFor="let figli of stdr.figli" class="panel-title-in-target">
                                <mat-checkbox (ngModelChange)="updateAllComplete(stdr, figli)"
                                    [checked]="figli.selected" (change)="toggleCheckboxFigli($event,figli)"
                                    [(ngModel)]="figli.selected" (mousedown)="$event.stopPropagation()">

                                    <div class="nome-stdr">
                                        <a style="font-weight: 600;"> {{figli.codice}}</a> {{figli.descrizione}}
                                    </div>
                                </mat-checkbox>
                                <mat-slide-toggle  [matTooltip]="!figli.target ?  'Attiva Target': 'Disattiva Target'"[(ngModel)]="figli.target"
                                    [disabled]="!figli.selected || stdr.selected "></mat-slide-toggle>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </ng-scrollbar>
            </mat-accordion>

        </div>

        <div class="sezione">

            <div class="cont-titolo">
                <div style="display: flex;
                align-items: center;
                justify-content: center;">
                    <div class="titolo" style="font-size: 14px !important;display: flex;
                    align-items: center;
                    justify-content: center;">Compliance Normativi
                    </div>
                </div>
            </div>

            <mat-accordion multi #accordionCompliance class="accordion">
                <ng-scrollbar>
                    <mat-expansion-panel *ngFor="let compliance of arrayCompliance" expanded>
                        <mat-expansion-panel-header>
                            <mat-panel-title class="panel-title-in-target">

                                <mat-checkbox [checked]="compliance.selected" [(ngModel)]="compliance.selected"
                                    color="primary" [disabled]="isStdrPadreDisabled(compliance)"
                                    (click)="$event.stopPropagation()"
                                    (change)="allCompleteStdr($event, compliance); setAll($event.checked , compliance); toggleCheckbox($event,compliance)">
                                    <div class="nome-stdr"> <a style="font-weight: 600;"> {{compliance.codice}}</a>
                                        {{compliance.descrizione}}</div>
                                </mat-checkbox>

                                <mat-slide-toggle  [matTooltip]="!compliance.target ?  'Attiva Target': 'Disattiva Target'" [(ngModel)]="compliance.target"
                                    [disabled]="!compliance.selected"
                                    (click)=" slideTarget(compliance); $event.stopPropagation()"></mat-slide-toggle>

                            </mat-panel-title>

                            <mat-panel-description>
                                <div *ngIf="isStdrPadreDisabled(compliance)"
                                    style="display: flex; align-items: center; transform: scale(0.9);padding-right: 10px;">
                                    <mat-icon class=" material-icons-outlined"
                                        matTooltip="Disabilitato perché settoriale e non generale"> info </mat-icon>
                                </div>

                                <span *ngIf="compliance.figli.length"
                                    class="nr-sel">{{nrSelezionati}}/{{compliance.figli.length}}</span>
                            </mat-panel-description>

                        </mat-expansion-panel-header>

                        <div *ngFor="let figli of compliance.figli" class="panel-title-in-target">
                            <mat-checkbox (ngModelChange)="updateAllComplete(compliance, figli)"
                                [checked]="figli.selected" (change)="toggleCheckboxFigli($event,figli)"
                                [(ngModel)]="figli.selected" (mousedown)="$event.stopPropagation()">

                                <div class="nome-stdr"> <a style="font-weight: 600;"> {{figli.codice}}</a>
                                    {{figli.descrizione}}</div>
                            </mat-checkbox>
                            <mat-slide-toggle [matTooltip]="!figli.target ?  'Attiva Target': 'Disattiva Target'"
                                [(ngModel)]="figli.target" [disabled]="!figli.selected"></mat-slide-toggle>
                        </div>

                    </mat-expansion-panel>
                </ng-scrollbar>
            </mat-accordion>

        </div>
    </div>
    <div mat-dialog-actions class="dialog-footer-cont">
        <div>
            <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
        </div>
        <div>
            <button mat-flat-button color="primary" (click)="salva()" class="primary-button">
                Salva
            </button>
        </div>
    </div>