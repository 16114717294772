import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaComitatiComponent } from './dialog/dialog-crea-comitati/dialog-crea-comitati.component';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { ComitatiService } from 'src/app/services/comitati/comitati.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';

@Component({
  selector: 'app-cfg-comitati',
  templateUrl: './cfg-comitati.component.html',
  styleUrls: ['./cfg-comitati.component.scss']
})
export class CfgComitatiComponent implements OnInit {
  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  private azienda: Azienda | undefined = undefined;

  private _perAzienda = false;

  private _subAziendaCorrente: Subscription | undefined;

  public filtri: Filtri[] = [

    {
      titolo: 'Descrizione',
      forControlName: 'descrizione',
      input: 'text',
    },
  ];

  public colonne: { [key: string]: Colonna } = {

    descrizione: {
      title: 'Descrizione',
      value: 'descrizione',
      sortable: true
    },

    modifica: {
      type: 'button',
      buttonIcon: (record) => this.azienda && !record.idAzienda ? 'lock' : 'edit',
      title: (record) => this.azienda && !record.idAzienda ? 'Non modificabile' : 'Modifica',
      buttonId: (record) => this.azienda && !record.idAzienda ? 'bloccato' : 'modifica',
      // nascondiButton: (record) => this.azienda && !record.idAzienda ? true : false,
      buttonMostraSempre: true
    },

    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record) => this.azienda && !record.idAzienda ? true : false
    },
  };


  constructor(
    public dialog: MatDialog,
    private aziendaService: AziendaService,
    private utilityService: UtilityService,
    private comitatiService: ComitatiService,
    private route: ActivatedRoute
  ) { }


  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe((data) => {
      this._perAzienda = !!data['perAzienda'];

      console.log('this._perAzienda : ', this._perAzienda);

      if (this._perAzienda) {
        this._initSubAzienda();
      }
    });
  }


  ngOnDestroy() {
    this._subAziendaCorrente?.unsubscribe();
  }


  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {

    if (!sortBy) {
      sortBy = [{
        chiave: 'descrizione',
        sort: 'asc'
      }];
    }

    if (this._perAzienda) {
      return this.comitatiService.getComitatiLibreriaAndAzienda(page, pageSize, ricerca, filters, sortBy);
    } else {
      return this.comitatiService.getComitati(page, pageSize, ricerca, filters, sortBy);
    }
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaNuovo(event.data);
        break;
      case 'elimina':
        this.elimina(event.data.id);
        break;
      case 'bloccato':
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Comitato preconfigurato. Modifica non permessa.',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }


  public creaNuovo(comitato?: any) {

    const dialogCreaComitato = this.dialog.open(DialogCreaComitatiComponent, {
      data: {
        comitati: comitato,
        idAzienda: this.azienda?.id
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '75%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaComitato.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public elimina(idRiga: string) {

    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare il comitato',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.spinnerOver.show();
            this.comitatiService.deleteComitati(idRiga).subscribe(
              {
                next: () => {
                  this.tabella.caricaDati();
                  this.spinnerOver.hide();
                },
                error: (err: any) => {
                  this.spinnerOver.hide();
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: err.error.message,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });

  }


  /**
   * Inizializza la subscription che intercetta i cambi azienda.
   * Utilizzto solo quando siamo nella maschera dei comitati per azienda.
   */
  private _initSubAzienda() {

    this._subAziendaCorrente = this.aziendaService.aziendaCorrente.subscribe((azienda) => {

      let cambiato = false;

      if (this.azienda?.id !== azienda?.id) {
        cambiato = true;
      }

      this.azienda = azienda;

      if (cambiato) {
        this.tabella?.caricaDati();
      }
    });
  }

}
