<h2 mat-dialog-title>
    Tematiche
</h2>
<div mat-dialog-content class="dialog-center-cont">

    <app-spinner-overlay></app-spinner-overlay>

    <form [formGroup]="formTematiche">
        <div appFormContainer [larghezza]="5">

            <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
                <mat-label>Nome Breve</mat-label>
                <input matInput placeholder="Nome Breve" formControlName="nomeBreve" />
                <mat-error appFormError></mat-error>
            </mat-form-field>

            <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
                <mat-label>Domanda</mat-label>
                <input matInput placeholder="Domanda" formControlName="domanda" />
                <mat-error appFormError></mat-error>
            </mat-form-field>

            <mat-form-field appFormElement [larghezza]="1">
                <mat-label>Ordinamento</mat-label>
                <input matInput placeholder="Ordinamento" formControlName="index" type="number" inputmode="numeric"
                    appInputNumber min="0" max="999999999999" (keydown)="onKeyDown($event)" />
                <mat-error appFormError></mat-error>
            </mat-form-field>

            <mat-form-field appFormElement [larghezza]="5" [riempi]="true">
                <mat-label>Descrizione</mat-label>
                <input matInput placeholder="Descrizione" formControlName="descrizione" />
                <mat-error appFormError></mat-error>

            </mat-form-field>
        </div>
        <div style="height: 10px;"></div>
        <!-- TABBE -->
        <!-- TAB DOVE CI SONO GLI AMBITI -->
        <mat-tab-group (selectedTabChange)="onTabChange($event)">
            <ng-container>
                <mat-tab label="Generali">
                    <!-- CONTENUTO DELLA TAB -->
                    <div style="height: 20px;"></div>

                    <div appFormContainer [larghezza]="8">

                        <mat-form-field appFormElement [larghezza]="4">
                            <mat-label>Ambiti</mat-label>
                            <mat-select formControlName="idsAmbiti" placeholder="Ambiti" multiple>
                                <mat-option *ngFor="let amb of arrayAmbiti"
                                    [value]="amb.id">{{amb.nomeBreve}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div appFormElement [larghezza]="2"
                            style="display: flex; align-items: baseline; column-gap: 5px;">
                            <mat-form-field style="width: 100%;">
                                <mat-label>Area</mat-label>
                                <mat-select formControlName="aree" placeholder="Area" multiple
                                    (selectionChange)="onSelectionChangeAree($event)">
                                    <mat-option *ngFor="let are of arrayAree"
                                        [value]="are.chiave">{{are.descrizione}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div *ngIf="areaSelezionata" [@fadeInOut]>
                                <ng-template #tooltip>
                                    <div class="descrizione-tooltip">Una volta selezionata l'area di
                                        <span style="font-weight: bold;">Materialità</span>, viene
                                        automaticamente selezionata anche l'area <span
                                            style="font-weight: bold;">Sintesi/Bilancio</span>.
                                    </div>
                                </ng-template>

                                <div *mytooltip="tooltip; posizione: 'bottom'; offset: 45 ">
                                    <mat-icon style="color: var(--mat-color-accent);"> info </mat-icon>
                                </div>
                            </div>
                        </div>

                       <!--  <mat-form-field appFormElement [larghezza]="2">
                            <mat-label>Standard di rendicontazione</mat-label>
                            <mat-select formControlName="stdr" placeholder="Standard di rendicontazione" multiple>
                                <mat-option *ngFor="let stdr of arrayObbligatorio" [value]="stdr.chiave">
                                    {{stdr.descrizione}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->

                        <mat-form-field appFormElement [larghezza]="2">
                            <mat-label>Livello min. risp. automatiche</mat-label>
                            <input matInput placeholder="(Solo autovalutazione)"
                                formControlName="minLivelloRispostaAutomatica" />
                            <mat-error appFormError></mat-error>

                        </mat-form-field>
                        <div appFormElement [larghezza]="4">
                            <app-select-custom [formControl] = "stdr" titolo="Standard di rendicontazione"></app-select-custom>
                        </div>
                
                        <div appFormElement [larghezza]="4">
                            <app-select-custom [formControl] = "compliance" titolo="Compliance Normativi"></app-select-custom>
                        </div>
                
                        <mat-form-field appFormElement [larghezza]="8">
                            <mat-label>Sotto-settori</mat-label>
                            <mat-chip-grid #chipGrid aria-label="Selezione sotto-settori">
                                <mat-chip-row
                                    *ngFor="let sottoSetSel of (formTematiche.get('sottoSettori')?.value || [])"
                                    (removed)="rimuoviSottoSettore(sottoSetSel)">
                                    <span
                                        *ngIf="sottoSetSel.id">{{settoriPerIdSottosettore[sottoSetSel.id].titolo}}/</span>{{sottoSetSel.titolo}}
                                    <button matChipRemove [attr.aria-label]="'remove ' + sottoSetSel.titolo">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip-row>
                            </mat-chip-grid>
                            <input placeholder="Ricerca sottoSettori" #inputSottoSettori
                                [formControl]="formControlChipSottoSettori" [matChipInputFor]="chipGrid"
                                [matAutocomplete]="auto" placeholder="Scrivi per cercare i sotto-settori..." />
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selezionaSottoSettore($event)"
                                placeholder="Sotto-settori" multiple [compareWith]="compareById">
                                <mat-optgroup *ngFor="let settore of sottoSettoriFiltrati | async"
                                    [label]="settore.titolo">
                                    <mat-option *ngFor="let sottoSettore of settore.sottoSettori"
                                        [value]="sottoSettore">
                                        {{sottoSettore.titolo}}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field appFormElement [larghezza]="8">
                            <mat-label>Obiettivi di Sviluppo Sostenibile</mat-label>
                            <mat-select formControlName="sdgs" placeholder="Obiettivi di Sviluppo Sostenibile" multiple
                                [compareWith]="compareByChiave">
                                <mat-option *ngFor="let sdgs of arraySdgs" [value]="sdgs">
                                    {{sdgs.valore}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appFormElement [larghezza]="8">
                            <mat-label>Note</mat-label>
                            <textarea matInput placeholder="Note" formControlName="note" rows="4"></textarea>
                            <mat-error appFormError></mat-error>
                        </mat-form-field>

                    </div>

                </mat-tab>

                <mat-tab label="Risposte"
                    *ngIf="this.formTematiche.get('aree')?.value?.length && this.formTematiche.get('aree')?.value?.includes('autovalutazioni')">
                    <mat-accordion>
                        <div cdkDropList (cdkDropListDropped)="drop($event)">
                            <mat-expansion-panel [expanded]="espandi"
                                (opened)="ctrBottoni($any(rispArr.get('sottoOpzioni')?.value))"
                                *ngFor="let rispArr of $any(formTematiche.get('opzioni'))?.controls; let idx = index"
                                cdkDrag>
                                <div class="" *cdkDragPlaceholder></div>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <button mat-icon-button title="Sposta" aria-label="Sposta">
                                            <mat-icon> drag_handle </mat-icon>
                                        </button>
                                        {{idx + 1}}
                                    </mat-panel-title>
                                    <mat-panel-description>
                                        <div class="testo-risposta">
                                            {{rispArr.get("valore").value}}
                                        </div>

                                        <div>
                                            Punteggio: <strong>
                                                {{rispArr.get("punteggio").value == null ||
                                                rispArr.get("punteggio").value == undefined ? 'NC' :
                                                rispArr.get("punteggio").value}}</strong>
                                        </div>
                                        <button mat-icon-button title="Elimina" aria-label="Elimina" color="warn"
                                            (click)="elimina( $any(formTematiche.get('opzioni')), idx)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <!--form opzioni -->
                                <div appFormContainer [larghezza]="4" [formGroup]="rispArr">

                                    <mat-form-field (mousedown)="$event.stopPropagation()" appFormElement
                                        [larghezza]="2" [riempi]="true">
                                        <mat-label>Descrizione</mat-label>
                                        <input #inputDesc matInput placeholder="Descrizione" formControlName="valore" />
                                        <mat-error appFormError></mat-error>
                                    </mat-form-field>

                                    <mat-form-field (mousedown)="$event.stopPropagation()" appFormElement
                                        [larghezza]="1" [riempi]="true">
                                        <mat-label>Punteggio</mat-label>
                                        <input #inputPunt matInput placeholder="Punteggio" type="number" appInputNumber
                                            formControlName="punteggio" />
                                        <mat-error appFormError></mat-error>
                                    </mat-form-field>

                                    <section class="check-nc" (mousedown)="$event.stopPropagation()" appFormElement
                                        [minWidth]="'auto'" [larghezza]="0">
                                        <mat-checkbox formControlName="nc"
                                            (change)="cambioNc($event,idx)">NC</mat-checkbox>
                                    </section>
                                    <!-- <mat-radio-group aria-label="NC" formControlName="nc"
                                        (mousedown)="$event.stopPropagation()" appFormElement [larghezza]="0"
                                        [minWidth]="50">
                                        <mat-radio-button value="S">Si</mat-radio-button>
                                        <mat-radio-button value="N">No</mat-radio-button>
                                    </mat-radio-group> -->

                                    <div appFormElement [larghezza]="3" [riempi]="true">
                                        <app-separatore-label titoloSeparatore="Sottorisposte"
                                            [styleSeparatore]="'small'"></app-separatore-label>
                                    </div>
                                    <!-- END appFormContainer -->
                                </div>

                                <div style="height: 25px;"></div>

                                <!-- SOTTORISPOSTE -->
                                <mat-accordion appFormElement [larghezza]="3">

                                    <div cdkDropList
                                        (cdkDropListDropped)="dropSotto($event,rispArr.get('sottoOpzioni'))">

                                        <mat-expansion-panel expanded="true"
                                            *ngFor="let sottoRisp of $any(rispArr.get('sottoOpzioni'))?.controls; let idx2 = index;"
                                            cdkDrag>

                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    <button mat-icon-button title="Sposta" aria-label="Sposta">
                                                        <mat-icon> drag_handle </mat-icon>
                                                    </button>
                                                    <div *ngIf="sottoRisp.get('tipo').value === 'checkbox'">
                                                        Sottorisposta {{idx2 + 1}}
                                                    </div>
                                                    <div *ngIf="sottoRisp.get('tipo').value === 'kpi'">
                                                        Kpi
                                                    </div>
                                                    <div *ngIf="sottoRisp.get('tipo').value === 'azione'">
                                                        Azione e Buona Pratica
                                                    </div>
                                                </mat-panel-title>
                                                <mat-panel-description>
                                                    <div *ngIf="sottoRisp.get('tipo').value === 'checkbox'"
                                                        class="testo-risposta">
                                                        {{sottoRisp.get("valore").value}}
                                                    </div>

                                                    <div *ngIf="sottoRisp.get('tipo').value === 'kpi'"
                                                        class="testo-risposta">
                                                        {{sottoRisp.get("valore").value?.descrizione }}
                                                    </div>

                                                    <div *ngIf="sottoRisp.get('tipo').value === 'azione'"
                                                        class="testo-risposta">
                                                        {{sottoRisp.get("valore").value?.titolo }}
                                                    </div>

                                                    <button mat-icon-button title="Elimina Sottorisposta"
                                                        aria-label="Elimina Sottorisposta" color="warn"
                                                        (click)="elimina( $any(rispArr.get('sottoOpzioni')), idx2)">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>

                                            <div appFormContainer [larghezza]="3" [formGroup]="sottoRisp">

                                                <app-autocomplete *ngIf="sottoRisp.get('tipo').value === 'kpi'"
                                                    appFormElement [larghezza]="2" [riempi]="true"
                                                    formControlName="valore" [label]="'KPI'"
                                                    [loadDataFn]="getKPIAutoComplete" [displayFn]="displayFn"
                                                    [compare]="compareById" [required]="true" [chiaveId]="'id'">
                                                </app-autocomplete>

                                                <app-autocomplete *ngIf="sottoRisp.get('tipo').value === 'azione'"
                                                    appFormElement [larghezza]="2" [riempi]="true"
                                                    formControlName="valore" [label]="'Azioni e Buone Pratiche'"
                                                    [loadDataFn]="getAzioneAutoComplete" [displayFn]="displayFnAzione"
                                                    [compare]="compareById" [required]="true" [chiaveId]="'id'">
                                                </app-autocomplete>

                                                <mat-form-field *ngIf="sottoRisp.get('tipo').value === 'checkbox'"
                                                    (mousedown)="$event.stopPropagation()" appFormElement
                                                    [larghezza]="2" [riempi]="true">
                                                    <mat-label>Descrizione Sottorisposta</mat-label>
                                                    <input matInput placeholder="Descrizione Sottorisposta"
                                                        formControlName="valore" />
                                                    <mat-error appFormError></mat-error>
                                                </mat-form-field>

                                                <mat-form-field *ngIf="sottoRisp.get('tipo').value === 'checkbox'"
                                                    (mousedown)="$event.stopPropagation()" appFormElement
                                                    [larghezza]="1" [riempi]="true">
                                                    <mat-label>Punteggio Sottorisposta</mat-label>
                                                    <input matInput placeholder="Punteggio Sottorisposta" type="number"
                                                        appInputNumber formControlName="punteggio" />
                                                    <mat-error appFormError></mat-error>
                                                </mat-form-field>
                                                <mat-form-field
                                                    *ngIf="sottoRisp.get('tipo').value === 'kpi' || sottoRisp.get('tipo').value === 'azione'"
                                                    (mousedown)="$event.stopPropagation()" appFormElement
                                                    [larghezza]="1" [riempi]="true">
                                                    <mat-label>Punteggio se compilato</mat-label>
                                                    <input matInput placeholder="Punteggio se compilato" type="number"
                                                        appInputNumber formControlName="punteggio" />
                                                    <mat-error appFormError></mat-error>
                                                </mat-form-field>
                                            </div>

                                        </mat-expansion-panel>

                                    </div>
                                </mat-accordion>

                                <div style="height: 25px;"></div>

                                <div style="display: flex; column-gap: 20px;">
                                    <div *ngIf="isSottoRisp" appFormElement [larghezza]="3" [riempi]="true">
                                        <button mat-stroked-button
                                            (click)="addRiga($any(rispArr.get('sottoOpzioni')), true)"
                                            class="botton-risposte">
                                            + Aggiungi Sottorisposte
                                        </button>
                                    </div>
                                    <div *ngIf="isKpi || isAzione" appFormElement [larghezza]="3" [riempi]="true">
                                        <button mat-stroked-button
                                            (click)="addRigaKpi($any(rispArr.get('sottoOpzioni')))"
                                            class="botton-risposte">
                                            + Aggiungi KPI
                                        </button>
                                    </div>
                                    <div *ngIf="isAzione || isKpi" appFormElement [larghezza]="3" [riempi]="true">
                                        <button mat-stroked-button
                                            (click)="addRigaAzione($any(rispArr.get('sottoOpzioni')))"
                                            class="botton-risposte">
                                            + Aggiungi Azione e Buona pratica
                                        </button>
                                    </div>
                                </div>

                            </mat-expansion-panel>

                        </div>
                    </mat-accordion>

                    <div style="height: 25px;"></div>

                    <button mat-stroked-button (click)="addRiga($any(formTematiche.get('opzioni') ))"
                        class="botton-risposte">
                        + Aggiungi Risposta
                    </button>

                </mat-tab>
                <!-- ---------------------------------------- I M P A T T I ------------------------- -->
                <mat-tab label="Impatti"
                    *ngIf="this.formTematiche.get('aree')?.value?.length && this.formTematiche.get('aree')?.value?.includes('survey')">
                    <div style="height: 20px;"></div>
                    <div appFormContainer [larghezza]="2">
                        <div appFormElement [larghezza]="1" [riempi]="true" class="list-container">
                            <div class="cont-titolo">
                                <mat-label class="titolo">Impatti Selezionati</mat-label>
                                <div>
                                    <button mat-flat-button class="imp-esclud" color="accent"
                                        (click)="apriFiltroSelezionati('impatti')" matBadgeColor="warn">
                                        <mat-icon matSuffix>filter_list</mat-icon>
                                        Filtri
                                    </button>
                                </div>
                            </div>
                            <div cdkDropList #selList="cdkDropList" [cdkDropListData]="arrayImpattiSel"
                                [cdkDropListConnectedTo]="[dispList]" class="list"
                                (cdkDropListDropped)="dropImpatti($event)">
                                <div class="box-sel" *ngFor="let sel of arrayImpattiSelFiltrati" cdkDrag
                                    [matTooltip]="sel.descrizione">
                                    <div class="box-sx">
                                        <button mat-icon-button title="Sposta" aria-label="Sposta">
                                            <mat-icon> drag_handle </mat-icon>
                                        </button>
                                        <mat-label class="truncate">{{sel.titolo}}</mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div appFormElement [larghezza]="1" [riempi]="true" class="list-container">
                            <div class="cont-titolo">
                                <mat-label class="titolo">Impatti Disponibili</mat-label>
                                <div>
                                    <button mat-flat-button class="imp-esclud" color="accent"
                                        (click)="apriFiltro('impatti')" matBadgeColor="warn">
                                        <mat-icon matSuffix>filter_list</mat-icon>
                                        Filtri
                                    </button>
                                </div>
                            </div>
                            <div cdkDropList #dispList="cdkDropList" [cdkDropListData]="arrayImpattiDisponibili"
                                [cdkDropListConnectedTo]="[selList]" class="list"
                                (cdkDropListDropped)="dropImpatti($event)">

                                <div style="display: flex;display: flex;justify-content: center;align-items: center;height: 100%;"
                                    *ngIf="loadingImpDisp">
                                    <mat-spinner style="height: 50px;"></mat-spinner>
                                </div>

                                <div style="display: flex;display: flex;justify-content: center; height: 100%;"
                                    *ngIf="!arrayImpattiDisponibili?.length && !loadingImpDisp">
                                    <p>Nessun Impatto Disponibile</p>
                                </div>

                                <div class="box" *ngFor="let disp of arrayImpattiDisponibili" cdkDrag
                                    [matTooltip]="disp.descrizione">
                                    <div class="box-sx">
                                        <button mat-icon-button title="Sposta" aria-label="Sposta">
                                            <mat-icon> drag_handle </mat-icon>
                                        </button>
                                        <mat-label class="truncate">{{disp.titolo}}</mat-label>
                                    </div>
                                    <!--  <div>
                                                    <button mat-icon-button title="Modifica" aria-label="Modifica"
                                                        (click)="modifica(disp)">
                                                        <mat-icon> mode_edit </mat-icon>
                                                    </button>
                                                </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <!-- ---------------------------------------- I M P E G N I  ------------------------- -->
                <mat-tab label="Impegni"
                    *ngIf="this.formTematiche.get('aree')?.value?.length && this.formTematiche.get('aree')?.value?.includes('bilanci')">
                    <div style="height: 20px;"></div>

                    <div appFormContainer [larghezza]="2">
                        <div appFormElement [larghezza]="1" [riempi]="true" class="list-container">
                            <div class="cont-titolo">
                                <mat-label class="titolo">Impegni Selezionati</mat-label>
                                <div>
                                    <button mat-flat-button class="imp-esclud" color="accent"
                                        (click)="apriFiltroSelezionati('impegni')" matBadgeColor="warn">
                                        <mat-icon matSuffix>filter_list</mat-icon>
                                        Filtri
                                    </button>
                                </div>
                            </div>
                            <div cdkDropList #selListImpe="cdkDropList" [cdkDropListData]="arrayImpegniSel"
                                [cdkDropListConnectedTo]="[dispListImpegni]" class="list"
                                (cdkDropListDropped)="dropImpegni($event)">
                                <div class="box-sel" *ngFor="let sel of arrayImpegniSelFiltrati" cdkDrag
                                    [matTooltip]="sel.descrizione">
                                    <div class="box-sx">
                                        <button mat-icon-button title="Sposta" aria-label="Sposta">
                                            <mat-icon> drag_handle </mat-icon>
                                        </button>
                                        <mat-label class="truncate">{{sel.titolo}}</mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div appFormElement [larghezza]="1" [riempi]="true" class="list-container">
                            <div class="cont-titolo">
                                <mat-label class="titolo">Impegni Disponibili</mat-label>
                                <div>
                                    <button mat-flat-button class="imp-esclud" color="accent"
                                        (click)="apriFiltro('impegni')" matBadgeColor="warn">
                                        <mat-icon matSuffix>filter_list</mat-icon>
                                        Filtri
                                    </button>
                                </div>
                            </div>
                            <div cdkDropList #dispListImpegni="cdkDropList" [cdkDropListData]="arrayImpegni"
                                [cdkDropListConnectedTo]="[selListImpe]" class="list"
                                (cdkDropListDropped)="dropImpegni($event)">

                                <div style="display: flex;display: flex;justify-content: center;align-items: center;height: 100%;"
                                    *ngIf="loadingImpDisp">
                                    <mat-spinner style="height: 50px;"></mat-spinner>
                                </div>

                                <div style="display: flex;display: flex;justify-content: center; height: 100%;"
                                    *ngIf="!arrayImpegni?.length && !loadingImpDisp">
                                    <p>Nessun Impegno Disponibile</p>
                                </div>

                                <div class="box" *ngFor="let disp of arrayImpegni" cdkDrag
                                    [matTooltip]="disp.descrizione">
                                    <div class="box-sx">
                                        <button mat-icon-button title="Sposta" aria-label="Sposta">
                                            <mat-icon> drag_handle </mat-icon>
                                        </button>
                                        <mat-label class="truncate">{{disp.titolo}}</mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </mat-tab>
                <!-- ---------------------------------------- B U O N E  P R A T I C H E  ------------------------- -->
                <mat-tab label="Buone Pratiche"
                    *ngIf="this.formTematiche.get('aree')?.value?.length && this.formTematiche.get('aree')?.value?.includes('bilanci')">
                    <div style="height: 20px;"></div>

                    <div appFormContainer [larghezza]="2">
                        <div appFormElement [larghezza]="1" [riempi]="true" class="list-container">
                            <div class="cont-titolo">
                                <mat-label class="titolo">Buone Pratiche Selezionate</mat-label>
                                <div>
                                    <button mat-flat-button class="imp-esclud" color="accent"
                                        (click)="apriFiltroSelezionati('azioni')" matBadgeColor="warn">
                                        <mat-icon matSuffix>filter_list</mat-icon>
                                        Filtri
                                    </button>
                                </div>
                            </div>
                            <div cdkDropList #selListAzioni="cdkDropList" [cdkDropListData]="arrayAzioneBPSel"
                                [cdkDropListConnectedTo]="[dispListAzioniBP]" class="list"
                                (cdkDropListDropped)="dropAzioneBP($event)">
                                <div class="box-sel" *ngFor="let sel of arrayAzioneBPSelFiltrati" cdkDrag
                                    [matTooltip]="sel.descrizione">
                                    <div class="box-sx">
                                        <button mat-icon-button title="Sposta" aria-label="Sposta">
                                            <mat-icon> drag_handle </mat-icon>
                                        </button>
                                        <mat-label class="truncate">{{sel.titolo}}</mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div appFormElement [larghezza]="1" [riempi]="true" class="list-container">
                            <div class="cont-titolo">
                                <mat-label class="titolo">Buone Pratiche Disponibili</mat-label>
                                <div>
                                    <button mat-flat-button class="imp-esclud" color="accent"
                                        (click)="apriFiltro('azioniBP')" matBadgeColor="warn">
                                        <mat-icon matSuffix>filter_list</mat-icon>
                                        Filtri
                                    </button>
                                </div>
                            </div>
                            <div cdkDropList #dispListAzioniBP="cdkDropList" [cdkDropListData]="arrayAzioneBP"
                                [cdkDropListConnectedTo]="[selListAzioni]" class="list"
                                (cdkDropListDropped)="dropAzioneBP($event)">

                                <div style="display: flex;display: flex;justify-content: center;align-items: center;height: 100%;"
                                    *ngIf="loadingImpDisp">
                                    <mat-spinner style="height: 50px;"></mat-spinner>
                                </div>

                                <div style="display: flex;display: flex;justify-content: center; height: 100%;"
                                    *ngIf="!arrayAzioneBP?.length && !loadingImpDisp">
                                    <p>Nessun azione o buona pratica Disponibile</p>
                                </div>

                                <div class="box" *ngFor="let disp of arrayAzioneBP" cdkDrag
                                    [matTooltip]="disp.descrizione">
                                    <div class="box-sx">
                                        <button mat-icon-button title="Sposta" aria-label="Sposta">
                                            <mat-icon> drag_handle </mat-icon>
                                        </button>
                                        <mat-label class="truncate">{{disp.titolo}}</mat-label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </mat-tab>
                <!-- ---------------------------------------- K P I  ------------------------- -->
                <mat-tab label="KPI"
                    *ngIf="this.formTematiche.get('aree')?.value?.length && this.formTematiche.get('aree')?.value?.includes('bilanci')">
                    <div style="height: 20px;"></div>

                    <div appFormContainer [larghezza]="2">
                        <div appFormElement [larghezza]="1" [riempi]="true" class="list-container">
                            <div class="cont-titolo">
                                <mat-label class="titolo">KPI Selezionati</mat-label>
                                <div>
                                    <button mat-flat-button class="imp-esclud" color="accent"
                                        (click)="apriFiltroSelezionati('kpi')" matBadgeColor="warn">
                                        <mat-icon matSuffix>filter_list</mat-icon>
                                        Filtri
                                    </button>
                                </div>
                            </div>
                            <div cdkDropList #selListKpi="cdkDropList" [cdkDropListData]="arrayKpiSel"
                                [cdkDropListConnectedTo]="[dispListKpi]" class="list"
                                (cdkDropListDropped)="dropKpi($event)">
                                <div class="box-sel" *ngFor="let sel of arrayKpiSelFiltrati" cdkDrag
                                    [matTooltip]="sel.descrizione">
                                    <div class="box-sx">
                                        <button mat-icon-button title="Sposta" aria-label="Sposta">
                                            <mat-icon> drag_handle </mat-icon>
                                        </button>
                                        <mat-label class="truncate">{{sel.descrizione}}</mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div appFormElement [larghezza]="1" [riempi]="true" class="list-container">
                            <div class="cont-titolo">
                                <mat-label class="titolo">KPI Disponibili</mat-label>
                                <div>
                                    <button mat-flat-button class="imp-esclud" color="accent"
                                        (click)="apriFiltro('kpi')" matBadgeColor="warn">
                                        <mat-icon matSuffix>filter_list</mat-icon>
                                        Filtri
                                    </button>
                                </div>
                            </div>
                            <div cdkDropList #dispListKpi="cdkDropList" [cdkDropListData]="arrayKpi"
                                [cdkDropListConnectedTo]="[selListKpi]" class="list"
                                (cdkDropListDropped)="dropKpi($event)">

                                <div style="display: flex;display: flex;justify-content: center;align-items: center;height: 100%;"
                                    *ngIf="loadingImpDisp">
                                    <mat-spinner style="height: 50px;"></mat-spinner>
                                </div>

                                <div style="display: flex;display: flex;justify-content: center; height: 100%;"
                                    *ngIf="!arrayKpi?.length && !loadingImpDisp">
                                    <p>Nessun KPI Disponibile</p>
                                </div>

                                <div class="box" *ngFor="let disp of arrayKpi" cdkDrag [matTooltip]="disp.descrizione">
                                    <div class="box-sx">
                                        <button mat-icon-button title="Sposta" aria-label="Sposta">
                                            <mat-icon> drag_handle </mat-icon>
                                        </button>
                                        <mat-label class="truncate">{{disp.descrizione}}</mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </mat-tab>
                <!-- ----------------------------------------- C E R T I F I C A Z I O N I-------------------------- -->
                <mat-tab label="Certificazioni">
                    <div style="height: 20px;"></div>

                    <div appFormContainer [larghezza]="2">
                        <div appFormElement [larghezza]="1" [riempi]="true" class="list-container">
                            <div class="cont-titolo">
                                <mat-label class="titolo">Certificazioni Selezionati</mat-label>
                                <div>
                                    <button mat-flat-button class="imp-esclud" color="accent"
                                        (click)="apriFiltroSelezionati('certificazioni')" matBadgeColor="warn">
                                        <mat-icon matSuffix>filter_list</mat-icon>
                                        Filtri
                                    </button>
                                </div>
                            </div>
                            <div cdkDropList #selListCertificazioni="cdkDropList"
                                [cdkDropListData]="arrayCertificazioniSel"
                                [cdkDropListConnectedTo]="[dispListCertificazioni]" class="list"
                                (cdkDropListDropped)="dropCertificazioni($event)">
                                <div class="box-sel" *ngFor="let sel of arrayCertificazioniSelFiltrati" cdkDrag
                                    [matTooltip]="sel.descrizione">
                                    <div class="box-sx">
                                        <button mat-icon-button title="Sposta" aria-label="Sposta">
                                            <mat-icon> drag_handle </mat-icon>
                                        </button>
                                        <mat-label class="truncate">{{sel.nomeBreve}}</mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div appFormElement [larghezza]="1" [riempi]="true" class="list-container">
                            <div class="cont-titolo">
                                <mat-label class="titolo">Certificazioni Disponibili</mat-label>
                                <div>
                                    <button mat-flat-button class="imp-esclud" color="accent"
                                        (click)="apriFiltro('certificazioni')" matBadgeColor="warn">
                                        <mat-icon matSuffix>filter_list</mat-icon>
                                        Filtri
                                    </button>
                                </div>
                            </div>
                            <div cdkDropList #dispListCertificazioni="cdkDropList"
                                [cdkDropListData]="arrayCertificazioni"
                                [cdkDropListConnectedTo]="[selListCertificazioni]" class="list"
                                (cdkDropListDropped)="dropCertificazioni($event)">

                                <div style="display: flex;display: flex;justify-content: center;align-items: center;height: 100%;"
                                    *ngIf="loadingImpDisp">
                                    <mat-spinner style="height: 50px;"></mat-spinner>
                                </div>

                                <div style="display: flex;display: flex;justify-content: center; height: 100%;"
                                    *ngIf="!arrayCertificazioni?.length && !loadingImpDisp">
                                    <p>Nessuna Certificazione Disponibile</p>
                                </div>

                                <div class="box" *ngFor="let disp of arrayCertificazioni" cdkDrag
                                    [matTooltip]="disp.descrizione">
                                    <div class="box-sx">
                                        <button mat-icon-button title="Sposta" aria-label="Sposta">
                                            <mat-icon> drag_handle </mat-icon>
                                        </button>
                                        <mat-label class="truncate">{{disp.nomeBreve}}</mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </mat-tab>
                <!-- ----------------------------------------- O b i e t t i v i -------------------------- -->

                <mat-tab label="Obiettivi"
                    *ngIf="this.formTematiche.get('aree')?.value?.length && this.formTematiche.get('aree')?.value?.includes('piano')">
                    <div style="height: 20px;"></div>

                    <div appFormContainer [larghezza]="2">
                        <div appFormElement [larghezza]="1" [riempi]="true" class="list-container">
                            <div class="cont-titolo">
                                <mat-label class="titolo">Obiettivi Selezionati</mat-label>
                                <div>
                                    <button mat-flat-button class="imp-esclud" color="accent"
                                        (click)="apriFiltroSelezionati('obiettivi')" matBadgeColor="warn">
                                        <mat-icon matSuffix>filter_list</mat-icon>
                                        Filtri
                                    </button>
                                </div>
                            </div>
                            <div cdkDropList #selListObiettivi="cdkDropList" [cdkDropListData]="arrayObiettiviSel"
                                [cdkDropListConnectedTo]="[dispListObiettivi]" class="list"
                                (cdkDropListDropped)="dropObiettivi($event)">
                                <div class="box-sel" *ngFor="let sel of arrayObiettiviSelFiltrati" cdkDrag
                                    [matTooltip]="sel.descrizione">
                                    <div class="box-sx">
                                        <button mat-icon-button title="Sposta" aria-label="Sposta">
                                            <mat-icon> drag_handle </mat-icon>
                                        </button>
                                        <mat-label class="truncate">{{sel.titolo}}</mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div appFormElement [larghezza]="1" [riempi]="true" class="list-container">
                            <div class="cont-titolo">
                                <mat-label class="titolo">Obiettivi Disponibili</mat-label>
                                <div>
                                    <button mat-flat-button class="imp-esclud" color="accent"
                                        (click)="apriFiltro('obiettivi')" matBadgeColor="warn">
                                        <mat-icon matSuffix>filter_list</mat-icon>
                                        Filtri
                                    </button>
                                </div>
                            </div>
                            <div cdkDropList #dispListObiettivi="cdkDropList" [cdkDropListData]="arrayObiettivi"
                                [cdkDropListConnectedTo]="[selListObiettivi]" class="list"
                                (cdkDropListDropped)="dropObiettivi($event)">

                                <div style="display: flex;display: flex;justify-content: center;align-items: center;height: 100%;"
                                    *ngIf="loadingImpDisp">
                                    <mat-spinner style="height: 50px;"></mat-spinner>
                                </div>

                                <div style="display: flex;display: flex;justify-content: center; height: 100%;"
                                    *ngIf="!arrayObiettivi?.length && !loadingImpDisp">
                                    <p>Nessun Obiettivo Disponibile</p>
                                </div>

                                <div class="box" *ngFor="let disp of arrayObiettivi" cdkDrag
                                    [matTooltip]="disp.descrizione">
                                    <div class="box-sx">
                                        <button mat-icon-button title="Sposta" aria-label="Sposta">
                                            <mat-icon> drag_handle </mat-icon>
                                        </button>
                                        <mat-label class="truncate">{{disp.titolo}}</mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </mat-tab>
            </ng-container>
        </mat-tab-group>

    </form>
    <div style="height: 10px;"></div>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Salva
        </button>
    </div>
</div>